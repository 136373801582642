import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Agent } from '../../../services/types';
import removeRedEye from '../../../assets/icons/removeRedEye.svg';

interface AgentTableProps {
  data: Agent[];
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  itemsPerPage: number;
}

const AgentTable: React.FC<AgentTableProps> = ({ data, currentPage, totalPages, onPageChange, itemsPerPage }) => {
  const [filter, setFilter] = useState('all');
  const [filteredData, setFilteredData] = useState(data);

  const startIndex = currentPage * itemsPerPage;
  const visibleData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  useEffect(() => {
    let newFilteredData = data;
    const now = new Date();

    switch (filter) {
      case 'day':
        newFilteredData = data.filter(agent => {
          if (!agent.last_login_date) return false;
          const agentDate = new Date(agent.last_login_date);
          return (
            agentDate.getDate() === now.getDate() &&
            agentDate.getMonth() === now.getMonth() &&
            agentDate.getFullYear() === now.getFullYear()
          );
        });
        break;
      case 'week':
        const startOfWeek = new Date(now);
        startOfWeek.setDate(now.getDate() - now.getDay());
        newFilteredData = data.filter(agent => {
          if (!agent.last_login_date) return false;
          const agentDate = new Date(agent.last_login_date);
          return agentDate >= startOfWeek && agentDate <= now;
        });
        break;
      case 'month':
        newFilteredData = data.filter(agent => {
          if (!agent.last_login_date) return false;
          const agentDate = new Date(agent.last_login_date);
          return (
            agentDate.getMonth() === now.getMonth() &&
            agentDate.getFullYear() === now.getFullYear()
          );
        });
        break;
      case 'year':
        newFilteredData = data.filter(agent => {
          if (!agent.last_login_date) return false;
          const agentDate = new Date(agent.last_login_date);
          return agentDate.getFullYear() === now.getFullYear();
        });
        break;
      default:
        newFilteredData = data;
    }

    setFilteredData(newFilteredData);
  }, [filter, data]);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      onPageChange(currentPage + 1);
    }
  };

  const handleDownload = () => {
    const csvContent = [
      ['S/N', 'First Name', 'Last Name', 'Email', 'Phone', 'Status', 'Checked by Admin', 'Verified', 'Last Login', 'Last Order Date'],
      ...filteredData.map((agent, index) => [
        startIndex + index + 1,
        agent.first_name,
        agent.last_name,
        agent.email,
        agent.phone,
        agent.account_status,
        agent.is_checked_by_admin ? 'Activated' : 'Not Active',
        agent.is_verified ? 'Yes' : 'No',
        agent.last_login || 'N/A',
        agent.last_order_date || 'N/A',
      ])
    ]
      .map(e => e.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'agent_table.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(event.target.value);
  };

  return (
    <>
      <div className="min-w-full leading-normal bg-amber-200 bg-opacity-25 rounded-tl-xl rounded-tr-xl border-rounded-xl">
        {/* Filter and Download Buttons */}
        <div className="flex justify-between items-center p-4">
          <div className="flex items-center">
            Filter by:
            <select
              className="border border-gray-300 rounded px-2 py-1 ml-2"
              value={filter}
              onChange={handleFilterChange}
            >
              <option value="all">All</option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
          <button onClick={handleDownload}>
            <i className="fas fa-download"></i> Download CSV
          </button>
        </div>
       
       {/* Table */}
<div className="overflow-x-auto">
  <table className="min-w-full w-full bg-amber-200 bg-opacity-25 rounded-tl-xl rounded-tr-xl border border-b-0 text-left text-xs font-semibold text-black uppercase tracking-wider">
    <thead>
      <tr>
        <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          S/N
        </th>
        <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          First Name
        </th>
        <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          Last Name
        </th>
        <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          Email
        </th>
       
        <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          Status
        </th>
        <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          Checked by Admin
        </th>
        
        <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          View Details
        </th>
      </tr>
    </thead>
    <tbody>
      {visibleData.map((agent, index) => (
        <tr key={agent._id} className={`hover:bg-gray-100 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
          <td className="px-5 py-3 border-b border-gray-200 text-sm">{startIndex + index + 1}</td>
          <td className="px-5 py-3 border-b border-gray-200 text-sm">{agent.first_name}</td>
          <td className="px-5 py-3 border-b border-gray-200 text-sm">{agent.last_name}</td>
          <td className="px-5 py-3 border-b border-gray-200 text-sm">{agent.email}</td>
          
          <td className={`px-5 py-3 border-b border-gray-200 text-sm ${agent.is_suspended ? 'text-red-600' : 'text-green-600'}`}>
            {agent.is_suspended ? 'Suspended' : 'Active'}
          </td>
          <td className="px-5 py-3 border-b border-gray-200 text-sm">{agent.is_checked_by_admin ? 'Activated' : 'NoT Active'}</td><td className="px-5 py-3 border-b border-gray-200 text-sm flex space-x-2">
            <Link to={`/pr-admin/agents/agent-details/${agent._id}`}>
              <img src={removeRedEye} alt="View" />
            </Link>
           
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>


         {/* Pagination */}
         <div className="w-full justify-between items-center inline-flex py-4">
          <div className="h-11 p-2.5 rounded-xl border border-amber-500 justify-center items-start gap-2.5 flex">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
              className="text-black text-base font-normal font-['Nunito'] leading-normal"
            >
              Previous
            </button>
          </div>
          <div className="text-black text-base font-normal font-['Nunito'] leading-normal">
            Page {currentPage + 1} of {totalPages}
          </div>
          <div className="h-11 p-2.5 rounded-xl border border-amber-500 justify-center items-start gap-2.5 flex">
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1}
              className="text-black text-base font-normal font-['Nunito'] leading-normal"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentTable;
