import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { request } from '../../../hooks/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter, faDownload } from '@fortawesome/free-solid-svg-icons';
import removeRedEye from '../../../assets/icons/removeRedEye.svg';
import AgentTab from './AgentTab'; // Import the AgentTab component
import AgentDetails from './AgentDetails';
import { FaCopy } from 'react-icons/fa'; // FontAwesome copy icon
import html2canvas from 'html2canvas';
import DeliveryLocation from '../../deliverylocation/DeliveryLocation';
import WalletTab from './WalletTab';
import AdminService from 'src/services/admin.services';





interface WalletProps {
  agentId: string;
}

interface WalletData {
  balance: { amount: number; currency_code: string };
  rewards: { bonus: { registration: number } };
}


interface OrderItem {
  restaurant_id: any;
  _id: string;
  order_code: string;
  restaurant_name: string;
  subtotal: number;
  total: number;
  delivery_fee: number;
  tax: number;
  date_delivered: string;
  items: {
    meal: {
      _id: string;
      name: string;
      description: string;
      restaurant_id:string;
      price: {
        amount: number;
        currency_code: string;
      };
      image_url?: string;
    };
    qty: number;
  }[];
  user: string;
  order_status: string;
  payment_method: string;
  pickup_time: string;
  agent: string;
  agent_delivered_order_upload: string;
  delivery_location: string;
  enroute_time: string;
  discount: {
    order: number;
    delivery: number;
  };
  voucher: number;
  created_at: string;
  updated_at: string;
  note: string;
  user_received_order_upload: string;
}

const ITEMS_PER_PAGE = 20;


const adminService = new AdminService();


const AgentDetailsWrapper: React.FC = () => {
  const { agentId } = useParams<{ agentId: string }>();
  const [agent, setAgent] = useState<any | null>(null); // Adjust type as necessary
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [orders, setOrders] = useState<OrderItem[]>([]);
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
  const [ordersError, setOrdersError] = useState<string | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<OrderItem | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState<string>('delivered');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [tab, setTab] = useState<number>(0); // State for managing the selected tab
  const [copied, setCopied] = useState(false);

 
  useEffect(() => {
    const fetchAgentDetails = async () => {
      try {
        const response = await request(`/agents/${agentId}`, 'GET', undefined, true, false, false);
        setAgent(response);
      } catch (err) {
        setError('Failed to fetch agent details');
      } finally {
        setLoading(false);
      }
    };

    fetchAgentDetails();
  }, [agentId]);

  

  
  useEffect(() => {
    if (tab === 1 && agent) { // Fetch orders only when "Order History" tab is selected
      const fetchAgentOrders = async () => {
        setOrdersLoading(true);
        try {
          // Check if the agent has valid coordinates
          let [longitude, latitude] = agent?.location?.Coordinates || [null, null];
  
          // If coordinates are missing, use Nigeria's center coordinates
          if (longitude === null || latitude === null) {
            console.warn('Agent coordinates not available, using Nigeria center coordinates');
            // Nigeria center coordinates
            longitude = 8.6753; // Longitude of Nigeria center
            latitude = 9.082;   // Latitude of Nigeria center
          }
  
          const maxDistance: bigint = 100000000000000n; // 1200 kilometers in meters (bigint)
  
          // Build query string with dynamic parameters for agent orders
          const query = `/shopping/order/search?page=${currentPage}&agentId=${agentId}&order_status=${filterStatus}&size=${ITEMS_PER_PAGE}&longitude=${longitude}&latitude=${latitude}&max_distance=${maxDistance.toString()}`;
  
          const response = await request(query, 'GET', {}, true, false, false);
  
          if (Array.isArray(response)) {
            // Filter orders based on search term
            const filteredOrders = response.filter(order =>
              order._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
              order.order_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
              order.restaurant_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              order.agent.toLowerCase().includes(searchTerm.toLowerCase())
            );
  
            setOrders(filteredOrders);
            setTotalPages(Math.ceil(filteredOrders.length / ITEMS_PER_PAGE));
          } else {
            throw new Error('Unexpected response format');
          }
        } catch (err: unknown) {
          // Typecast 'err' to 'Error' to access the 'message' property
          if (err instanceof Error) {
            console.error('Failed to fetch orders:', err.message);  // Log the error message
            setOrdersError(`Failed to fetch orders: ${err.message}`);  // Show the error message
          } else {
            console.error('Failed to fetch orders: Unknown error');  // Fallback for unknown error type
            setOrdersError('Failed to fetch orders: Unknown error');
          }
        } finally {
          setOrdersLoading(false);
        }
      };
  
      fetchAgentOrders();
    }
  }, [agent, agentId, filterStatus, currentPage, searchTerm, tab]);
  
  const handleSearch = () => {
    setCurrentPage(1); // Reset to the first page when searching
  };
  
  


  
  useEffect(() => {
    const fetchAgentsWallet = async () => {
      try {
        const response = await request(
          `/agents/wallet/${agentId}`,
          "GET",
          {},
          true,
          false,
          false
        );
    
        console.log('API response:', response);
    
        // Check if the response is in the expected format
        if (response && response.agentId === agentId) {
          return response; // Return the entire response
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (error) {
        console.error('Error fetching wallet details:', error);
        throw error;
      }
    };
    
  
    // Call the fetch function inside useEffect
    if (agentId) {
      fetchAgentsWallet();
    } else {
      console.warn('agentId is undefined');
    }
  }, [agentId]); // Add agentId as a dependency
  

    
  
 
  
  const handleCopy = () => {
    navigator.clipboard.writeText(agent._id);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  };

  const handleDownload = () => {
    const csvRows = [
      [
        'Order ID', 'Order Code', 'Restaurant', 'Subtotal', 'Total', 'Delivery Fee', 'Tax', 'Date Delivered',
        'Items', 'User', 'Order Status', 'Payment Method', 'Pickup Time', 'Agent',
        'Agent Delivered Order Upload', 'Delivery Location', 'Enroute Time',
        'Discount Order', 'Discount Delivery', 'Voucher', 'Created At', 'Updated At',
        'Note', 'User Received Order Upload'
      ], // CSV header
      ...orders.map(order => [
        order._id,
        order.order_code,
        order.restaurant_name,
        order.subtotal,
        order.total,
        order.delivery_fee,
        order.tax,
       
        order.items.map(item => `${item.meal.name} (${item.qty})`).join(', '),
        order.user,
        order.order_status,
        order.payment_method,
        order.agent,
        order.agent_delivered_order_upload,
        order.delivery_location,
       // Check for default/invalid dates before formatting
      new Date(order.date_delivered).getFullYear() !== 1 ? new Date(order.date_delivered).toLocaleDateString() : '',
      new Date(order.pickup_time).getFullYear() !== 1 ? new Date(order.pickup_time).toLocaleDateString() : '',
      new Date(order.enroute_time).getFullYear() !== 1 ? new Date(order.enroute_time).toLocaleDateString() : '',
      new Date(order.created_at).getFullYear() !== 1 ? new Date(order.created_at).toLocaleDateString() : '',
      
      order.agent,
      order.agent_delivered_order_upload,
      order.delivery_location,
      order.discount?.order || '',  // Handle missing discount
      order.discount?.delivery || '',  // Handle missing delivery discount
      order.voucher || '',  // Handle missing voucher
      
      order.note || '',  // Handle missing notes
      order.user_received_order_upload || ''  // Handle missing upload
      ])
    ];

    const csvContent = 'data:text/csv;charset=utf-8,' + csvRows.map(e => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'orders.csv');
    document.body.appendChild(link);
    link.click();
  };

  const openModal = (order: OrderItem) => {
    setSelectedOrder(order);
  };

  const closeModal = () => {
    setSelectedOrder(null);
  };

  const downloadInvoice = () => {
    const captureElement = document.getElementById('order-details');
  
    if (captureElement && selectedOrder) { // Added null check for selectedOrder
      html2canvas(captureElement, {
        useCORS: true,
        scale: 2, // Increase the scale to improve image quality
        logging: true, // Enable logging to check any issues
      })
        .then(canvas => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = `Order_${selectedOrder._id}.png`; // selectedOrder is now guaranteed to be non-null
          link.click();
        })
        .catch(error => {
          console.error('Error capturing the element:', error);
        });
    } else {
      if (!captureElement) {
        console.error('Capture element is missing');
      }
      if (!selectedOrder) {
        console.error('Selected order is missing');
      }
    }
  };
  

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

 

  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Replace the tabs section with AgentTab */}


      <AgentTab setTab={setTab} tab={tab} hasOrders={orders.length > 0} />

      {tab === 0 && agent && (
        <div className="mb-6">
     
          <div className="space-y-2">
         
          <div>
      <div className="h-11 p-2.5 rounded-xl border border-amber-500 flex justify-between border-b pb-2 items-start gap-2.5">
        <div>Agent ID:</div>
        <div className="flex items-center gap-2">
          <div className="px-5 py-3">{agent._id}</div>
          <button onClick={handleCopy} className="text-amber-500 hover:text-amber-600">
            <FaCopy />
          </button>
        </div>
      </div>
      <hr />
      {copied && <div className="text-sm text-green-500 mt-2">Copied to clipboard!</div>}
    </div>

    
            
            {/* Pass the necessary props to AgentDetails */}
            <AgentDetails 
              agent={agent} 
              onStatusChange={(newStatus) => {
                // Handle the status change here
                console.log(`Agent status changed to: ${newStatus}`);
              }} 
            />
          </div>
        </div>
      )}

      {tab === 1 && (
        <div className="mt-6">
          <div className="mb-4 flex items-center justify-between">
            <div className="relative flex items-center">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search orders..."
                className="border p-2 rounded pr-10"
              />
              <button
                onClick={handleSearch}
                className="absolute right-0 top-0 mt-2 mr-2"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
            <div className="flex items-center">
            <select
                      value={filterStatus}
                      onChange={(e) => setFilterStatus(e.target.value)}
                      className="border border-gray-300 rounded-lg px-4 py-2"
                    >
                 <option value="delivered">Delivered</option>
                <option value="pending">Pending</option>
                <option value="cancelled">Cancelled</option>
                <option value="confirmed">Confirmed</option>
                <option value="declined">Declined</option>
                    </select>
              <button
                onClick={() => setFilterStatus(filterStatus === 'delivered' ? 'pending' : 'delivered')}
                className="flex items-center mr-4"
              >
                <FontAwesomeIcon icon={faFilter} className="mr-2" />
                {filterStatus === 'delivered' ? 'Show Pending' : 'Show Delivered'}
              </button>
              <button
                onClick={handleDownload}
                className="flex items-center"
              >
                <FontAwesomeIcon icon={faDownload} className="mr-2" />
                Download
              </button>
            </div>
          </div>
          {ordersLoading ? (
            <div className="text-center">Loading orders...</div>
          ) : ordersError ? (
            <div className="text-center text-red-500">{ordersError}</div>
          ) : orders.length ? (
            <div>
              <h2 className="text-xl font-bold mb-4">Order History</h2>
              
              <table className="min-w-full px-5 py-3 bg-amber-200 bg-opacity-25 rounded-tl-xl rounded-tr-xl border border-b-0 text-left text-xs font-semibold text-black uppercase tracking-wider">
                <thead>
                  <tr>
                    <th className="px-5 py-3  bg-amber-200 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Order ID</th>
                    <th className="px-5 py-3  bg-amber-200 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Order Code</th>
                    <th className="px-5 py-3  bg-amber-200 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Restaurant</th>
                    
                    <th className="px-5 py-3  bg-amber-200 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Subtotal</th>
                    <th className="px-5 py-3  bg-amber-200 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Total:₦</th>
                    <th className="px-5 py-3  bg-amber-200 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date Delivered</th>
                    <th className="px-5 py-3  bg-amber-200 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">View</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map(order => (
                    <tr key={order._id} className="hover:bg-gray-100">
                      <td className="px-5 py-3">{order._id}</td>
                      <td className="px-5 py-3">{order.order_code}</td>
                      <td className="px-5 py-3">{order.restaurant_name}</td>
                     
                      <td className="px-5 py-3">c{order.subtotal.toFixed(2)}</td>
                      <td className="px-5 py-3">₦{order.total.toFixed(2)}</td>
                      <td className="px-5 py-3">{new Date(order.date_delivered).toLocaleDateString()}</td>
                      <td className="px-5 py-3">
                        <button
                          onClick={() => openModal(order)}
                          className="text-blue-500 hover:underline"
                        >
                          <img src={removeRedEye} alt="View" className="w-5 h-5 inline-block" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="h-11 p-2.5 rounded-xl border border-amber-500 justify-center items-start gap-2.5 flex"
                >
                  Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="h-11 p-2.5 rounded-xl border border-amber-500 justify-center items-start gap-2.5 flex"
                >
                  Next
                </button>
              </div>
            </div>
          ) : (
            <div className="text-center">No orders found</div>
          )}
        </div>
      )}


{selectedOrder &&  (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
    <div id="order-details" className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full relative max-h-screen overflow-y-auto">
      <button
        onClick={closeModal}
        className="absolute top-4 right-4 bg-yellow-500 text-white py-2 px-4 rounded"
      >
        Close
      </button>
      <h2 className="text-2xl font-bold mb-4">Order Details</h2>

      <div className="space-y-2">
        <div className="flex justify-between border-b pb-2">
          <p><strong>Order ID:</strong></p>
          <p>{selectedOrder._id}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p><strong>Order Code:</strong></p>
          <p>{selectedOrder.order_code}</p>
        </div>
        
        
        <div className="flex justify-between border-b pb-2">
  <p><strong>Date Delivered:</strong></p>
  <p>
    {selectedOrder.date_delivered && 
     new Date(selectedOrder.date_delivered).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.date_delivered).toLocaleDateString()} ${new Date(selectedOrder.date_delivered).toLocaleTimeString()}` 
      : ''}
  </p>
</div>
<div className="flex justify-between border-b pb-2">
  <p><strong>pickup time</strong></p>
  <p>
    {selectedOrder.pickup_time && 
     new Date(selectedOrder.pickup_time).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.pickup_time).toLocaleDateString()} ${new Date(selectedOrder.pickup_time).toLocaleTimeString()}` 
      : ''}
  </p>
</div>

                <div className="flex justify-between border-b pb-2">
                  <p><strong>Order Date:</strong></p>
                  <p>{selectedOrder.created_at ?
                    `${new Date(selectedOrder.created_at).toLocaleDateString()} ${new Date(selectedOrder.created_at).toLocaleTimeString()}`
                    : ' '}</p>
                </div>

                <div className="flex justify-between border-b pb-2">
  <p><strong>created At</strong></p>
  <p>
    {selectedOrder.created_at && 
     new Date(selectedOrder.created_at).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.created_at).toLocaleDateString()} ${new Date(selectedOrder.created_at).toLocaleTimeString()}` 
      : ''}
  </p>
</div>

<div className="flex justify-between border-b pb-2">
  <p><strong>Enroute Time</strong></p>
  <p>
    {selectedOrder.enroute_time && 
     new Date(selectedOrder.enroute_time).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.enroute_time).toLocaleDateString()} ${new Date(selectedOrder.enroute_time).toLocaleTimeString()}` 
      : ''}
  </p>
</div>

        <div className="flex justify-between border-b pb-2">
          <p><strong>Order Status:</strong></p>
          <p>{selectedOrder.order_status}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p><strong>Payment Method:</strong></p>
          <p>{selectedOrder.payment_method}</p>
        </div>
       
        <div className="flex justify-between border-b pb-2">
          <p><strong>User:</strong></p>
          
          <Link to={`/pr-admin/users/user-details/${selectedOrder.user}`}>
                    
                    <img src={removeRedEye} alt="View" />
                  </Link>
        </div>

   
        <div className="flex justify-between border-b pb-2">
          <p><strong>Agent Delivered Order Upload:</strong></p>
          <p>{selectedOrder.agent_delivered_order_upload}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
        
          {selectedOrder && <DeliveryLocation locationId={selectedOrder.delivery_location as string} />}

        </div>
       
        <div className="flex justify-between border-b pb-2">
          <p><strong>Discount (Order):</strong></p>
          <p>₦{selectedOrder.discount.order}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p><strong>Discount (Delivery):</strong></p>
          <p>₦{selectedOrder.discount.delivery}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p><strong>Voucher:</strong></p>
          <p>₦{selectedOrder.voucher}</p>
        </div>
        
        <div className="flex justify-between border-b pb-2">
          <p><strong>Note:</strong></p>
          <p>{selectedOrder.note}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p><strong>User Received Order Upload:</strong></p>
          <p>{selectedOrder.user_received_order_upload}</p>
        </div>
      </div>

      <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2">Items:</h3>
        <ul>
          {selectedOrder.items.map(item => (
            <li key={item.meal._id} className="mb-2">
              <div className="flex justify-between border-b pb-2">
                <p><strong>Meal Name:</strong></p>
                <p>{item.meal.name}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Description:</strong></p>
                <p>{item.meal.description}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Price:</strong></p>
                <p>₦{item.meal.price.amount} </p>
              </div>
              {item.meal.image_url && (
                <img src={item.meal.image_url} alt={item.meal.name} className="w-16 h-16 object-cover mt-2" />
              )}
              <div className="flex justify-between border-b pb-2">
                <p><strong>Quantity:</strong></p>
                <p>{item.qty}</p>
              </div>
            </li>
            
          ))}
        </ul>
        <div className="flex justify-between border-b pb-2">
          <p><strong>Restaurant:</strong></p>
          <p>{selectedOrder.restaurant_name}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p><strong>Subtotal:</strong></p>
          <p>₦{selectedOrder.subtotal}</p>
        </div>
       
        <div className="flex justify-between border-b pb-2">
          <p><strong>Delivery Fee:</strong></p>
          <p>₦{selectedOrder.delivery_fee}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p><strong>Service Charge:</strong></p>
          <p>₦{selectedOrder.tax}</p>
        </div>
      </div>
    
    <div className="flex justify-between border-b pb-2">
          <p><strong>Total:</strong></p>
          <p>₦{selectedOrder.total}</p>
        </div>

        <button
        onClick={downloadInvoice}
        className="mt-4 bg-green-500 text-white py-2 px-4 rounded"
      >
        Download Invoice
      </button>
  </div>
  
  </div>
)}


{/* Conditionally render WalletTab based on the selected tab */}
{tab === 2 && agentId && (
  <div className="bg-white p-6 rounded-lg shadow">
    <WalletTab 
      agentId={agentId} // Ensure you're passing the correct variable
    /> {/* Display wallet data for selected agent */}
  </div>
)}



</div>
  );
};

export default AgentDetailsWrapper;
