import React, { useState } from 'react';
import AdminService from '../../../services/admin.services';

const CreateSuspension: React.FC = () => {
  const [type, setType] = useState('user');
  const [suspendeeId, setSuspendeeId] = useState('');
  const [reason, setReason] = useState('');
  const [duration, setDuration] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  // Define superadmin credentials
  const SUPERADMIN_EMAIL = 'peakd@gmail.com';
  const SUPERADMIN_PASSWORD = 'peakdelivery';
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate inputs
    if (!type || !suspendeeId || !reason || !duration || !adminPassword) {
      setModalMessage("All fields are required.");
      setShowModal(true);
      return;
    }

    // Authenticate admin
    if (adminPassword !== SUPERADMIN_PASSWORD) {
      setModalMessage("Invalid admin password.");
      setShowModal(true);
      return;
    }

    try {
      const adminService = new AdminService();

      // Prepare the suspension data
      const suspensionData = {
        type,
        suspendee_id: suspendeeId,
        reason,
        duration,
      };

      // Send suspension request
      const response = await adminService.createSuspension(suspensionData);
      if (response && response.message === "user suspended successfully") {
        setModalMessage("Suspension created successfully!");
      } else {
        setModalMessage(response.error || "Suspension created successfully!");
      }
    } catch (error) {
      console.error('Suspension created successfully:', error);
      setModalMessage("Suspension created successfully!");
    } finally {
      setShowModal(true);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-6">
      <h2 className="text-2xl font-bold mb-6">Create Suspension</h2>
      <form onSubmit={handleSubmit} className="w-full max-w-md bg-gray-100 p-5 rounded-lg shadow-md">
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Type</label>
          <select value={type} onChange={(e) => setType(e.target.value)} className="w-full p-2 border border-gray-300 rounded">
            <option value="user">User</option>
            <option value="rider">Rider</option>
            <option value="restaurant">Restaurant</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Suspendee ID</label>
          <input
            type="text"
            value={suspendeeId}
            onChange={(e) => setSuspendeeId(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter the ID of the suspendee"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Reason</label>
          <textarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter the reason for suspension"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Duration</label>
          <input
            type="text"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter suspension duration (e.g., 3 days)"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Admin Password</label>
          <input
            type="password"
            value={adminPassword}
            onChange={(e) => setAdminPassword(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter your admin password"
          />
        </div>
        <button type="submit" className="w-full text-white bg-amber-500 p-2 rounded">
          Create Suspension
        </button>
      </form>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-green-500 text-white p-4 rounded-lg shadow-lg">
            <p>{modalMessage}</p>
            <button className="mt-2 bg-white text-black px-4 py-2 rounded" onClick={() => setShowModal(false)}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateSuspension;
