import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { navlinks } from "../../../utils/constants";
import logo from '../../../assets/images/logo.svg';
import { useState, useEffect } from "react";
import AdminService from 'src/services/admin.services';
import { Order } from "src/services/types";

const adminService = new AdminService();

function LeftPane() {
    const location = useLocation();
    const navigate = useNavigate();
    const [imageSrc, setImageSrc] = useState({
        show: false,
        iconSrc: ''
    });
    const [newOrderCount, setNewOrderCount] = useState<number>(0);
    const [previousOrderCount, setPreviousOrderCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true); // Add loading state

    useEffect(() => {
        // Fetch new order count from API
        const fetchOrders = async () => {
            setLoading(true); // Set loading to true before fetching
            try {
                const response = await adminService.getnotificationcount(1); // Fetch orders from the first page
                console.log('API response:', response);

                if (Array.isArray(response)) {
                    const filteredOrders = response.filter((order: Order) =>
                        order.order_status === 'confirmed'
                    );
                    const newConfirmedOrders = filteredOrders.length;
                    const newOrderCount = newConfirmedOrders > previousOrderCount ? newConfirmedOrders - previousOrderCount : 0;

                    console.log('New confirmed orders:', newConfirmedOrders);
                    console.log('Previous order count:', previousOrderCount);
                    console.log('New order count:', newOrderCount);

                    setNewOrderCount(newOrderCount);
                    setPreviousOrderCount(newConfirmedOrders);
                } else {
                    throw new Error('Unexpected response format');
                }
            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchOrders();
    }, [previousOrderCount]); // Dependency on previousOrderCount to track changes

    const handleHover = (src: string) => {
        setImageSrc({ show: true, iconSrc: src });
    };

    const handleHoverOut = () => {
        setImageSrc({ show: false, iconSrc: '' });
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        navigate('/pr-admin/login');
    };

    return (
        <nav>
            {/* Logo */}
            <img src={logo} alt="logo" className="mt-[30px]" />
            {/* Nav Links */}
            <div className="mt-[50px]">
                {navlinks.map((item, index) => {
                    const targetRoute = item.to;
                    const isTargetRoute = location.pathname.includes(targetRoute);

                    return item.to === 'logout' ? (
                        <button
                            key={index}
                            onClick={handleLogout}
                            className={isTargetRoute ?
                                "text-pr-primary tracking-[0.02rem] flex items-center md:justify-normal justify-center gap-x-3 md:ml-7 px-2 md:py-3 py-2 md:mb-2 mb-3 hover:text-pr-primary" :
                                'text-black font-light tracking-[0.02rem] flex items-center md:justify-normal justify-center gap-x-3 md:ml-7 px-2 md:py-3 py-2 md:mb-2 mb-3 hover:text-pr-primary'}
                        >
                            {item.icon}
                            <span className="lg:block hidden text-[16px]">{item.name}</span>
                        </button>
                    ) : (
                        <NavLink
                            to={`/pr-admin/${item.to}`}
                            key={index}
                            onMouseOver={() => handleHover(item.name)}
                            onMouseOut={handleHoverOut}
                            className={isTargetRoute ?
                                "text-pr-primary tracking-[0.02rem] flex items-center md:justify-normal justify-center gap-x-3 md:ml-7 px-2 md:py-3 py-2 md:mb-2 mb-3 hover:text-pr-primary" :
                                'text-black font-light tracking-[0.02rem] flex items-center md:justify-normal justify-center gap-x-3 md:ml-7 px-2 md:py-3 py-2 md:mb-2 mb-3 hover:text-pr-primary'}
                        >
                            {item.icon}
                            {item.badge && newOrderCount > 0 && (
                                <span className="relative inline-block ml-2">
                                    {item.icon}
                                    <span className="absolute top-0 right-0 block w-5 h-5 text-xs font-bold text-white bg-red-600 rounded-full items-center justify-center -translate-x-1/2 -translate-y-1/2">
                                        {newOrderCount}
                                    </span>
                                </span>
                            )}
                            <span className="lg:block hidden text-[16px]">{item.name}</span>
                        </NavLink>
                    );
                })}
            </div>
        </nav>
    );
}

export default LeftPane;
