import React, { useEffect, useState, useRef } from 'react';
import AdminService from 'src/services/admin.services';
import { Agent } from 'src/services/types';
import Modal from './Modal2';
import BackButton from 'src/components/BackButton';

const adminService = new AdminService();

const AssignRider: React.FC = () => {
  const [agents, setAgents] = useState<any[]>([]);
  const [orderId, setOrderId] = useState<string>('');
  const [parcelId, setParcelId] = useState<string>('');
  const [selectedAgentId, setSelectedAgentId] = useState<string>('');
  const [latitude, setLatitude] = useState<number>(6.13);
  const [longitude, setLongitude] = useState<number>(6.824);
  const [distance, setDistance] = useState<number>(1000);
  const [limit, setLimit] = useState<number>(10);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState<'success' | 'error'>('success');

  const containerRef = useRef<HTMLDivElement>(null);

  // Fetch agents
  const searchAgents = async () => {
    try {
      const response = await adminService.searchAgents(longitude, latitude, distance, limit);
      if (response && Array.isArray(response)) {
        setAgents(response);
      } else {
        console.error('Failed to fetch agents', response);
      }
    } catch (error: any) {
      console.error('Error fetching agents:', error.response ? error.response.data : error.message);
    }
  };

 
  const assignAgentToOrder = async () => {
    if (!selectedAgentId) {
      setModalMessage('Please select a rider to assign.');
      setModalType('error');
      setIsModalOpen(true);
      return;
    }
  
    try {
      const response = await adminService.assignAgentToOrder({
        order_id: orderId,
        agent_id: selectedAgentId,
      });
  
      if (response && response.success) {
        setModalMessage('Agent assigned successfully');
        setModalType('success');
      } else if (response && response.error) {
        // Display the exact error message from the response
        setModalMessage(response.error);
        setModalType('error');
      } else {
        setModalMessage('Agent assigned successfully');
        setModalType('error');
      }
      setIsModalOpen(true);
    } catch (error: any) {
      // Try to capture the specific error message from the server response in the catch block
      const errorMessage = error.response?.data?.error || 'only confirmed order can be accepted for delivery';
      setModalMessage(errorMessage);
      setModalType('error');
      setIsModalOpen(true);
    }
  };
  

  // Broadcast delivery request
  const broadcastDeliveryRequest = async () => {
    try {
      const data = { latitude, longitude, order_id: orderId, parcel_id: parcelId };
      const response = await adminService.broadcastDeliveryRequest(data);

      if (response.success) {
        setModalMessage('Delivery request broadcasted successfully!');
        setModalType('success');
      } else {
        setModalMessage('Delivery request broadcasted successfully!');
        setModalType('success');
      }
      setIsModalOpen(true);
    } catch {
      setModalMessage('elivery request broadcasted successfully!');
      setModalType('error');
      setIsModalOpen(true);
    }
  };

  // Scroll to bottom on load or when agents change
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [agents]);

  // Handle agent selection
  const handleAgentSelection = (agentId: string) => {
    setSelectedAgentId(agentId);
  };

  return (
    <div className="p-6 h-screen overflow-y-auto">
      <BackButton />
      <h2 className="text-2xl font-bold mb-6">Assign Rider to Order</h2>

      {/* Search Rider Section */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-4">Search for Riders</h3>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block mb-2">Latitude</label>
            <input
              type="number"
              value={latitude}
              onChange={(e) => setLatitude(parseFloat(e.target.value))}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block mb-2">Longitude</label>
            <input
              type="number"
              value={longitude}
              onChange={(e) => setLongitude(parseFloat(e.target.value))}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block mb-2">Distance (in meters)</label>
            <input
              type="number"
              value={distance}
              onChange={(e) => setDistance(parseFloat(e.target.value))}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block mb-2">Limit (Number of Riders)</label>
            <input
              type="number"
              value={limit}
              onChange={(e) => setLimit(parseInt(e.target.value, 10))}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
        </div>

        <button onClick={searchAgents} className="bg-amber-500 text-white px-4 py-2 rounded">
          Search Riders
        </button>

        {/* Display the available agents */}
        <div className="mt-4 overflow-y-auto max-h-96" ref={containerRef}>
          {agents.length > 0 ? (
            <ul className="border border-gray-300 rounded p-4">
              {agents.map((agent) => (
                <li key={agent.agent[0]._id} className="mb-2 flex items-center justify-between">
                  <div>
                    <strong className="text-lg">{agent.agent[0].phone}</strong>
                    <span className="text-gray-500 ml-2">(ID: {agent.agent[0]._id})</span>
                  </div>
                  <button
                    className="ml-4 bg-amber-500 text-white px-2 py-1 rounded"
                    onClick={() => handleAgentSelection(agent.agent[0]._id)}
                  >
                    Select Rider
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No riders found. Please adjust your search criteria.</p>
          )}
        </div>
      </div>

      {/* Assign Rider to Order Section */}
      <div className="p-6">
        <h3 className="text-xl font-bold mb-4">Assign Rider to Order</h3>
        <div className="mb-4">
          <label className="block mb-2">Meal Order ID</label>
          <input
            type="text"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
{/* Assign Rider to Order Section 
        <div className="mb-4">
          <label className="block mb-2">Parcel Order ID</label>
          <input
            type="text"
            value={parcelId}
            onChange={(e) => setParcelId(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
*/}
        <div className="mb-4">
          <label className="block mb-2">Selected Rider ID</label>
          <input
            type="text"
            value={selectedAgentId}
            className="w-full p-2 border border-gray-300 rounded"
            
          />
        </div>

        <button onClick={assignAgentToOrder} className="bg-amber-500 text-white px-4 py-2 rounded">
          Assign Rider
        </button>
      </div>

      {/* Broadcast Delivery Request Section */}
      <div className="p-6">
        <h3 className="text-xl font-bold mb-4">Broadcast Delivery Request</h3>
        <button onClick={broadcastDeliveryRequest} className="bg-amber-500 text-white px-4 py-2 rounded">
          Broadcast Delivery
        </button>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          type={modalType}
          message={modalMessage}
        />
      )}
    </div>
  );
};

export default AssignRider;
