import React from 'react';
import { FaTimes } from 'react-icons/fa';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  title: string;
  message: string;
  type: 'success' | 'error';
}

const Modal: React.FC<ModalProps> = ({ show, onClose, title, message, type }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-96">
        <div className={`p-4 rounded-t-lg ${type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white flex justify-between items-center`}>
          <h2 className="text-lg font-semibold">{title}</h2>
          <button onClick={onClose}>
            <FaTimes className="text-white" />
          </button>
        </div>
        <div className="p-6">
          <p className="text-gray-700">{message}</p>
          <div className="mt-6 flex justify-end">
            <button
              onClick={onClose}
              className={`px-4 py-2 rounded-lg ${type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white hover:bg-opacity-75 transition`}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
