interface ProgressProps {
    progress: string;
    height?: string;
    background?: string;
    progressColor?: string;
}

function ProgressBar({ progress, height, background, progressColor }: ProgressProps) {
    return (
        <div className={`w-full relative ${height ? height : 'h-2'}`}>
            <div className={`w-full ${height ? height : 'h-2'} left-0 top-0 absolute ${background ? background : 'bg-zinc-300'} rounded-xl`} />
            <div className={`${progress} ${height ? height : 'h-2'} left-0 top-0 absolute ${progressColor ? progressColor : 'bg-black'} rounded-xl`} />
        </div>
    );
}

export default ProgressBar;
