import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import AdminService from 'src/services/admin.services';
import RestaurantDetails from './RestaurantDetails';
import RestaurantTab from './RestaurantTab';
import { request } from '../../../hooks/api';
import { Restaurant} from 'src/services/types';
import removeRedEye from '../../../assets/icons/removeRedEye.svg';
import html2canvas from 'html2canvas';
import DeliveryLocation from '../../deliverylocation/DeliveryLocation';
import BankDetailsTab from './BankDetailsTab';
import WalletTab from './WalletTab';



interface OrderItem {
  restaurant_id: any;
  _id: string;
  order_code: string;
  restaurant_name: string;
  subtotal: number;
  total: number;
  delivery_fee: number;
  tax: number;
  date_delivered: string;
  items: {
    meal: {
      _id: string;
      name: string;
      description: string;
      restaurant_id: string;
      price: {
        amount: number;
        currency_code: string;
      };
      image_url?: string;
    };
    qty: number;
  }[];
  user: string;
  order_status: string;
  payment_method: string;
  pickup_time: string;
  agent: string;
  agent_delivered_order_upload: string;
  delivery_location: string;
  enroute_time: string;
  discount: {
    order: number;
    delivery: number;
  };
  voucher: number;
  created_at: string;
  updated_at: string;
  note: string;
  user_received_order_upload: string;
}


interface PayRestaurantProps {
  restaurantId: string;
  // Add other props here if needed
}


const adminService = new AdminService();
const ITEMS_PER_PAGE = 40;

const RestaurantDetailsWrapper: React.FC = () => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [orders, setOrders] = useState<OrderItem[]>([]);
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
  const [ordersError, setOrdersError] = useState<string | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<OrderItem | null>(null);
  const [filterDateRange, setFilterDateRange] = useState<string>('day');
  const [filterStatus, setFilterStatus] = useState<string>('all'); // Updated to include "all" option
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [tab, setTab] = useState<number>(0);

  
  useEffect(() => {
    if (!restaurantId) {
      setError('Restaurant ID is missing');
      setLoading(false);
      return;
    }

    const fetchRestaurantDetails = async () => {
      try {
        const response = await adminService.getRestaurantDetails(restaurantId);
        setRestaurant(response);
      } catch (error) {
        console.error('Error fetching restaurant details:', error);
        setError('Could not fetch restaurant details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurantDetails();
  }, [restaurantId]);

  


useEffect(() => {
  if (tab === 1 && restaurantId && restaurant?.location?.Coordinates) {
    const fetchRestaurantOrders = async () => {
      setOrdersLoading(true);
      try {
        const [longitude, latitude] = restaurant.location.Coordinates || [null, null];
        const maxDistance = 50000; // 50 km in meters

        // Dynamic API query with page number
        const query = `/shopping/order/search?page=1&restaurant_id=${restaurantId}
          &order_status=${filterStatus}&size=40&longitude=${longitude}
          &latitude=${latitude}&max_distance=${maxDistance}`;

        const response = await request(query, 'GET', {}, true, false, false);

        if (Array.isArray(response)) {
          const filteredOrders = response.filter((order: OrderItem) =>
            order._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.order_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.restaurant_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.user.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.agent.toLowerCase().includes(searchTerm.toLowerCase())
          );

          setOrders(filteredOrders);
          setTotalPages(Math.ceil(filteredOrders.length / ITEMS_PER_PAGE)); // Ensure total pages are calculated
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (err) {
        console.error('Failed to fetch orders:', err);
        setOrdersError('Failed to fetch orders');
      } finally {
        setOrdersLoading(false);
      }
    };

    fetchRestaurantOrders();
  }
}, [restaurantId, filterStatus, currentPage, searchTerm, tab, restaurant?.location?.Coordinates]);

 
  
  const handleStatusChange = async (newStatus: boolean) => {
    if (restaurantId && restaurant) {
      try {
        await adminService.updateRestaurantStatus(restaurantId, newStatus);
        setRestaurant({ ...restaurant, is_activated: newStatus });
      } catch (error) {
        console.error('Error updating restaurant status:', error);
      }
    }
  };


  

  const openModal = (order: OrderItem) => {
    setSelectedOrder(order);
  };

  const closeModal = () => {
    setSelectedOrder(null);
  };



  const downloadInvoice = () => {
    const captureElement = document.getElementById('order-details');
  
    if (captureElement && selectedOrder) { // Added null check for selectedOrder
      html2canvas(captureElement, {
        useCORS: true,
        scale: 2, // Increase the scale to improve image quality
        logging: true, // Enable logging to check any issues
      })
        .then(canvas => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = `Order_${selectedOrder._id}.png`; // selectedOrder is now guaranteed to be non-null
          link.click();
        })
        .catch(error => {
          console.error('Error capturing the element:', error);
        });
    } else {
      if (!captureElement) {
        console.error('Capture element is missing');
      }
      if (!selectedOrder) {
        console.error('Selected order is missing');
      }
    }
  };
  

 
  
  
  useEffect(() => {
    // Fetching orders logic goes here...
  }, [filterDateRange, filterStatus, searchTerm]);

  const filterOrdersByDateRange = () => {
    const now = new Date();
    let filteredOrders = orders;

    if (filterDateRange === 'day') {
      filteredOrders = orders.filter(order =>
        new Date(order.date_delivered).toDateString() === now.toDateString()
      );
    } else if (filterDateRange === 'week') {
      const oneWeekAgo = new Date(now.setDate(now.getDate() - 7));
      filteredOrders = orders.filter(order => new Date(order.date_delivered) >= oneWeekAgo);
    } else if (filterDateRange === 'month') {
      const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
      filteredOrders = orders.filter(order => new Date(order.date_delivered) >= oneMonthAgo);
    } else if (filterDateRange === 'year') {
      const oneYearAgo = new Date(now.setFullYear(now.getFullYear() - 1));
      filteredOrders = orders.filter(order => new Date(order.date_delivered) >= oneYearAgo);
    }

    if (filterDateRange === 'all') return orders;

    return filteredOrders;
  };


  
  const handleDownload = () => {
    // Get filtered orders based on the current filter criteria
    const filteredOrders = filterOrdersByDateRange();

    const csvRows = [
      [
        'Order ID', 'Order Code', 'Restaurant', 'Subtotal', 'Total', 'Delivery Fee', 'Tax', 'Date Delivered',
        'Items', 'User', 'Order Status', 'Payment Method', 'Pickup Time', 'Agent',
        'Agent Delivered Order Upload', 'Delivery Location', 'Enroute Time',
        'Discount Order', 'Discount Delivery', 'Voucher', 'Created At', 'Updated At',
        'Note', 'User Received Order Upload'
      ], // CSV header
      ...filteredOrders.map(order => [
        order._id,
        order.order_code,
        order.restaurant_name,
        order.subtotal,
        order.total,
        order.delivery_fee,
        order.tax,
        new Date(order.date_delivered).toLocaleDateString(),
        order.items.map(item => `${item.meal.name} (${item.qty})`).join(', '),
        order.user,
        order.order_status,
        order.payment_method,
        order.agent,
        order.agent_delivered_order_upload,
        order.delivery_location,
        // Check for default/invalid dates before formatting
      new Date(order.date_delivered).getFullYear() !== 1 ? new Date(order.date_delivered).toLocaleDateString() : '',
      new Date(order.pickup_time).getFullYear() !== 1 ? new Date(order.pickup_time).toLocaleDateString() : '',
      new Date(order.enroute_time).getFullYear() !== 1 ? new Date(order.enroute_time).toLocaleDateString() : '',
      new Date(order.created_at).getFullYear() !== 1 ? new Date(order.created_at).toLocaleDateString() : '',
      
      order.agent,
      order.agent_delivered_order_upload,
      order.delivery_location,
      order.discount?.order || '',  // Handle missing discount
      order.discount?.delivery || '',  // Handle missing delivery discount
      order.voucher || '',  // Handle missing voucher
      
      order.note || '',  // Handle missing notes
      order.user_received_order_upload || ''  // Handle missing upload
        
       
      ])
    ];

    const csvContent = 'data:text/csv;charset=utf-8,' + csvRows.map(e => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'filtered_orders.csv');
    document.body.appendChild(link);
    link.click();
  };

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;


  function checkRestaurantByEmail(email: string) {
    throw new Error('Function not implemented.');
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <RestaurantTab setTab={setTab} tab={tab} />
      <div className="mt-6">
        {restaurant && (
          <div className="flex flex-col items-center">
          
            {tab === 0 && <RestaurantDetails
  restaurant={restaurant}
  onStatusChange={handleStatusChange}
  checkRestaurant={async ({ email }: { email: string }) => {
    try {
      const result = checkRestaurantByEmail(email); // This calls the function to check the restaurant
      console.log("Restaurant check result:", result);
      return result; // Return the result if successful
    } catch (error) {
      console.error("Failed to verify restaurant:", error);
      return { message: "Verification failed due to an error." }; // Ensure fallback value
    }
  }}
/>


}
         
            {tab === 1 && (
              <div>
  
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-4">
                    <input
                      type="text"
                      placeholder="Search Orders"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="border border-gray-300 rounded-lg px-4 py-2"
                    />
                         </div>
                         <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="border border-gray-300 rounded-lg px-4 py-2"
          >
             <option value=" ">All</option>
            <option value="delivered">Delivered</option>
            <option value="pending">Pending</option>
            <option value="cancelled">Cancelled</option>
            <option value="confirmed">Confirmed</option>
            <option value="declined">Declined</option>
          </select>

          <select
            value={filterDateRange}
            onChange={(e) => setFilterDateRange(e.target.value)}
            className="border border-gray-300 rounded-lg px-4 py-2"
          >
            <option value="all">All</option>
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select>

                  <button
                    onClick={handleDownload}
                    className="bg-yellow-500 text-white px-4 py-2 rounded-lg"
                  >
                    Download Orders
                  </button>
                </div>

                
                <h2 className="text-2xl font-bold mb-4">Order History</h2>
                {ordersLoading ? (
                  <div className="text-center">Loading orders...</div>
                ) : ordersError ? (
                  <div className="text-center text-red-500">{ordersError}</div>
                ) : orders.length > 0 ? (
                  <div>
                    <table className="min-w-full px-5 py-3 bg-amber-200 bg-opacity-25 rounded-tl-xl rounded-tr-xl border border-b-0 text-left text-xs font-semibold text-black uppercase tracking-wider">
                      <thead>
                        <tr className="bg-amber-200">
                          <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Order ID</th>
                          <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Order Code</th>
                         
                          <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">TotalTotal:₦</th>
                         
                          <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date Delivered</th>
                          <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map(order => (
                          <tr key={order._id} className="border-b border-gray-200 hover:bg-gray-100 cursor-pointer" onClick={() => openModal(order)}>
                            <td className="px-5 py-3">{order._id}</td>
                            
                            <td className="px-5 py-3">{order.order_code}</td>
                            <td className="px-5 py-3">₦{order.total.toFixed(2)}</td>
                            <td className="px-5 py-3">
                    {order.created_at ?
                      `${new Date(order.created_at).toLocaleDateString()} ${new Date(order.created_at).toLocaleTimeString()}`
                      : 'N/A'}
                  </td>
                            <td className="px-5 py-3">
                              <img src={removeRedEye} alt="View Order Details" className="w-5 h-5 cursor-pointer" onClick={() => openModal(order)} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    


                  </div>
                ) : (
                  <div>No orders found for this restaurant.</div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
  

      {selectedOrder && (
  <div className="fixed inset-0 bg-amber-500 bg-opacity-50  flex items-center justify-center z-50">
    <div
      id="order-details"
      className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full relative max-h-screen overflow-y-auto"
    >
      <button
        onClick={closeModal}
        className="absolute top-4 right-4 bg-yellow-500 text-white py-2 px-4 rounded"
      >
        Close
      </button>
      <h2 className="text-2xl font-bold mb-4">Order Details</h2>

      <div className="space-y-2">
        <div className="flex justify-between border-b pb-2">
          <p><strong>Order ID:</strong></p>
          <p>{selectedOrder._id}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p><strong>Order Code:</strong></p>
          <p>{selectedOrder.order_code}</p>
        </div>
        
        <div className="flex justify-between border-b pb-2">
  <p><strong>Date Delivered:</strong></p>
  <p>
    {selectedOrder.date_delivered && 
     new Date(selectedOrder.date_delivered).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.date_delivered).toLocaleDateString()} ${new Date(selectedOrder.date_delivered).toLocaleTimeString()}` 
      : ''}
  </p>
</div>
<div className="flex justify-between border-b pb-2">
  <p><strong>pickup time</strong></p>
  <p>
    {selectedOrder.pickup_time && 
     new Date(selectedOrder.pickup_time).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.pickup_time).toLocaleDateString()} ${new Date(selectedOrder.pickup_time).toLocaleTimeString()}` 
      : ''}
  </p>
</div>

                <div className="flex justify-between border-b pb-2">
                  <p><strong>Order Date:</strong></p>
                  <p>{selectedOrder.created_at ?
                    `${new Date(selectedOrder.created_at).toLocaleDateString()} ${new Date(selectedOrder.created_at).toLocaleTimeString()}`
                    : ' '}</p>
                </div>

                <div className="flex justify-between border-b pb-2">
  <p><strong>created At</strong></p>
  <p>
    {selectedOrder.created_at && 
     new Date(selectedOrder.created_at).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.created_at).toLocaleDateString()} ${new Date(selectedOrder.created_at).toLocaleTimeString()}` 
      : ''}
  </p>
</div>

<div className="flex justify-between border-b pb-2">
  <p><strong>Enroute Time</strong></p>
  <p>
    {selectedOrder.enroute_time && 
     new Date(selectedOrder.enroute_time).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.enroute_time).toLocaleDateString()} ${new Date(selectedOrder.enroute_time).toLocaleTimeString()}` 
      : ''}
  </p>
</div>

        <div className="flex justify-between border-b pb-2">
          <p><strong>Order Status:</strong></p>
          <p>{selectedOrder.order_status}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p><strong>Payment Method:</strong></p>
          <p>{selectedOrder.payment_method}</p>
        </div>
       

        
        <div className="flex justify-between border-b pb-2">
          <p><strong>Agent:</strong></p>
          <Link to={`/pr-admin/agents/agent-details/${selectedOrder.agent}`}>
              <img src={removeRedEye} alt="View" />
            </Link>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p><strong>User:</strong></p>
          
          <Link to={`/pr-admin/users/user-details/${selectedOrder.user}`}>
                
                <img src={removeRedEye} alt="View" />
              </Link>
        </div>

       
        
        <div className="flex justify-between border-b pb-2">
          <p><strong>Agent Delivered Order Upload:</strong></p>
          <p>{selectedOrder.agent_delivered_order_upload}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
        {selectedOrder && <DeliveryLocation locationId={selectedOrder.delivery_location as string} />}

        </div>
       
        <div className="flex justify-between border-b pb-2">
          <p><strong>Discount (Delivery):</strong></p>
          <p>₦{selectedOrder.discount?.delivery?.toFixed(2)}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p><strong>Voucher:</strong></p>
          <p>₦{selectedOrder.voucher?.toFixed(2)}</p>
        </div>
        
        <div className="flex justify-between border-b pb-2">
          <p><strong>Note:</strong></p>
          <p>{selectedOrder.note}</p>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p><strong>Service Charge:</strong></p>
          <p>{selectedOrder.tax}</p>
        </div>
        
        <div className="flex justify-between border-b pb-2">
          <p><strong>User Received Order Upload:</strong></p>
          <p>{selectedOrder.user_received_order_upload}</p>
        </div>
      </div>

      <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2">Items:</h3>
        <ul>
          {selectedOrder.items?.map(item => (
            <li key={item.meal._id} className="mb-2">
              <div className="flex justify-between border-b pb-2">
                <p><strong>Meal Name:</strong></p>
                <p>{item.meal?.name}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Description:</strong></p>
                <p>{item.meal?.description}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Price:</strong></p>
                <p>₦{item.meal?.price.amount.toFixed(2)}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Quantity:</strong></p>
                <p>{item.qty}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <button
        onClick={downloadInvoice}
        className="mt-4 bg-green-500 text-white py-2 px-4 rounded"
      >
        Download Invoice
      </button>
    </div>
  </div>
)}
  
  {tab === 2 && (
            <div>
              {/* Other UI components */}
              {restaurantId && <BankDetailsTab restaurantId={restaurantId} />}
              {/* Continue with your orders table */}
            </div>
          )}


{tab === 3 && ( 
  <div>
    
    {restaurantId && <WalletTab restaurantId={restaurantId} />}
  </div>
          )}

          
 </div>


  

  
  );
};

export default RestaurantDetailsWrapper;