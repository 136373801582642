import React from 'react';
import BackButton from 'src/components/BackButton';

interface UserTabProps {
  setTab: React.Dispatch<React.SetStateAction<number>>;
  tab: number;
}

const UserTab: React.FC<UserTabProps> = ({ setTab, tab }) => {
  return (
    <div className="flex space-x-4 border-b-2 border-gray-200">
        <BackButton />
      <button
        className={`py-2 px-4 ${tab === 0 ? 'border-b-4 border-pr-primary text-pr-primary' : 'text-gray-500'}`}
        onClick={() => setTab(0)}
      >
        User Details
      </button>
      <button
        className={`py-2 px-4 ${tab === 1 ? 'border-b-4 border-pr-primary text-pr-primary' : 'text-gray-500'}`}
        onClick={() => setTab(1)}
      >
        Restaurant Order History
      </button>
      <button
        className={`py-2 px-4 ${tab === 2 ? 'border-b-4 border-pr-primary text-pr-primary' : 'text-gray-500'}`}
        onClick={() => setTab(2)}
      >
       Wallet History
      </button>
    
    </div>
  );
};

export default UserTab;
