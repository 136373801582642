export const API_BASE_URL = "https://api.peakreachdelivery.com/api/v1";

export let token = localStorage.getItem("token");

export const saveToken = (accessToken: string) => {
  localStorage.setItem("token", accessToken);
  token = accessToken;
};

export const logout = async (): Promise<void> => {
  try {
    const response = await fetch(`${API_BASE_URL}/admins/login`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ refreshToken: localStorage.getItem("refreshToken") })
    });
    if (response.ok) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.clear();
      // Redirect to login page if needed
      // window.location.href = "/auth/login";
    } else {
      throw new Error('Logout request failed');
    }
  } catch (error) {
    console.error('Logout Error:', error);
    throw error;
  }
};

export const isTokenExpired = (token: string): boolean => {
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload.exp * 1000 < Date.now();
};
