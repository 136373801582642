import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter, } from "react-router-dom";
import  store  from "./redux/store";
import { ToastContainer } from "react-toastify";
import { routes } from "./utils/routes"


const router = createBrowserRouter(routes);

function App() { 
  return (
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>     
      <ToastContainer 
        hideProgressBar={true}
        position='top-right' 
      />
      {/* <PushNotification /> */}
    </React.StrictMode>
    );
};


export default App;
