import React from 'react';

interface TabProps {
  setTab: (index: number) => void;
  tab: number;
}

const SettingsTabs: React.FC<TabProps> = ({ setTab, tab }) => {
  return (
    <div className="flex gap-6 border-b-2 border-gray-300">
      <div
        onClick={() => setTab(0)}
        className={`py-2 px-4 cursor-pointer ${tab === 0 ? 'border-b-2 border-pr-primary' : ''}`}
      >
        Admin Table
      </div>
      <div
        onClick={() => setTab(1)}
        className={`py-2 px-4 cursor-pointer ${tab === 1 ? 'border-b-2 border-pr-primary' : ''}`}
      >
        Create Admin
      </div>
      <div
        onClick={() => setTab(2)}
        className={`py-2 px-4 cursor-pointer ${tab === 2 ? 'border-b-2 border-pr-primary' : ''}`}
      >
        Remove Admin
      </div>
      <div
        onClick={() => setTab(3)}  // Index for Create Suspension
        className={`py-2 px-4 cursor-pointer ${tab === 3 ? 'border-b-2 border-pr-primary' : ''}`}
      >
        Create Suspension
      </div>
      <div
        onClick={() => setTab(4)}  // Index for Suspension History
        className={`py-2 px-4 cursor-pointer ${tab === 4 ? 'border-b-2 border-pr-primary' : ''}`}
      >
        Suspension History
      </div>
      <div
        onClick={() => setTab(5)}  // Index for Create Meal Category
        className={`py-2 px-4 cursor-pointer ${tab === 5 ? 'border-b-2 border-pr-primary' : ''}`}
      >
         Meal Category
      </div>
      <div
        onClick={() => setTab(6)}  // Index for Meal Categories
        className={`py-2 px-4 cursor-pointer ${tab === 6 ? 'border-b-2 border-pr-primary' : ''}`}
      >
      Create  Meal Categories
      </div>
    </div>
  );
};

export default SettingsTabs;
