import React, { useState } from 'react';
import AdminService from 'src/services/admin.services';


// Update the MealCategoryProps interface
interface MealCategoryProps {
  data: { _id: string; name: string }[];
  currentPage: number;
  totalPages: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  onDeleteMeal: (id: string) => Promise<void>; // Add this line
}



const MealCategory: React.FC<MealCategoryProps> = ({
  data,
  onPageChange,
  onDeleteMeal, // Destructure onDeleteMeal prop
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  
  const handleDelete = async (id: string) => {
    try {
      await AdminService.deleteMeal(id);
      setSuccessMessage('Meal deleted successfully!');
    } catch (error) {
      console.error('Error deleting meal category:', error);
      // Type guard to check if the error is an instance of Error
      const errorMessage = error instanceof Error ? error.message : 'Error deleting meal category. Please try again.';
      setErrorMessage(errorMessage);
    }
  };
  

  return (
    <div className='overflow-x-auto'>
      <h3 className="text-xl font-semibold mb-4">Meal Categories</h3>
      {errorMessage && <div className="text-red-500">{errorMessage}</div>}
      {successMessage && <div className="text-green-500">{successMessage}</div>}
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((category) => (
            <tr key={category._id}>
              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">{category.name}</td>
              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                <button
                  className="bg-red-500 text-white px-4 rounded"
                  onClick={() => handleDelete(category._id)} // Call the delete function
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MealCategory;

