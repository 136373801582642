import React from 'react';

function ErrorPage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-800">
      <h1 className="text-8xl font-extrabold text-amber-600">404</h1>
      <p className="text-xl mt-4">Oops! The page you are looking for does not exist.</p>
      <p className="text-gray-500 mt-2">It might have been moved or deleted.</p>
      <a 
        href="/" 
        className="mt-6 px-6 py-2 bg-amber-600 text-white rounded-lg shadow-md hover:bg-amber-700 transition duration-300"
      >
        Go Back To Home
      </a>
    </div>
  );
}

export default ErrorPage;
