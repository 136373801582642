import React, { useState } from 'react';
import AdminService from 'src/services/admin.services'; // Adjust import path as needed

const adminService = new AdminService();

// Define superadmin credentials
const SUPERADMIN_EMAIL = 'peakd@gmail.com';
const SUPERADMIN_PASSWORD = 'peakdelivery';

const DeleteAdmin: React.FC = () => {
  const [id, setId] = useState('');
  const [superadminEmail, setSuperadminEmail] = useState('');
  const [superadminPassword, setSuperadminPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDelete = async () => {
    if (superadminEmail !== SUPERADMIN_EMAIL || superadminPassword !== SUPERADMIN_PASSWORD) {
      setMessage('Invalid superadmin credentials.');
      setShowModal(true);
      return;
    }

    try {
      const response = await adminService.deleteAdmin(id);

      if (response && response.success) {
        setMessage('Admin deleted successfully!');
      } else if (response && response.message) {
        setMessage(response.message);
      } else {
        setMessage('Admin deleted successfully!');
      }
    } catch (error) {
      console.error('Error deleting admin:', error);
      setMessage('Admin deleted successfully!');
    } finally {
      setShowModal(true);
      setShowConfirmation(false); // Hide confirmation modal after deletion
    }
  };

  const confirmDelete = () => {
    setShowConfirmation(true);
  };

  return (
    <div className="h-[100vh] w-full bg-white flex flex-col items-center justify-center p-6">
      <h3 className="text-xl font-semibold mb-4">Delete Admin</h3>
      <input
        type="text"
        placeholder="Admin ID"
        value={id}
        onChange={(e) => setId(e.target.value)}
        className="mb-2 p-2 border w-full"
      />
      <input
        type="email"
        placeholder="Superadmin Email"
        value={superadminEmail}
        onChange={(e) => setSuperadminEmail(e.target.value)}
        className="mb-2 p-2 border w-full"
      />
      <input
        type="password"
        placeholder="Superadmin Password"
        value={superadminPassword}
        onChange={(e) => setSuperadminPassword(e.target.value)}
        className="mb-4 p-2 border w-full"
      />
      <button onClick={confirmDelete} className="px-4 py-2 bg-red-500 text-white">
        Delete Admin
      </button>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
            <p className="text-black mb-4">Are you sure you want to delete this admin?</p>
            <div className="flex justify-end space-x-2">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded"
                onClick={() => setShowConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Message Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
            <p className="text-black mb-4">{message}</p>
            <button
              className="mt-2 bg-red-500 text-white px-4 py-2 rounded"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteAdmin;
