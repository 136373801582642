import React, { useEffect, useState } from 'react';
import { request } from 'src/hooks/api';
import AdminService from 'src/services/admin.services';
import wallet from '../../../assets/images/wallet.png';

// Modal component for displaying error/success messages
const Modal: React.FC<{ message: string; onClose: () => void }> = ({ message, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-96 text-center">
      <p className="text-lg mb-4">{message}</p>
      <button className="bg-red-500 text-white py-2 px-4 rounded" onClick={onClose}>
        Close
      </button>
    </div>
  </div>
);

interface WalletProps {
  restaurantId: string;
}

interface WalletData {
  balance: { amount: number; currency_code: string };
  rewards: {
    bonus: { registration?: number };
    free_delivery: number;
    discount: { order: number; delivery: number };
  };
  created_at: string;
  updated_at: string;
}

const WalletTab: React.FC<WalletProps> = ({ restaurantId }) => {
  const [walletData, setWalletData] = useState<WalletData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [debitAmount, setDebitAmount] = useState<string>(''); // Initialize as an empty string
  const [debitHistory, setDebitHistory] = useState<any[]>([]);
  const [modalMessage, setModalMessage] = useState<string | null>(null); // For modal messages
  const adminService = new AdminService();

  useEffect(() => {
    const fetchRestaurantWallet = async () => {
      setLoading(true);
      try {
        const response = await request(
          `/restaurants/wallet/${restaurantId}`,
          "GET",
          {},
          true,
          false,
          false
        );
        if (response && response.balance) {
          setWalletData(response);
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (error) {
        setError('Error fetching wallet information');
      } finally {
        setLoading(false);
      }
    };

    if (restaurantId) {
      fetchRestaurantWallet();
    } else {
      setError('Invalid restaurantId');
      setLoading(false);
    }

    // Retrieve debit history for this restaurantId
    const storedHistory = JSON.parse(localStorage.getItem(`debitHistory_${restaurantId}`) || '[]');
    setDebitHistory(storedHistory);

  }, [restaurantId]);

  const handleDebit = async () => {
    const amountToDebit = Number(debitAmount.trim()); // Convert to number and trim input

    if (isNaN(amountToDebit) || amountToDebit <= 0) {
      setModalMessage('Please enter a valid debit amount');
      return;
    }

    try {
      // Make the request to the restaurant wallet debit endpoint
      const response = await request(
        `/restaurants/wallet-debit`, // Endpoint for restaurant debit
        "PATCH",
        { amount: amountToDebit, restaurantId }, // Payload with amount and restaurantId
        true,  // Authenticated request
        false, // No need to process file uploads
        false  // No raw response needed
      );

      // Update the wallet balance in state if the request succeeds
      setWalletData((prev) => {
        if (prev) {
          return {
            ...prev,
            balance: {
              ...prev.balance,
              amount: prev.balance.amount - amountToDebit
            }
          };
        }
        return prev;
      });

      // Update debit history
      const newHistoryEntry = { date: new Date().toLocaleString(), amount: amountToDebit };
      const updatedHistory = [...debitHistory, newHistoryEntry];
      setDebitHistory(updatedHistory);

      // Store updated history in localStorage
      localStorage.setItem(`debitHistory_${restaurantId}`, JSON.stringify(updatedHistory));

      // Set success modal message
      setModalMessage('Debit successfully processed!');
      setDebitAmount(''); // Clear input after processing
    } catch (error) {
      // Handle the error
      const errorMessage = error instanceof Error ? error.message : 'Error processing debit';
      setModalMessage(errorMessage);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!walletData) {
    return <div>No wallet data available</div>;
  }

  return (
    <div>
      {/* Wallet Info Section */}
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
        <div className="flex items-center mb-4">
          <img src={wallet} alt="Wallet Icon" className="w-20 h-20 mr-2" />
          <h3 className="text-2xl font-semibold">Restaurant Wallet Information</h3>
        </div>
        <p className="text-lg">
          <strong>Balance:</strong> ₦{walletData.balance.amount}
        </p>
        {/* Safely access rewards.bonus */}
        <p className="text-lg">
          <strong>Registration Bonus:</strong> ₦{walletData.rewards?.bonus?.registration || 0}
        </p>
        <p className="text-lg">
          <strong>Free Delivery:</strong> {walletData.rewards?.free_delivery || 0} times
        </p>
        <p className="text-lg">
          <strong>Discount on Orders:</strong> {walletData.rewards?.discount?.order || 0}%
        </p>
        <p className="text-lg">
          <strong>Discount on Delivery:</strong> {walletData.rewards?.discount?.delivery || 0}%
        </p>
        <p className="text-sm text-gray-500 mt-4">
          <strong>Created At:</strong> {new Date(walletData.created_at).toLocaleString()}
        </p>
        <p className="text-sm text-gray-500">
          <strong>Updated At:</strong> {new Date(walletData.updated_at).toLocaleString()}
        </p>
      </div>

      {/* Debit Section */}
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg mt-4">
        <h3 className="text-2xl font-semibold mb-4">Debit Restaurant</h3>
        <input
          type="number"
          className="border p-2 w-full mb-4"
          placeholder="Enter debit amount"
          value={debitAmount}
          onChange={(e) => setDebitAmount(e.target.value)} // Controlled input as string
        />
        <button
          className="bg-amber-500 text-white py-2 px-4 rounded w-full"
          onClick={handleDebit}
        >
          Proceed
        </button>
      </div>

      {/* Debit History Section */}
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg mt-4">
        <h3 className="text-2xl font-semibold mb-4">Debit History</h3>
        {debitHistory.length > 0 ? (
          <ul>
            {debitHistory.map((entry, index) => (
              <li key={index} className="mb-2">
                <strong>Date:</strong> {entry.date} - <strong>Amount:</strong> ₦{entry.amount}
              </li>
            ))}
          </ul>
        ) : (
          <p>No debit history available</p>
        )}
      </div>

      {/* Modal for Success/Error messages */}
      {modalMessage && (
        <Modal
          message={modalMessage}
          onClose={() => setModalMessage(null)}
        />
      )}
    </div>
  );
};

export default WalletTab;
