import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../hooks/request'; // Ensure this path is correct

const Logout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = () => {
      try {
        logout(); // Perform the local logout process
        navigate('/pr-admin/login'); // Redirect to login page
      } catch (error) {
        console.error('Logout failed:', error);
        // Optionally handle the error (e.g., show an error message)
      }
    };

    performLogout();
  }, [navigate]);

  return <div>Logging out...</div>; // Optional: show a message while logging out
};

export default Logout;
