import React, { useState } from 'react';
import { Agent } from '../../../services/types'; // Adjust the import path as needed
import Modal from './Modal'; // Adjust the path as needed
import AdminService from '../../../services/admin.services'; // Adjust the path if needed

const adminService = new AdminService();

interface AgentDetailsProps {
  agent: Agent;
  onStatusChange: (newStatus: string) => void;
}

const AgentDetails: React.FC<AgentDetailsProps> = ({ agent, onStatusChange }) => {
  // Initialize status based on both is_suspended and is_checked_by_admin properties
  const [status, setStatus] = useState(() => {
    if (agent.is_suspended) return 'suspended';
    if (!agent.is_checked_by_admin) return 'inactive';
    return 'active';
  });
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    message: '',
    type: 'success' as 'success' | 'error',
    action: () => {},
  });

  const handleToggleStatus = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const response = await adminService.checkAgent({ email: agent.email });
      console.log('Toggle status response:', response);

      const newStatus = status === 'active' ? 'suspended' : 'active';
      const actionMessage = newStatus === 'active' ? 'activated' : 'deactivated';

      setModalContent({
        title: `${newStatus.charAt(0).toUpperCase() + newStatus.slice(1)} Agent`,
        message: response.success || `Agent ${agent.first_name} ${agent.last_name} ${actionMessage} successfully.`,
        type: 'success',
        action: () => {
          setStatus(newStatus);
          onStatusChange(newStatus);
          setIsModalOpen(false);
        },
      });
    } catch (error) {
      console.error('Error toggling agent status:', error);
      let errorMessage = `Failed to ${status === 'active' ? 'deactivate' : 'activate'} agent.`;
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setModalContent({
        title: '',
        message: errorMessage,
        type: 'error',
        action: () => setIsModalOpen(false),
      });
    } finally {
      setIsModalOpen(true);
    }
  };

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="relative">
        <img
          src={agent.image_url || 'placeholder-image-url'}
          alt={`${agent.first_name} ${agent.last_name}`}
          className="w-full h-64 object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-40"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white text-2xl font-bold bg-black bg-opacity-50 p-4">
          {agent.first_name} {agent.last_name}
        </div>
      </div>
      <div className="p-6">
        <div className="flex items-center mb-6">
          <img
            src={agent.image_url || 'placeholder-image-url'}
            alt={`${agent.first_name} ${agent.last_name}`}
            className="w-24 h-24 object-cover rounded-full border-4 border-gray-300"
          />
          <div className="ml-4">
            <h2 className="text-3xl font-bold text-gray-800">{agent.first_name} {agent.last_name}</h2>
            <p className="text-gray-600 text-lg">{agent.email}</p>
            <p className="text-gray-600 text-lg">{agent.phone}</p>
          </div>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700">Date of Birth</h3>
          <p className="text-gray-600 text-lg">{agent.date_of_birth || 'N/A'}</p>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700">Agent Type</h3>
          <p className="text-gray-600 text-lg">{agent.agent_type || 'N/A'}</p>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700">Status</h3>
          <p className="text-gray-600 text-lg">
            {status === 'active' && agent.is_checked_by_admin ? 'Active' : status === 'suspended' ? 'Suspended' : 'Inactive'}
          </p>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700">Last Login</h3>
          <p className="text-gray-600 text-lg">{agent.last_login || 'N/A'}</p>
        </div>
        <div className="mt-6">
          <button
            onClick={handleToggleStatus}
            className={`px-4 py-2 rounded-lg text-white transition ${
              status === 'active' ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
            }`}
          >
            {status === 'active' ? 'Deactivate' : 'Activate'}
          </button>
        </div>
      </div>

      {/* Modal */}
      <Modal
        show={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={modalContent.title}
        message={modalContent.message}
        type={modalContent.type}
      />
    </div>
  );
};

export default AgentDetails;
