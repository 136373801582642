import React, { useState } from 'react';
import { UserDetailsProps } from 'src/services/types'; // Adjust the import path as needed
import Modal from './Modal'; // Adjust the path as needed
import { FaCopy } from 'react-icons/fa';



const UserDetails: React.FC<UserDetailsProps> = ({ user, onStatusChange }) => {
  const [status, setStatus] = useState(user.is_suspended ? 'suspended' : 'active');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    message: '',
    type: 'success' as 'success' | 'error',
    action: () => {},
  });

  const [copied, setCopied] = useState(false);


  const handleCopy = () => {
    navigator.clipboard.writeText(user._id);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
    };

  

  

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
     
     <div>
      <div className="h-11 p-2.5 rounded-xl border border-amber-500 flex justify-between border-b pb-2 items-start gap-2.5">
        <div>User ID:</div>
        <div className="flex items-center gap-2">
          <div className="px-5 py-3">{user._id}</div>
          <button onClick={handleCopy} className="text-amber-500 hover:text-amber-600">
            <FaCopy />
          </button>
        </div>
      </div>
      <hr />
      {copied && <div className="text-sm text-green-500 mt-2">Copied to clipboard!</div>}
    </div>
<br></br>
      <div className="relative">
        <img
          src={user.image_url || 'placeholder-image-url'}
          alt={`${user.first_name} ${user.last_name}`}
          className="w-full h-64 object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-40"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white text-2xl font-bold bg-black bg-opacity-50 p-4">
          {user.first_name} {user.last_name}
        </div>
      </div>
      <div className="p-6">
        <div className="flex items-center mb-6">
          <img
            src={user.image_url || 'placeholder-image-url'}
            alt={`${user.first_name} ${user.last_name}`}
            className="w-24 h-24 object-cover rounded-full border-4 border-gray-300"
          />
          <div className="ml-4">
            <h2 className="text-3xl font-bold text-gray-800">{user.first_name} {user.last_name}</h2>
            <p className="text-gray-600 text-lg">{user.email}</p>
            <p className="text-gray-600 text-lg">{user.phone}</p>
          </div>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700">Account Status</h3>
          <p className="text-gray-600 text-lg">{status === 'active' ? 'Active' : 'Suspended'}</p>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700">Last Login</h3>
          <p className="text-gray-600 text-lg">{user.last_login || 'N/A'}</p>
        </div>
        
      </div>

      {/* Modal */}
      <Modal
        show={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={modalContent.title}
        message={modalContent.message}
        type={modalContent.type}
      />
    </div>
  );
};

export default UserDetails;
