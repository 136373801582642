import React from 'react';
import BackButton from 'src/components/BackButton';

interface TabProps {
  setTab: (tab: number) => void;
  tab: number;
}

function RestaurantTab({ setTab, tab }: TabProps) {
  return (
    
    <div className="flex justify-center items-center gap-6 border-b border-gray-200">
        <BackButton />
      <div
        onClick={() => setTab(0)}
        className={`cursor-pointer py-2 px-4 text-center ${
          tab === 0 ? 'border-b-2 border-pr-primary' : 'text-gray-500'
        }`}
      >
        Restaurant Details
      </div>
      <div
        onClick={() => setTab(1)}
        className={`cursor-pointer py-2 px-4 text-center ${
          tab === 1 ? 'border-b-2 border-pr-primary' : 'text-gray-500'
        }`}
      >
        Order History
      </div>

      <div
        onClick={() => setTab(2)}
        className={`cursor-pointer py-2 px-4 text-center ${
          tab === 2 ? 'border-b-2 border-pr-primary' : 'text-gray-500'
        }`}
      >
       Bank Details
      </div>
      
      <div
        onClick={() => setTab(3)}
        className={`cursor-pointer py-2 px-4 text-center ${
          tab === 3 ? 'border-b-2 border-pr-primary' : 'text-gray-500'
        }`}
      >
       Wallet History
      </div>
      
      
    </div>
  );
}

export default RestaurantTab;
