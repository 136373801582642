import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Logistic } from 'src/services/types';
import { Link } from 'react-router-dom';
import removeRedEye from '../../../assets/icons/removeRedEye.svg';

// In LogisticDetails.tsx
export interface LogisticDetailsProps {
  logistic: Logistic;
  onClose: () => void;
  handleStatusChange: (logistic: Logistic, newStatus: string) => void;
  successMessage: string | null;
  error: string | null;
}

const mapContainerStyle = {
  height: '400px',
  width: '100%',
};

const modalStyle: React.CSSProperties = {
  maxWidth: 'lg',
  width: '100%',
  padding: '1.5rem',
  borderRadius: '0.5rem',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  backgroundColor: 'white',
  position: 'relative',
  maxHeight: '80vh', // Ensure modal is scrollable
  overflowY: 'auto', // Use 'auto' for vertical scrolling
};

const LogisticDetails: React.FC<LogisticDetailsProps> = ({ logistic, onClose, successMessage, error }) => {
  const center = {
    lat: logistic.pickup_location.latitude || 0,
    lng: logistic.pickup_location.longitude || 0,
  };

  return (
    <div className="modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" role="dialog" aria-labelledby="logistic-details-modal">
      <div className="modal-content" style={modalStyle}>
        <button onClick={onClose} className="absolute top-4 right-4 bg-yellow-500 text-white py-2 px-4 rounded">
          Close
        </button>

        {/* Success/Failure message */}
        {successMessage && <div className="text-green-600 mb-4">{successMessage}</div>}
        {error && <div className="text-red-600 mb-4">{error}</div>}

       <div className="mb-6 border-b pb-2">
          <p><strong>Tracking ID:</strong> {logistic.trackingId}</p>
        </div>
        <div className="mb-6 border-b pb-2">
          <p><strong>Pickup Time:</strong> {new Date(logistic.picked_up_time).toLocaleString()}</p>
        </div>
        <div className="mb-6 border-b pb-2">
          <p><strong>Pickup Address:</strong> {logistic.pickup_location.address}</p>
        </div>
        <div className="mb-6 border-b pb-2">
          <p><strong>Delivery Address:</strong> {logistic.receiver.address.address}</p>
        </div>
        <div className="mb-6 border-b pb-2">
          <p><strong>Receiver Name:</strong> {logistic.receiver.name}</p>
        </div>
        <div className="mb-6 border-b pb-2">
          <p><strong>Status:</strong> {logistic.order_status}</p>
        </div>
        
        <div className="mb-6 border-b pb-2">
          <p><strong>Item Size:</strong> {logistic.item_size}</p>
        </div>
        <div className="mb-6 border-b pb-2">
          <p><strong>Created At:</strong> {new Date(logistic.created_at).toLocaleString()}</p>
        </div>
        <div className="mb-6 border-b pb-2">
          <p><strong>Delivered Time:</strong> {new Date(logistic.delivered_time).toLocaleString()}</p>
        </div>
        <div className="mb-6 border-b pb-2">
          <p><strong>Enroute Time:</strong> {new Date(logistic.enroute_time).toLocaleString()}</p>
        </div>
        <div className="mb-6 border-b pb-2">
          <p><strong>Arrived Time:</strong> {new Date(logistic.arrived_time).toLocaleString()}</p>
        </div>
        
        <div className="mb-6 border-b pb-2">
          <p><strong>Agent ID:</strong> {logistic.agentId}</p>
        </div>
        <div className="mb-6 border-b pb-2">

           
          <p><strong>Agent:</strong> </p>
       
 <Link to={`/pr-admin/agents/agent-details/${logistic.agentId}`}>
                    
               View Agent     <img src={removeRedEye} alt="View" />
                  </Link>
        </div>

        <div className="mb-6 border-b pb-2">

           
          <p><strong>User:</strong> </p>
        <Link to={`/pr-admin/users/user-details/${logistic.userId}`}>
                    
               View User     <img src={removeRedEye} alt="View" />
                  </Link>
        </div>


       

        <div className="mb-6 border-b pb-2">
          <p><strong>Description:</strong> {logistic.description || 'N/A'}</p>
        </div>
        <div className="mb-6 border-b pb-2">
          <p><strong>Payment Method:</strong> {logistic.payment_method}</p>
        </div>

        <div className="map-container mb-6">
        <LoadScript googleMapsApiKey="AIzaSyD845dpQ62RHqNW83JcyA5YKaRQ05UVl8I">
        <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={10}>
  <Marker
    position={{
      lat: logistic.pickup_location.latitude ?? 0, // Use 0 if undefined
      lng: logistic.pickup_location.longitude ?? 0, // Use 0 if undefined
    }}
    label="Pickup Location"
  />
  {logistic.receiver.address.latitude && logistic.receiver.address.longitude && (
    <Marker
      position={{
        lat: logistic.receiver.address.latitude ?? 0, // Use 0 if undefined
        lng: logistic.receiver.address.longitude ?? 0, // Use 0 if undefined
      }}
      label="Delivery Location"
    />
  )}
</GoogleMap>

          </LoadScript>
        </div>

        <div className="mb-6 border-b pb-2">
          <p><strong>Cost:</strong> ₦{logistic.cost.toFixed(2)}</p>
        </div>
        <div className="mb-6 border-b pb-2">
          <p><strong>Service Fee:</strong> ₦{logistic.tax.toFixed(2)}</p>
        </div>
        <div className="mb-6 border-b pb-2">
          <p><strong>Total:</strong> ₦{logistic.total.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

export default LogisticDetails;
