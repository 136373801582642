import React from 'react';
import { Transaction } from '../../../services/types'; // Adjust the path if needed
import { Link } from 'react-router-dom';
import removeRedEye from '../../../assets/icons/removeRedEye.svg'; // Ensure this path is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCalendarDay, faCalendarWeek, faCalendarAlt, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';

interface AgentTransactionsProps {
  transactions: Transaction[];
  loading: boolean;
  error: string | null;
}

const AgentTransactions: React.FC<AgentTransactionsProps> = ({ transactions, loading, error }) => {
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [filter, setFilter] = React.useState<string>('all');
  const [page, setPage] = React.useState<number>(1);
  const [size] = React.useState<number>(10); // Set size to a smaller number for better pagination

  const downloadCSV = () => {
    const csvData = transactions.map(transaction => ({
      ID: transaction._id,
      Amount: transaction.amount,
      Status: transaction.status,
      Description: transaction.description,
      Type: transaction.type,
      'Reg. Bonus Used': transaction.registration_bonus_used,
      'Ref. Bonus Used': transaction.referral_bonus_used,
      'Trans. Code': transaction.transaction_code,
      'Created At': new Date(transaction.created_at).toLocaleString(),
      'Updated At': new Date(transaction.updated_at).toLocaleString(),
    }));

    const csvContent = [
      Object.keys(csvData[0]).join(','),
      ...csvData.map(row => Object.values(row).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'agent_transactions.csv');
  };

  const filterByDateRange = (range: string) => {
    const now = new Date();
    let filteredTransactions = transactions;

    if (range === 'day') {
      filteredTransactions = transactions.filter(transaction => {
        const transactionDate = new Date(transaction.created_at);
        return transactionDate.toDateString() === now.toDateString();
      });
    } else if (range === 'week') {
      const weekAgo = new Date(now);
      weekAgo.setDate(weekAgo.getDate() - 7);
      filteredTransactions = transactions.filter(transaction => {
        const transactionDate = new Date(transaction.created_at);
        return transactionDate >= weekAgo && transactionDate <= now;
      });
    } else if (range === 'month') {
      const monthAgo = new Date(now);
      monthAgo.setMonth(monthAgo.getMonth() - 1);
      filteredTransactions = transactions.filter(transaction => {
        const transactionDate = new Date(transaction.created_at);
        return transactionDate >= monthAgo && transactionDate <= now;
      });
    }

    return filteredTransactions;
  };

  const filteredTransactionsByDate = filterByDateRange(filter);

  const handleSearch = (transactions: Transaction[]) => {
    return transactions.filter(transaction =>
      transaction._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      transaction.description.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const filteredTransactions = handleSearch(filteredTransactionsByDate);

  const paginatedTransactions = filteredTransactions.slice((page - 1) * size, page * size);
  const totalPages = Math.ceil(filteredTransactions.length / size);

  return (
    <div className="p-4 mt-4 bg-gray-100 rounded-md shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Agent Transactions</h2>
        <button onClick={downloadCSV} className="text-black text-base font-normal font-['Nunito'] leading-normal">
          <FontAwesomeIcon icon={faDownload} /> Download CSV
        </button>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search Transactions"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border p-2 rounded-md w-full"
        />
        <div className="mt-2">
          <button onClick={() => setFilter('day')} className={`p-2 ${filter === 'day' ? 'bg-amber-500 text-white' : 'bg-gray-200 text-gray-600'} rounded-md`}>
            <FontAwesomeIcon icon={faCalendarDay} /> Today
          </button>
          <button onClick={() => setFilter('week')} className={`p-2 ${filter === 'week' ? 'bg-amber-500 text-white' : 'bg-gray-200 text-gray-600'} rounded-md ml-2`}>
            <FontAwesomeIcon icon={faCalendarWeek} /> Last Week
          </button>
          <button onClick={() => setFilter('month')} className={`p-2 ${filter === 'month' ? 'bg-amber-500 text-white' : 'bg-gray-200 text-gray-600'} rounded-md ml-2`}>
            <FontAwesomeIcon icon={faCalendarAlt} /> Last Month
          </button>
        </div>
      </div>

      <table className="min-w-full bg-white border border-gray-200 rounded-md">
        <thead>
          <tr>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">ID</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Description</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Type</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Registration Bonus Used</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Referral Bonus Used</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Created At</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Updated At</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">View Agent</th>
          </tr>
        </thead>
        <tbody>
          {paginatedTransactions.map(transaction => (
            <tr key={transaction._id}>
              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">{transaction._id}</td>
              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">₦{transaction.amount.toFixed(2)}</td>
              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">{transaction.status}</td>
              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">{transaction.description}</td>
              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">{transaction.type}</td>
              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">₦{transaction.registration_bonus_used.toFixed(2)}</td>
              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">₦{transaction.referral_bonus_used.toFixed(2)}</td>

              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">{new Date(transaction.created_at).toLocaleString()}</td>
              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">{new Date(transaction.updated_at).toLocaleString()}</td>
              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
               
                <Link to={`/pr-admin/agents/agent-details/${transaction.agent_id}`}>
              <img src={removeRedEye} alt="View" />
            </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-4 flex justify-between items-center">
        <button onClick={() => setPage(prevPage => Math.max(prevPage - 1, 1))} disabled={page === 1} className="h-11 p-2.5 rounded-xl border border-amber-500 justify-center items-start gap-2.5 flex">
          <FontAwesomeIcon icon={faArrowLeft} />
       Previous
        </button>
        <span className="text-sm">
          Page {page} of {totalPages}
        </span>
        <button onClick={() => setPage(prevPage => Math.min(prevPage + 1, totalPages))} disabled={page === totalPages} className="h-11 p-2.5 rounded-xl border border-amber-500 justify-center items-start gap-2.5 flex">
        Next  <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

export default AgentTransactions;
