import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import AdminService from '../../services/admin.services';

const initialState = {
    email: '',
    password: '',
}

function Login() {
    const [formData, setFormData] = useState(initialState);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const navigate = useNavigate();

    const inputStyle = "w-full px-[20px] py-[12px] border border-black text-[14px] rounded-[8px]";

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const { email, password } = formData;
            const adminService = new AdminService();
            const response = await adminService.login(email, password);

            if (response && response.token) {
                setModalMessage("Login successful! Redirecting...");
                setShowSuccessModal(true);
                setTimeout(() => navigate('/pr-admin/dashboard'), 2000); // Redirect after 2 seconds
            } else {
                setModalMessage("Login failed. Please check your credentials.");
                setShowErrorModal(true);
            }
        } catch (error) {
            console.error('Login error:', error);
            setModalMessage("An error occurred. Please try again later.");
            setShowErrorModal(true);
        }
    }

    const Modal = ({ message, isSuccess, onClose }: { message: string, isSuccess: boolean, onClose: () => void }) => (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className={`bg-${isSuccess ? 'green' : 'red'}-500 text-white p-4 rounded-lg shadow-lg`}>
                <p>{message}</p>
                <button className="mt-2 bg-white text-black px-4 py-2 rounded" onClick={onClose}>Close</button>
            </div>
        </div>
    );

    return (
        <div className="h-[100vh] w-full bg-white">
            <img src={logo} alt="logo" className="mx-auto lg:w-[360px] md:w-[200px] mb-[3.5rem] mt-[105px]" />
            <div className="lg:w-[420px] md:w-[70%] sm:w-[70%] mx-auto px-5 md:px-0">
                <h2 className="text-center md:text-[27px] text-[23px] font-bold leading-[48px] mb-[2.5rem]">ADMIN LOGIN</h2>
                <form onSubmit={handleSubmit} className="grid gap-y-7">
                    <input 
                        placeholder="Email"
                        type="email"
                        className={`${inputStyle}`}
                        value={formData.email}
                        onChange={(e) => setFormData({...formData, email: e.target.value})}
                    />
                    <input 
                        placeholder="Password"
                        type="password"
                        className={`${inputStyle}`}
                        value={formData.password}
                        onChange={(e) => setFormData({...formData, password: e.target.value})}
                    />
                    <button type="submit" className="w-full text-black font-normal bg-pr-primary rounded-[8px] px-[20px] py-[12px] text-[18px] mt-[3rem]">Login</button>
                </form>
            </div>
            {showSuccessModal && <Modal message={modalMessage} isSuccess={true} onClose={() => setShowSuccessModal(false)} />}
            {showErrorModal && <Modal message={modalMessage} isSuccess={false} onClose={() => setShowErrorModal(false)} />}
        </div>
    )
}

export default Login;
