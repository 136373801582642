import {
  AgentManagement,
  Dashboard,
  ErrorPage,
  Finance,
  Login,
  RestaurantManagement,
  UserManagement,
} from "../pages";

import Logout from "src/pages/auth/Logout";
import RestaurantWrapper from "src/pages/restaurant/components/RestaurantWrapper";
import UserDetailsWrapper from "../pages/user/components/UserDetailsWrapper";
import AgentDetailsWrapper from "../pages/agent/components/AgentDetailsWrapper";
import SettingsWrapper from "src/pages/settings/components/SettingsWrapper";
import NotificationWrapper from "src/pages/notification/NotificationWrapper";
import DeclinedOrders from "src/pages/agent/components/DeclinedOrders";
import LogisticsManagement from "src/pages/logistic/Logistics";
import LogisticsWrapper from "src/pages/logistic/components/LogisticsWrapper";
import DeliveryLocation from "src/pages/deliverylocation/DeliveryLocation";
import AssignRider from "src/pages/assignRider/AssignRider";

type UserRouteType = {
  path: string;
  element: JSX.Element;
  exact?: boolean;
};

const routes: Array<UserRouteType> = [
  { path: "*", element: <ErrorPage /> },
  { path: "/pr-admin/login", element: <Login /> },
  { path: "/pr-admin/logout", element: <Logout /> },
  { path: "/pr-admin/dashboard", element: <Dashboard /> },
  { path: "/pr-admin/settings", element: <SettingsWrapper /> },
  { path: "/pr-admin/users", element: <UserManagement /> },
  { path: "/pr-admin/restaurants", element: <RestaurantManagement /> },
  { path: "/pr-admin/agents", element: <AgentManagement /> },
  { path: "/pr-admin/finances", element: <Finance /> },
  { path: "/pr-admin/notification", element: <NotificationWrapper /> },
  { path: "/pr-admin/logistics", element: <LogisticsManagement /> }, // Logistics Management route
  { path: "/pr-admin/users/logistics/", element: <LogisticsWrapper /> },
  { path: "/pr-admin/users/user-details/:userId", element: <UserDetailsWrapper /> },
  { path: "/pr-admin/users/update-suspension/:userId", element: <UserDetailsWrapper /> },
  { path: "/pr-admin/agents/agent-details/:agentId", element: <AgentDetailsWrapper /> },
  { path: "/pr-admin/agents/update-suspension/:agentId", element: <AgentDetailsWrapper /> },
  { path: "/pr-admin/agents/agent-details/:agentId/declined-orders", element: <DeclinedOrders /> },
  { path: "/pr-admin/restaurants/restaurant-details/:restaurantId", element: <RestaurantWrapper /> },
  { path: "/pr-admin/restaurants/update-suspension/:restaurantId", element: <RestaurantWrapper /> },
  { path: "/pr-admin/delivery-location", element: <DeliveryLocation locationId="someLocationId" /> },
  { path: "/pr-admin/assign-rider", element: <AssignRider /> },  // New route for Assign Rider page
];

export { routes };