import React from 'react';
import { AppLayout } from '../../components/layouts';
import Statistics from './components/Statistics';
import Welcome from './components/Welcome';
import Rightnav from './components/Rightnav';
import { FaWhatsapp } from 'react-icons/fa';

// WhatsApp group link (replace with your group's link)
const whatsappLink = 'https://chat.whatsapp.com/your-group-link-here';

function Dashboard() {
  return (
    <AppLayout>
 <div className="grid md:grid-cols-3">
 <div className="col-span-1">

      </div>
      <div className="col-span-2 font-['Outfit'] text-4xl font-medium leading-[50px] text-amber-500 tracking-normal">Group Chat<a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className="fixed top-5 right-5 z-50 items-center justify-center bg-amber-500 text-white p-4 rounded-full shadow-lg hover:bg-amber-600 transition-all"
      >
        <FaWhatsapp size={24} />
      </a></div>

      <div className="col-span-3">
      <Welcome />
      </div>
      </div>

      {/* Grid layout */}
      <div className="w-full mt-10">
        <div className="grid md:grid-cols-6">
      
          {/* Left Column */}
          <div className="col-span-4">

            <Statistics />
          </div>
          
          {/* Right Column */}
          <div className="col-span-2 relative">
            <Rightnav />
          </div>
          {/* Floating WhatsApp Button */}
     
        </div>
        
      </div>
    </AppLayout>
  );
}

export default Dashboard;
