import React, { useState } from 'react';
import AdminService from 'src/services/admin.services'; // Adjust import path as needed

const CreateAdmin: React.FC = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone: '',
    superadmin_email: '',
    superadmin_password: '',
  });

  const [modalMessages, setModalMessages] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);

  // Define superadmin credentials
  const SUPERADMIN_EMAIL = 'peakd@gmail.com';
  const SUPERADMIN_PASSWORD = 'peakdelivery';

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationMessages: string[] = [];

    // Basic frontend validation
    if (!/^[a-zA-Z]+$/.test(formData.last_name)) {
      validationMessages.push("Last name must contain only letters.");
    }

    if (formData.password.length < 8) {
      validationMessages.push("Password must be at least 8 characters long.");
    }

    // Validate superadmin credentials
    if (formData.superadmin_email !== SUPERADMIN_EMAIL) {
      validationMessages.push("Invalid superadmin email.");
    }

    if (formData.superadmin_password !== SUPERADMIN_PASSWORD) {
      validationMessages.push("Invalid superadmin password.");
    }

    // If there are any validation messages, display them and stop form submission
    if (validationMessages.length > 0) {
      setModalMessages(validationMessages);
      setShowModal(true);
      return;
    }

    // Convert all input fields to lowercase before submission
    const lowerCaseFormData = {
      first_name: formData.first_name.toLowerCase(),
      last_name: formData.last_name.toLowerCase(),
      email: formData.email.toLowerCase(),
      password: formData.password.toLowerCase(),
      phone: formData.phone.toLowerCase(),
    };

    try {
      const adminService = new AdminService();
      const response = await adminService.createAdmin(lowerCaseFormData);

      if (response && response.message === "admin created successfully") {
        // Set the success message in the modal
        setModalMessages(["Admin created successfully!"]);
      } else if (response && response.error) {
        // Display specific error messages returned by the server
        const errors = response.error instanceof Array ? response.error : [response.error];
        setModalMessages(errors);
      } else {
        setModalMessages(["Admin created successfully!"]);
      }
    } catch (error) {
      console.error('Error creating admin:', error);
      setModalMessages(["Admin created successfully!"]);
    } finally {
      setShowModal(true);
    }
  };

  const inputStyle = "w-full px-[20px] py-[12px] border border-black text-[14px] rounded-[8px]";

  return (
    <div className="h-[100vh] w-full bg-white flex flex-col items-center justify-center p-6">
      <h2 className="text-center text-2xl font-bold mb-6">Create Admin</h2>
      <form onSubmit={handleSubmit} className="w-full max-w-md px-5 py-6 bg-gray-100 rounded-lg shadow-md">
        <input
          placeholder="First Name"
          type="text"
          className={inputStyle}
          value={formData.first_name}
          onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
        />
        <input
          placeholder="Last Name"
          type="text"
          className={inputStyle}
          value={formData.last_name}
          onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
        />
        <input
          placeholder="Email"
          type="email"
          className={inputStyle}
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        <input
          placeholder="Password"
          type="password"
          className={inputStyle}
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
        />
        <input
          placeholder="Phone"
          type="text"
          className={inputStyle}
          value={formData.phone}
          onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
        />
        <h2>superadmin credentials:</h2>
        <input
          placeholder="Superadmin Email"
          type="email"
          className={inputStyle}
          value={formData.superadmin_email}
          onChange={(e) => setFormData({ ...formData, superadmin_email: e.target.value })}
        />
        <input
          placeholder="Superadmin Password"
          type="password"
          className={inputStyle}
          value={formData.superadmin_password}
          onChange={(e) => setFormData({ ...formData, superadmin_password: e.target.value })}
        />
        <button type="submit" className="w-full text-black font-normal bg-pr-primary rounded-[8px] px-[20px] py-[12px] text-[18px] mt-[3rem]">Create Admin</button>
      </form>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-green-500 text-white p-4 rounded-lg shadow-lg max-w-md w-full">
            {modalMessages.map((message, index) => (
              <p key={index} className="mb-2">{message}</p>
            ))}
            <button className="mt-2 bg-white text-black px-4 py-2 rounded" onClick={() => setShowModal(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateAdmin;
