import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { request } from '../../../hooks/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

interface OrderItem {
  // Define the structure for OrderItem as needed
  _id: string;
  order_code: string;
  restaurant_name: string;
  subtotal: number;
  total: number;
  date_declined: string;
  order_status: string;
}

const DeclinedOrders: React.FC = () => {
  const { agentId } = useParams<{ agentId: string }>();
  const [declinedOrders, setDeclinedOrders] = useState<OrderItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDeclinedOrders = async () => {
      try {
        const response = await request(`/shopping/order/declined?agentId=${agentId}`, 'GET', {}, true, false, false);
        setDeclinedOrders(response);
      } catch (err) {
        setError('Failed to fetch declined orders');
      } finally {
        setLoading(false);
      }
    };

    fetchDeclinedOrders();
  }, [agentId]);

  const handleDownload = () => {
    const csvRows = [
      ['Order ID', 'Order Code', 'Restaurant', 'Subtotal', 'Total', 'Date Declined', 'Order Status'],
      ...declinedOrders.map(order => [
        order._id,
        order.order_code,
        order.restaurant_name,
        order.subtotal,
        order.total,
        new Date(order.date_declined).toLocaleDateString(),
        order.order_status
      ])
    ];

    const csvContent = 'data:text/csv;charset=utf-8,' + csvRows.map(e => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'declined_orders.csv');
    document.body.appendChild(link);
    link.click();
  };

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-bold">Declined Orders</h2>
        <button onClick={handleDownload} className="flex items-center">
          <FontAwesomeIcon icon={faDownload} className="mr-2" />
          Download
        </button>
      </div>
      {declinedOrders.length ? (
        <table className="min-w-full border text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          <thead>
            <tr>
              <th className="px-5 py-3">Order ID</th>
              <th className="px-5 py-3">Order Code</th>
              <th className="px-5 py-3">Restaurant</th>
              <th className="px-5 py-3">Subtotal</th>
              <th className="px-5 py-3">Total</th>
              <th className="px-5 py-3">Date Declined</th>
              <th className="px-5 py-3">Order Status</th>
            </tr>
          </thead>
          <tbody>
            {declinedOrders.map(order => (
              <tr key={order._id} className="hover:bg-gray-100">
                <td className="px-5 py-3">{order._id}</td>
                <td className="px-5 py-3">{order.order_code}</td>
                <td className="px-5 py-3">{order.restaurant_name}</td>
                <td className="px-5 py-3">{order.subtotal}</td>
                <td className="px-5 py-3">{order.total}</td>
                <td className="px-5 py-3">{new Date(order.date_declined).toLocaleDateString()}</td>
                <td className="px-5 py-3">{order.order_status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>No declined orders found.</div>
      )}
    </div>
  );
};

export default DeclinedOrders;
