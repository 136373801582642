import React from 'react';
import { Transaction } from '../../../services/types';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, ArcElement, PointElement, LineElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, ArcElement, PointElement, LineElement);

interface TransactionAnalysisProps {
  transactions: Transaction[];
}

const TransactionAnalysis: React.FC<TransactionAnalysisProps> = ({ transactions }) => {
  const totalTransactions = transactions.length;
  const totalAmount = transactions.reduce((sum, transaction) => sum + transaction.amount, 0);

  // Transaction Status Count
  const transactionStatusCount = transactions.reduce((statusCount, transaction) => {
    statusCount[transaction.status] = (statusCount[transaction.status] || 0) + 1;
    return statusCount;
  }, {} as Record<string, number>);

  // Amount Distribution Against Date
  const amountDistributionData = transactions.reduce((data, transaction) => {
    const date = new Date(transaction.created_at).toLocaleDateString();
    if (!data[date]) {
      data[date] = 0;
    }
    data[date] += transaction.amount;
    return data;
  }, {} as Record<string, number>);

  const lineChartData = {
    labels: Object.keys(amountDistributionData),
    datasets: [{
      label: 'Amount Distribution',
      data: Object.values(amountDistributionData),
      borderColor: '#36A2EB',
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderWidth: 2,
    }]
  };

  // Success vs Failed Transactions
  const successFailedCount = transactions.reduce((count, transaction) => {
    if (transaction.status === 'success') {
      count.success += 1;
    } else if (transaction.status === 'failed') {
      count.failed += 1;
    }
    return count;
  }, { success: 0, failed: 0 });

  const barChartData = {
    labels: ['Success', 'Failed'],
    datasets: [{
      label: 'Transaction Status',
      data: [successFailedCount.success, successFailedCount.failed],
      backgroundColor: ['#36A2EB', '#FF6384'],
      borderColor: ['#36A2EB', '#FF6384'],
      borderWidth: 1,
    }]
  };

  return (
    <div className="p-4 mt-4 bg-gray-100 rounded-md shadow-md">
      <h2 className="text-lg font-semibold mb-4">Transaction Analysis</h2>
      <div className="flex flex-col md:flex-row md:space-x-4">
        <div className="flex-1 bg-amber-500 p-4 rounded-md border border-yellow-600 mb-4 md:mb-0">
          <h3 className="text-md font-medium mb-2">Total Transactions</h3>
          <p className="text-lg font-bold">{totalTransactions}</p>
        </div>
        <div className="flex-1 bg-amber-500 p-4 rounded-md border border-yellow-600 mb-4 md:mb-0">
          <h3 className="text-md font-medium mb-2">Total Amount</h3>
          <p className="text-lg font-bold">₦{totalAmount.toFixed(2)}</p>
        </div>
      </div>
      <div className="mt-4 flex flex-col md:flex-row md:space-x-4">
        <div className="flex-1 bg-amber-500 p-4 rounded-md border border-yellow-600 mb-4 md:mb-0">
          <h3 className="text-md font-medium mb-2">Status Breakdown</h3>
          <Pie data={lineChartData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
        </div>
        <div className="flex-1 bg-amber-500 p-4 rounded-md border border-yellow-600 mb-4 md:mb-0">
          <h3 className="text-md font-medium mb-2">Amount Distribution</h3>
          <Line data={lineChartData} options={{ responsive: true, plugins: { legend: { display: false } } }} />
        </div>
        <div className="flex-1 bg-amber-500 p-4 rounded-md border border-yellow-600">
          <h3 className="text-md font-medium mb-2">Success vs Failed Transactions</h3>
          <Bar data={barChartData} options={{ responsive: true, plugins: { legend: { display: false } } }} />
        </div>
      </div>
    </div>
  );
};

export default TransactionAnalysis;
