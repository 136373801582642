import React, { useEffect, useState } from 'react';
import { AppLayout } from 'src/components/layouts';
import AgentTable from './components/AgentTable';
import AdminService from 'src/services/admin.services';
import { Agent } from '../../services/types';
import SearchInput from '../../components/ui/SearchInput';
import Statistics from './components/Statistics';
import WalletTab from './components/WalletTab'; // Import the WalletTab component

const adminService = new AdminService();
const ITEMS_PER_PAGE = 10;

const AgentManagement: React.FC = () => {
  const [data, setData] = useState<Agent[]>([]);
  const [, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [filteredAgents, setFilteredAgents] = useState<Agent[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);

  // New states for tab and selected agent ID
  const [tab, setTab] = useState<number>(0);
  const [selectedAgentId] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Set loading state when fetching data
        const agentsData = await adminService.getAgents(currentPage + 1); // Pass currentPage (1-indexed)
        setData(agentsData); // Update the data state
        setFilteredAgents(agentsData); // Reset filteredAgents for pagination
      } catch (error) {
        console.error('Error fetching agents:', error);
        setError('Error fetching agents.');
      } finally {
        setIsLoading(false); // Set loading to false after fetching
      }
    };
  
    fetchData();
  }, [currentPage]); // Re-run when currentPage changes
  
  

  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      setFilteredAgents(
        data.filter((agent) =>
          agent.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          agent.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          agent.email.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredAgents(data);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage); // Update the current page
    }
  };
  

  const totalPages = Math.ceil(filteredAgents.length / ITEMS_PER_PAGE);

  // Calculate statistics
  const totalAgents = data.length;
  const activeAgents = data.filter(agent => agent.is_active).length;
  const inactiveAgents = totalAgents - activeAgents;
  const totalDeliveries = data.reduce((sum, agent) => sum + agent.total_deliveries, 0);
  const totalDeliveryDistance = data.reduce((sum, agent) => sum + agent.total_delivery_distance, 0);
  const averageRating = totalAgents > 0 ? (data.reduce((sum, agent) => sum + agent.rating, 0) / totalAgents) : 0;
  const averageDeliveriesPerMonth = data.length > 0 ? (totalDeliveries / data.length).toFixed(1) : "0";
  const averageDeliveryTime = data.length > 0 ? (data.reduce((sum, agent) => sum + agent.average_delivery_time, 0) / data.length).toFixed(1) : "0";
  const totalSuspensions = data.reduce((sum, agent) => sum + (agent.is_suspended ? 1 : 0), 0);

  return (
    <AppLayout>
      <div className="flex flex-col gap-4">
        <h2 className="text-xl font-semibold mb-4">Agent Management</h2>
        <div className="bg-white p-6 rounded-lg shadow">
          <SearchInput search={handleSearch} />
          <br />
          <AgentTable
  data={filteredAgents}
  currentPage={currentPage}
  totalPages={totalPages}
  onPageChange={handlePageChange}
  itemsPerPage={ITEMS_PER_PAGE}
/>

        </div>

        <br />
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Statistics</h2>
          <Statistics
            totalAgents={totalAgents}
            activeAgents={activeAgents}
            inactiveAgents={inactiveAgents}
            totalDeliveries={totalDeliveries}
            totalDeliveryDistance={totalDeliveryDistance}
            averageRating={averageRating}
            averageDeliveriesPerMonth={averageDeliveriesPerMonth}
            averageDeliveryTime={averageDeliveryTime}
            totalSuspensions={totalSuspensions}
          />
        </div>

        
      </div>
    </AppLayout>
  );
};

export default AgentManagement;
