import { API_BASE_URL, isTokenExpired, logout, saveToken, token } from "./request";

export const request = async (
  url: string, 
  method: string, 
  payload: Record<string, any> | undefined, 
  includeToken: boolean, 
  text: boolean, 
  form: boolean
) => {
  if (includeToken && token && isTokenExpired(token)) {
    try {
      // Try refreshing the token before logging out
      await refreshToken();
    } catch (err) {
      await logout();
      throw new Error("Token expired. Redirecting to login page.");
    }
  }

  const requestHeader: Record<string, string> = {
    Accept: "application/json",
    "Cache-Control": "no-cache",
    "Content-Type": form ? "multipart/form-data" : "application/json",
  };

  if (includeToken && token) {
    requestHeader["Authorization"] = `Bearer ${token}`;
  }

  let body: BodyInit | null | undefined;

  if (method !== 'GET' && payload) {
    if (form) {
      const formData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (value !== undefined) {
          formData.append(key, value);
        }
      });
      body = formData;
    } else {
      body = JSON.stringify(payload);
    }
  }

  const requestOptions: RequestInit = {
    method,
    headers: requestHeader,
    body,
  };

  try {
    const response = await fetch(API_BASE_URL + url, requestOptions);

    if (response.status === 403 || response.status === 401) {
      await logout();
      throw new Error("Access forbidden. Redirecting to login page.");
    }

    if (response.status >= 400) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Request failed");
    }

    if (text) {
      return response.text();
    } else {
      return response.json();
    }
  } catch (error) {
    console.error(`Request Error ${url}: `, error);
    throw error;
  }
};

// Token refresh function
export const refreshToken = async (): Promise<void> => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) throw new Error("No refresh token available.");

  try {
    const response = await fetch(`${API_BASE_URL}/admins/refresh-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken }),
    });

    if (response.ok) {
      const data = await response.json();
      saveToken(data.accessToken); // Save new token
    } else {
      throw new Error("Failed to refresh token");
    }
  } catch (error) {
    console.error("Token Refresh Error:", error);
    throw error;
  }
};
