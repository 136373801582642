import React from "react";
import NotificationWrapper from "./NotificationWrapper";
import AssignRider from "./AssignRider";
import BackButton from "src/components/BackButton";

interface TabComponentProps {
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const TabComponent: React.FC<TabComponentProps> = ({ activeTab, setActiveTab }) => {
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="tabs-container">
      {/* Tab Headers */}
      <div className="tabs-header flex space-x-4 border-b-2 mb-4">
      <BackButton />
        <button
          className={`px-4 py-2 ${activeTab === 0 ? "border-b-2 border-blue-500 font-semibold" : ""}`}
          onClick={() => handleTabClick(0)}
        >
          Orders
        </button>
        <button
          className={`px-4 py-2 ${activeTab === 1 ? "border-b-2 border-blue-500 font-semibold" : ""}`}
          onClick={() => handleTabClick(1)}
        >
           Assign Rider
        </button>
      
      </div>

      {/* Tab Content */}
      <div className="tabs-content">
      {activeTab === 0 && (
          <div>
          
            {/* Placeholder for Track Order content */}
            <p>
          . 
            </p>
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <h3 className="text-lg font-semibold mb-4">Assign Rider</h3>
            {/* Placeholder for Assign Rider content */}
            <p>
            <AssignRider />
            </p>
          </div>
        )}
        
      </div>
    </div>
  );
};

export default TabComponent;
