// src/pages/components/AdminTable.tsx
import React from 'react';

interface Admin {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  created_at?: string;
  updated_at?: string;
}

interface AdminTableProps {
  data: Admin[];
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  itemsPerPage: number;
}

const AdminTable: React.FC<AdminTableProps> = ({
  data,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage
}) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = data.slice(startIndex, endIndex);

  console.log('Paginated Admin Data:', paginatedData);

  return (
    <div>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
      
          <th className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">id</th>
            <th className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">First Name</th>
            <th className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">Last Name</th>
            <th className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">Email</th>
            <th className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">Phone</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.length > 0 ? (
            paginatedData.map((admin) => (
              <tr key={admin._id}>
                 <td className="px-5 py-3 border-b text-left">{admin._id}</td>
                <td className="px-5 py-3 border-b text-left">{admin.first_name}</td>
                <td className="px-5 py-3 border-b text-left">{admin.last_name}</td>
                <td className="px-5 py-3 border-b text-left">{admin.email}</td>
                <td className="px-5 py-3 border-b text-left">{admin.phone}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className="text-center py-4">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex justify-between items-center py-4">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="h-11 px-5 rounded-xl border border-amber-500 text-black text-base font-normal"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="h-11 px-5 rounded-xl border border-amber-500 text-black text-base font-normal"
        >
          Next
        </button>
      </div>
    </div>
  );
};


export default AdminTable;
