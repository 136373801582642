import { ReactNode } from "react";
import LeftPane from "./LeftPane";

interface ChildrenProps {
    children?: ReactNode;
}

function AppLayout({ children }: ChildrenProps){
    return (
        <div className="w-full h-[100vh] grid grid-cols-10">
            <aside className="h-full col-span-2 bg-white border-r-[1px] border-r-pr-primaryLighter">
                <LeftPane />
            </aside>
            <main className="col-span-8 h-[100vh] overflow-y-auto px-[2.5rem] py-[3rem] bg-white">{ children } </main>
        </div>
    )
}

export default AppLayout