import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import BackButton from 'src/components/BackButton';

interface Address {
  type: string;
  address: string;
  latitude: number;
  longitude: number;
  place_id: string;
}

interface LocationData {
  _id: string;
  address: Address;
  is_default: boolean;
}

const mapContainerStyle = {
  height: '400px',
  width: '100%',
};

interface DeliveryLocationProps {
  locationId: string;
}

// Helper to determine state based on address or coordinates
const getStateFromAddress = (address: string): string => {
  const states = [
    'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue', 'Borno',
    'Cross River', 'Delta', 'Ebonyi', 'Edo', 'Ekiti', 'Enugu', 'Gombe', 'Imo', 'Jigawa',
    'Kaduna', 'Kano', 'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Lagos', 'Nasarawa', 'Niger',
    'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau', 'Rivers', 'Sokoto', 'Taraba', 'Yobe',
    'Zamfara', 'FCT'
  ];

  for (const state of states) {
    if (address.includes(state)) {
      return state;
    }
  }

  return 'Unknown State';
};

const DeliveryLocation: React.FC<DeliveryLocationProps> = ({ locationId }) => {
  const [locationData, setLocationData] = useState<LocationData | null>(null);
  const [error, setError] = useState<string>('');
  const [stateName, setStateName] = useState<string>('Unknown');

  useEffect(() => {
    const getLocation = async () => {
      try {
        const response = await fetch(`https://api.peakreachdelivery.com/api/v1/locations/delivery-address/${locationId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          const data: LocationData = await response.json();
          console.log('Fetched Location Data:', data);
          setLocationData(data);
          setError('');

          // Extract state from the address
          const state = getStateFromAddress(data.address.address);
          setStateName(state);
        } else {
          const text = await response.text();
          console.log('Response text:', text);
          throw new Error('Received non-JSON response');
        }
      } catch (err) {
        console.error('Error fetching location:', err);
        setError('Failed to fetch location data.');
        setLocationData(null);
        setStateName('Unknown');
      }
    };

    if (locationId) {
      getLocation();
    }
  }, [locationId]);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100 min-h-screen">
      <BackButton />
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Delivery Location Information</h2>

      {error && <p className="text-red-600 mb-4">{error}</p>}
      {locationData ? (
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Location Details</h3>
          <p><strong className="font-semibold">ID:</strong> {locationData._id}</p>
          <p><strong className="font-semibold">Address:</strong> {locationData.address.address}</p>
          <p><strong className="font-semibold">Latitude:</strong> {locationData.address.latitude}</p>
          <p><strong className="font-semibold">Longitude:</strong> {locationData.address.longitude}</p>
          <p><strong className="font-semibold">Place ID:</strong> {locationData.address.place_id}</p>
          <p><strong className="font-semibold">Default:</strong> {locationData.is_default ? 'Yes' : 'No'}</p>
          <p><strong className="font-semibold">State:</strong> {stateName}</p>

          {/* Google Maps Integration */}
          <div className="mb-6">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Location on Map</h3>
            <LoadScript googleMapsApiKey="AIzaSyD845dpQ62RHqNW83JcyA5YKaRQ05UVl8I">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={{
                  lat: locationData.address.latitude,
                  lng: locationData.address.longitude,
                }}
                zoom={15}
                options={{
                  disableDefaultUI: true,
                  styles: [
                    {
                      featureType: 'all',
                      elementType: 'labels.text.fill',
                      stylers: [{ color: '#000000' }],
                    },
                    {
                      featureType: 'poi',
                      elementType: 'labels',
                      stylers: [{ visibility: 'off' }],
                    },
                  ],
                }}
              >
                <Marker
                  position={{
                    lat: locationData.address.latitude,
                    lng: locationData.address.longitude,
                  }}
                  icon="\src\assets\images\location.png"
                />
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      ) : (
        <p className="mt-6 text-gray-600">Location is loading..., Please wait..</p>
      )}
    </div>
  );
};

export default DeliveryLocation;
