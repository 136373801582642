import React from 'react';
import BackButton from 'src/components/BackButton';

interface TabProps {
  setTab: (index: number) => void;
  tab: number;
  hasOrders: boolean; // Add this prop
}

const AgentTab: React.FC<TabProps> = ({ setTab, tab, hasOrders }) => {
  return (
    <div className="flex space-x-4 border-b-2 border-gray-200">
        <BackButton />
      
      <button
        onClick={() => setTab(0)}
        className={`py-2 px-4 ${tab === 0 ? 'border-b-4 border-pr-primary text-pr-primary' : 'text-gray-500'}`}
      >
        Agent Details
      </button>
      <button
        onClick={() => setTab(1)}
        className={`py-2 px-4 ${tab === 1 ? 'border-b-4 border-pr-primary text-pr-primary' : 'text-gray-500'}`}
      >
        Order History
      </button>
      

      <button
        onClick={() => setTab(2)}
        className={`py-2 px-4 ${tab === 2 ? 'border-b-4 border-pr-primary text-pr-primary' : 'text-gray-500'}`}
      >
        Wallet History
      </button>
      
    </div>
  );
};

export default AgentTab;
