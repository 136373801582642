import React, { useEffect, useState } from 'react';
import { request } from 'src/hooks/api';
import wallet from '../../../assets/images/wallet.png';

interface BankDetailsProps {
  restaurantId: string;
}

interface BankDetailsData {
  bank: string;
  account_number: string;
  account_name: string;
}

const BankDetailsTab: React.FC<BankDetailsProps> = ({ restaurantId }) => {
  const [bankDetails, setBankDetails] = useState<BankDetailsData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRestaurantBankDetails = async () => {
      setLoading(true);
      try {
        console.log('Fetching bank details for restaurantId:', restaurantId);
        const response = await request(
          `/admins/restaurants/account-details/${restaurantId}`,
          "GET",
          {},
          true,
          false,
          false
        );

        console.log('API response:', response);

        if (response && response.data) {
          setBankDetails(response.data); // Update bank details state
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (error) {
        console.error('Error fetching bank details:', error);
        setError('Error fetching bank information');
      } finally {
        setLoading(false);
      }
    };

    if (restaurantId) {
      fetchRestaurantBankDetails();
    } else {
      console.warn('restaurantId is undefined');
      setError('Invalid restaurantId');
      setLoading(false);
    }
  }, [restaurantId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!bankDetails) {
    return <div>No bank details available</div>;
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
        <div className="flex items-center mb-4">
          <img src={wallet} alt="Bank Icon" className="w-20 h-20 mr-2" />
          <h3 className="text-2xl font-semibold">Bank Information</h3>
        </div>
        
        <p className="text-lg">
          <strong>Bank:</strong> {bankDetails.bank}
        </p>
        <p className="text-lg">
          <strong>Account Number:</strong> {bankDetails.account_number}
        </p>
        <p className="text-lg">
          <strong>Account Name:</strong> {bankDetails.account_name}
        </p>
      </div>
    </div>
  );
};

export default BankDetailsTab;
