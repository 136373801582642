import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import AdminService from 'src/services/admin.services';
import { Order } from 'src/services/types';
import TabComponent from './TabComponent ';

import removeRedEye from '../../assets/icons/removeRedEye.svg';
import html2canvas from 'html2canvas';

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import DeliveryLocation from '../deliverylocation/DeliveryLocation';
import { Link } from 'react-router-dom';


//import { LocationData } from 'src/services/types';
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const adminService = new AdminService();

// Declare locally or in a shared file
type OrderStatus = 
  | "delivered" 
  | "pending" 
  | "cancelled" 
  | "confirmed" 
  | "declined" 
  | "all" 
  | "location";

type OrderFilter = "all" | "location" | OrderStatus;


// Define LocationData type
type LocationData = {
  latitude: number;
  longitude: number;
};



const NotificationWrapper: React.FC = () => {
 // State declarations
 const [orders, setOrders] = useState<Order[]>([]);
 const [currentPage] = useState<number>(1);
 const [filteredData, setFilteredData] = useState<Order[]>([]);
 const [totalPages] = useState(10);  // Track total pages for pagination
 const [pageNumber, setPageNumber] = useState(1); // Tracks current page number
 const [pageSize, setPageSize] = useState(40); // Tracks page size
 const [loading, setLoading] = useState<boolean>(true);
 const [searchTerm, setSearchTerm] = useState<string>('');
 const [successMessage] = useState<string | null>(null);
 const [error, setError] = useState<string | null>(null);
 const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
 const [filter] = useState<string>('day');
 const [isUpdating, setIsUpdating] = useState<boolean>(false);
 const [filterStatus, setFilterStatus] = useState<OrderStatus>('all');
 const [locationData, setLocationData] = useState<LocationData | null>(null);
 const [activeTab, setActiveTab] = useState<number>(0);const [currentFilter, setCurrentFilter] = useState<OrderFilter>('all');




// Filter orders by coordinates
  const filterByCoordinates = (
    orders: Order[],
    longitude?: number,
    latitude?: number,
  maxDistance: number = 100000000
  ): Order[] => {
    if (!longitude || !latitude) return orders;

    return orders.filter((order) => {
      const { longitude: orderLongitude, latitude: orderLatitude } = order;
      if (orderLongitude && orderLatitude) {
        const distance = getDistanceFromLatLonInMeters(latitude, longitude, orderLatitude, orderLongitude);
        return distance <= maxDistance;
      }
      return false;
    });
  };

// Calculate distance between two coordinates
  const getDistanceFromLatLonInMeters = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const R = 6371e3;
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a = Math.sin(Δφ / 2) ** 2 +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

// Apply filters to orders
  const applyFilters = () => {
  let filtered = [...orders];

    if (filterStatus !== 'all' && filterStatus !== 'location') {
    filtered = filtered.filter((order) => order.order_status?.toLowerCase() === filterStatus.toLowerCase());
    }

    if (filterStatus === 'location' && locationData) {
      filtered = filterByCoordinates(filtered, locationData.longitude, locationData.latitude);
    }

    setFilteredData(filtered);
  };

useEffect(() => {
  if (filterStatus === 'location' && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocationData({ latitude, longitude });
      },
      (error) => console.error('Error fetching geolocation:', error),
      { enableHighAccuracy: true }
    );
  }
}, [filterStatus]);

useEffect(() => {
  applyFilters();
}, [filterStatus, orders, locationData]);


const fetchOrders = async (page: number, filter: OrderFilter) => {
  setLoading(true);
  try {
    const fetchedOrders = await adminService.getOrders(
      page,
      pageSize,
      undefined,
      filter === 'location' || filter === 'all' ? undefined : filter,
      filter === 'location' ? locationData?.longitude : undefined,
      filter === 'location' ? locationData?.latitude : undefined
    );

    if (Array.isArray(fetchedOrders)) {
      // Append new orders for additional pages
      setOrders((prevOrders) => (page === 1 ? fetchedOrders : [...prevOrders, ...fetchedOrders]));
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (err) {
    console.error('Error fetching orders:', err);
    setError('Failed to fetch orders. Please try again.');
  } finally {
    setLoading(false);
  }
};



useEffect(() => {
  fetchOrders(pageNumber, currentFilter);
}, [pageNumber, currentFilter]);

const paginatedOrders = filteredData.slice(
  (pageNumber - 1) * pageSize,
  pageNumber * pageSize
);



const handlePageChange = (page: number) => {
  if (page >= 1 && page <= totalPages) {
    setPageNumber(page);
    // Trigger fetching new orders for the next page
    fetchOrders(page, currentFilter);
  }
};



// Handle page size change
const handlePageSizeChange = (size: number) => {
  setPageSize(size);
  setPageNumber(1); // Reset to page 1 when changing page size

  // Update the URL with the new page size
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set("size", size.toString());
  currentUrl.searchParams.set("page", "1");
  window.history.pushState({}, "", currentUrl.toString());
};

// Handle filter change
const handleFilterChange = (filter: OrderFilter) => {
  setCurrentFilter(filter);
  setPageNumber(1); // Reset to page 1 when the filter is changed

  // Update the URL with the new filter
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set("filter", filter);
  currentUrl.searchParams.set("page", "1");
  window.history.pushState({}, "", currentUrl.toString());
};

 // Fetch initial data
  useEffect(() => {
  fetchOrders(pageNumber, currentFilter);
}, [pageNumber, pageSize, currentFilter, locationData]);





  // Search input handler
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };
  
  
  const openModal = (order: Order) => {
    setSelectedOrder(order);
  };

  const closeModal = () => {
    setSelectedOrder(null);
  };

    // Handle search and apply combined filtering
    useEffect(() => {
      const filteredOrders = orders.filter((order) => {
        const matchesSearchTerm =
          order._id.includes(searchTerm) ||
          order.order_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.restaurant_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          new Date(order.date_delivered).toLocaleDateString().includes(searchTerm) ||
          new Date(order.created_at).toLocaleDateString().includes(searchTerm) ||
          order.agent?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.order_status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.user?.toLowerCase().includes(searchTerm.toLowerCase());
    
        return matchesSearchTerm;
      });
    
      setFilteredData(filteredOrders);
    }, [orders, searchTerm]);

  // Function to prepare data for download (as CSV)
  const prepareDownloadData = (orders: Order[]) => {
    const csvRows = [
      ['Order Code', 'Restaurant Name', 'Date Delivered', 'Order Status'], // Header row
      ...orders.map(order => [
        order._id,
        order.order_code,
        order.restaurant_name,
        order.subtotal,
        order.total,
        order.delivery_fee,
        order.tax,
       
        order.items.map((item: { meal: { name: any; }; qty: any; }) => `${item.meal.name} (${item.qty})`).join(', '),
        order.user,
        order.order_status,
        order.payment_method,
        new Date(order.date_delivered).toLocaleDateString(),
        new Date(order.pickup_time).toLocaleDateString(),
        new Date(order.enroute_time).toLocaleDateString(),
        new Date(order.created_at).toLocaleDateString(),
   
        order.agent,
        order.agent_delivered_order_upload,
        order.delivery_location,
       
        order.discount.order,
        order.discount.delivery,
        order.voucher,
        
        order.note,
        order.user_received_order_upload
      ])
    ];
  
    const csvContent = csvRows.map(row => row.join(',')).join('\n');
  
    // Create a download link for CSV
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `orders_${filter}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  



  const downloadInvoice = () => {
    const captureElement = document.getElementById('order-details');

    if (captureElement && selectedOrder) {
      html2canvas(captureElement, {
        useCORS: true,
        scale: 2,
        logging: true,
      })
        .then(canvas => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = `Order_${selectedOrder._id}.png`;
          link.click();
        })
        .catch(error => {
          console.error('Error capturing the element:', error);
        });
    }
  };



  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;


// Admin service function to update orders
const updateOrderStatus = async (orderId: string, status: string) => {
  setIsUpdating(true);
  try {
    const response = await fetch(`/admins/orders/update/${orderId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ order_status: status }),
    });

    if (response.ok) {
      alert(`Order status updated to ${status}`);
    } else {
      throw new Error("Failed to update order status");
    }
  } catch (error) {
    console.error("Error updating order:", error);
    alert("Failed to update order status. Please try again.");
  } finally {
    setIsUpdating(false);
  }
};

// Action handlers
const handleCancelOrder = (order: Order): void => {
  updateOrderStatus(order._id, "cancelled");
};

const handleAcceptOrder = (order: Order): void => {
  updateOrderStatus(order._id, "confirmed");
};

const handleDeclineOrder = (order: Order): void => {
  updateOrderStatus(order._id, "declined");
};

  
  return (
    <><>
      
    </><div className="max-w-4xl mx-auto p-6">
  
    <TabComponent activeTab={activeTab} setActiveTab={setActiveTab} />
      

        <div className="flex items-center gap-4 mb-6">
           {/* Search input */}
      <input
        type="text"
        placeholder="Search orders..."
        value={searchTerm}
        onChange={handleSearchChange}
      />


      {/* Filter dropdown */}
      <select
        value={filterStatus}
        onChange={(e) => setFilterStatus(e.target.value as OrderStatus)} // Cast value to OrderStatus
        className="border border-gray-300 rounded-lg px-4 py-2"
      >
        <option value="all">All Status</option>
        <option value="delivered">Delivered</option>
        <option value="pending">Pending</option>
        <option value="confirmed">Confirmed</option>
        <option value="declined">Declined</option>
        <option value="cancelled">Cancelled</option>
        <option value="location">Location</option> {/* Location filter */}
      </select>
      

          <button
            className="border bg-amber-500 border-gray-300 rounded-lg px-4 py-2"
            onClick={() => prepareDownloadData(filteredData)}
          >
            Download CSV
          </button>
        </div>

        {successMessage && (
          <div className="mb-4 p-2 bg-green-200 text-green-800 rounded">
            {successMessage}
          </div>
        )}

        <div className="overflow-x-auto">
        <table className="min-w-full px-5 py-3 bg-amber-200 bg-opacity-25 rounded-tl-xl rounded-tr-xl border border-b-0 text-left text-xs font-semibold text-black uppercase tracking-wider">
  <thead>
    <tr>
      <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
        S/N
      </th>
      <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
        Order Code
      </th>
      <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
        Restaurant
      </th>
      <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
        Date
      </th>
      <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
        Status
      </th>
      <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
        Action
      </th>
    </tr>
  </thead>
  <tbody>
  {paginatedOrders.map((order: Order, index: number) => (
      <tr key={order._id} className="bg-amber-200">
        {/* S/N Column */}
        <td className="px-5 py-3">
          {(currentPage - 1) * pageSize + index + 1}
        </td>
        <td className="px-5 py-3">{order.order_code}</td>
        <td className="px-5 py-3">{order.restaurant_name}</td>
        <td className="px-5 py-3">
          {order.created_at
            ? `${new Date(order.created_at).toLocaleDateString()} ${new Date(order.created_at).toLocaleTimeString()}`
            : 'N/A'}
        </td>
        <td className="px-5 py-3">
  {order.order_status}
  {order.order_status === "pending" && (
    <>
      <button
        onClick={() => handleAcceptOrder(order)}
        className="text-white bg-green-500 hover:bg-green-600 px-2 py-1 rounded"
        disabled={isUpdating}
      >
        Accept
      </button>
      <button
        onClick={() => handleDeclineOrder(order)}
        className="text-white bg-orange-500 hover:bg-orange-600 px-2 py-1 rounded"
        disabled={isUpdating}
      >
        Decline
      </button>
      <button
        onClick={() => handleCancelOrder(order)}
        className="text-white bg-red-500 hover:bg-red-600 px-2 py-1 rounded"
        disabled={isUpdating}
      >
        Cancel
      </button>
    </>
  )}
</td>
<td className="px-5 py-3">
  <button
    className="text-amber-500 px-2 py-1 border border-zinc-400 text-sm rounded"
    onClick={() => openModal(order)}
  >
    View
  </button>
</td>

      </tr>
    ))}
  </tbody>
</table>

        </div>
        <div className="pagination-controls flex items-center gap-2">
        <div className="pagination-controls flex items-center gap-2">
        {/* Previous Button */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-4 py-2 border rounded-lg transition duration-200 ${
            currentPage === 1
              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
              : 'bg-white text-gray-700 hover:bg-gray-100'
          }`}
        >
          Previous
        </button>

        {/* Page Numbers */}
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`px-4 py-2 border rounded-lg transition duration-200 ${
              currentPage === index + 1
                ? 'bg-blue-500 text-white border-blue-500' // Highlight active page
                : 'bg-white text-gray-700 hover:bg-gray-100' // Default style
            }`}
          >
            {index + 1}
          </button>
        ))}

        {/* Next Button */}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 border rounded-lg transition duration-200 ${
            currentPage === totalPages
              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
              : 'bg-white text-gray-700 hover:bg-gray-100'
          }`}
        >
          Next
        </button>
      </div>
    </div>

        {/* Modal for Order Details */}
        {selectedOrder && (
          <div className="fixed inset-0 bg-amber-500 bg-opacity-50  flex items-center justify-center z-50">
            <div
              id="order-details"
              className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full relative max-h-screen overflow-y-auto"
            >
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 bg-yellow-500 text-white py-2 px-4 rounded"
              >
                Close
              </button>
              <h2 className="text-2xl font-bold mb-4">Order Details</h2>

              <div className="space-y-2">
                <div className="flex justify-between border-b pb-2">
                  <p><strong>Order ID:</strong></p>
                  <p>{selectedOrder._id}</p>
                </div>
                <div className="flex justify-between border-b pb-2">
                  <p><strong>Order Code:</strong></p>
                  <p>{selectedOrder.order_code}</p>
                </div>

                <div className="flex justify-between border-b pb-2">
  <p><strong>Date Delivered:</strong></p>
  <p>
    {selectedOrder.date_delivered && 
     new Date(selectedOrder.date_delivered).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.date_delivered).toLocaleDateString()} ${new Date(selectedOrder.date_delivered).toLocaleTimeString()}` 
      : ''}
  </p>
</div>
<div className="flex justify-between border-b pb-2">
  <p><strong>pickup time</strong></p>
  <p>
    {selectedOrder.pickup_time && 
     new Date(selectedOrder.pickup_time).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.pickup_time).toLocaleDateString()} ${new Date(selectedOrder.pickup_time).toLocaleTimeString()}` 
      : ''}
  </p>
</div>

                <div className="flex justify-between border-b pb-2">
                  <p><strong>Order Date:</strong></p>
                  <p>{selectedOrder.created_at ?
                    `${new Date(selectedOrder.created_at).toLocaleDateString()} ${new Date(selectedOrder.created_at).toLocaleTimeString()}`
                    : ' '}</p>
                </div>

                <div className="flex justify-between border-b pb-2">
  <p><strong>created At</strong></p>
  <p>
    {selectedOrder.created_at && 
     new Date(selectedOrder.created_at).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.created_at).toLocaleDateString()} ${new Date(selectedOrder.created_at).toLocaleTimeString()}` 
      : ''}
  </p>
</div>

<div className="flex justify-between border-b pb-2">
  <p><strong>Enroute Time</strong></p>
  <p>
    {selectedOrder.enroute_time && 
     new Date(selectedOrder.enroute_time).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.enroute_time).toLocaleDateString()} ${new Date(selectedOrder.enroute_time).toLocaleTimeString()}` 
      : ''}
  </p>
</div>



                <div className="flex justify-between border-b pb-2">
                  <p><strong>Order Status:</strong></p>
                  <p>{selectedOrder.order_status}</p>
                </div>
                
                <div className="flex justify-between border-b pb-2">
                  <p><strong>Is in Stock:</strong></p>
                  <p>{selectedOrder.is_in_stock}</p>
                </div>
                

                <div className="flex justify-between border-b pb-2">
                  <p><strong>Order Status:</strong></p>
                  <p>{selectedOrder.order_status}</p>
                </div>
                <div className="flex justify-between border-b pb-2">
                  <p><strong>Payment Method:</strong></p>
                  <p>{selectedOrder.payment_method}</p>
                </div>
                
                

                <div className="flex justify-between border-b pb-2">
                  <p><strong>Agent:</strong></p>
                  <Link to={`/pr-admin/agents/agent-details/${selectedOrder.agent}`}>
                    <img src={removeRedEye} alt="View" />
                  </Link>
                </div>
                <div className="flex justify-between border-b pb-2">
                  <p><strong>User:</strong></p>

                  <Link to={`/pr-admin/users/user-details/${selectedOrder.user}`}>

                    <img src={removeRedEye} alt="View" />
                  </Link>
                </div>



                <div className="flex justify-between border-b pb-2">
                  <p><strong>Agent Delivered Order Upload:</strong></p>
                  <p>{selectedOrder.agent_delivered_order_upload}</p>
                </div>
                <div className="flex justify-between border-b pb-2">
                  {selectedOrder && <DeliveryLocation locationId={selectedOrder.delivery_location as string} />}

                </div>
                
              

                <div className="flex justify-between border-b pb-2">
                  <p><strong>Note:</strong></p>
                  <p>{selectedOrder.note}</p>
                </div>
                <div className="flex justify-between border-b pb-2">
                  <p><strong>User Received Order Upload:</strong></p>
                  <p>{selectedOrder.user_received_order_upload}</p>
                </div>
              </div>

              <div className="flex justify-between border-b pb-2">
                  <p><strong>Discount (Order):</strong></p>
                  <p>₦{selectedOrder.discount?.order?.toFixed(2)}</p>
                </div>
                <div className="flex justify-between border-b pb-2">
                  <p><strong>Discount (Delivery):</strong></p>
                  <p>₦{selectedOrder.discount?.delivery?.toFixed(2)}</p>
                </div>
                <div className="flex justify-between border-b pb-2">
          <p><strong>Service Charge:</strong></p>
          <p>{selectedOrder.tax}</p>
        </div>
              <div className="mt-4">

        <ul>
          {selectedOrder.items?.map((item: { meal: { _id: React.Key | null | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { amount: number; }; }; qty: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => (
            <li key={item.meal._id} className="mb-2">
              <div className="flex justify-between border-b pb-2">
                <p><strong>Meal Name:</strong></p>
                <p>{item.meal?.name}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Description:</strong></p>
                <p>{item.meal?.description}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Quantity:</strong></p>
                <p>{item.qty}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Price:</strong></p>
                <p>₦{item.meal?.price.amount.toFixed(2)}</p>
              </div>
              
            </li>
          ))}
        </ul>
      </div>




              <button
                onClick={downloadInvoice}
                className="mt-4 bg-green-500 text-white py-2 px-4 rounded"
              >
                Download Invoice
              </button>
            </div>
          </div>
        )}







      </div></>
  );
};

export default NotificationWrapper;
