import React from 'react';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  type: 'success' | 'error';
  message: string; // Include the message prop
  children?: React.ReactNode; // Optional children prop
}

const Modal: React.FC<ModalProps> = ({ message, type, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background Overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>
      
      {/* Modal Content */}
      <div className={`bg-white z-40 rounded-lg shadow-lg p-6 w-96 ${type === 'success' ? 'border-green-500' : 'border-red-500'}`}>
        <h2 className={`text-xl font-bold ${type === 'success' ? 'text-green-500' : 'text-red-500'}`}>
          {type === 'success' ? 'Success' : 'Error'}
        </h2>
        <p className="mt-4">{message}</p>
        <div className="mt-6 flex justify-end">
          <button
            onClick={onClose}
            className="bg-amber-500 text-white px-4 py-2 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
