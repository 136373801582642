// Settings.tsx
import React, { useState, useEffect } from 'react';
import AdminService from '../../services/admin.services';
import AdminTable from './components/AdminTable';
import SettingsTabs from './components/SettingsTabs';
import { Admin } from 'src/services/types';

const adminService = new AdminService();

const Settings: React.FC = () => {
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tab, setTab] = useState<number>(0);
  const itemsPerPage = 50;

  useEffect(() => {
    if (tab === 0) {
      fetchAdmins();
    }
  }, [currentPage, tab]);

  const fetchAdmins = async () => {
    try {
      const response = await adminService.getAdmins();
      if (response && Array.isArray(response)) {
        setAdmins(response);
      } else {
        console.error('Failed to fetch admins');
      }
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  return (
    <div className="flex">
      <div className="flex-1 p-6 ml-64">
        <h2 className="text-2xl font-bold mb-6">Admin Settings</h2>
        <SettingsTabs setTab={setTab} tab={tab} />
        <div className="mt-6">
          {tab === 0 && (
            <div>
              <h3 className="text-xl font-semibold mb-4">Admin Table</h3>
              <AdminTable
                data={admins} // Ensure `admins` is an array of Admin objects
                currentPage={currentPage}
                totalPages={Math.ceil(admins.length / itemsPerPage)}
                onPageChange={setCurrentPage}
                itemsPerPage={itemsPerPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
