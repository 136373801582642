import React, { useState } from 'react';
import { Restaurant } from '../../../services/types';
import Modal from './Modal'; // Adjust the import path as needed
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { FaCopy } from 'react-icons/fa';
import AdminService from 'src/services/admin.services';

// Create an instance of AdminService
const adminService = new AdminService();


const mapContainerStyle = {
  height: '400px',
  width: '100%',
};

const center = {
  lat: 0,
  lng: 0,
};

interface RestaurantDetailsProps {
  restaurant: Restaurant;
  onStatusChange: (newStatus: boolean) => Promise<void>;
  checkRestaurant: (agent: { email: string }) => Promise<any>;
}

const RestaurantDetails: React.FC<RestaurantDetailsProps> = ({
  restaurant,
  onStatusChange,
  checkRestaurant,
}) => {
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isVerified, setIsVerified] = useState(restaurant.is_verified); // Assuming the restaurant object has an `is_verified` field
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(restaurant._id);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleActivate = async () => {
    await onStatusChange(true);
    setIsActivateModalOpen(false);
  };

  // Refactored checkRestaurantByEmail to use adminService
  const checkRestaurantByEmail = async (email: string): Promise<any> => {
    const token = localStorage.getItem("token"); // Update the key here to match the stored token key
  
  
    if (!token) {
      throw new Error("Authentication token is missing.");
    }
  
    try {
      const response = await adminService.checkRestaurant({ email }); // Use adminService here
      
      return response;
    } catch (error) {
      console.error("Detailed error during verification:", error);
      throw error;
    }
  };
  
  
  const handleVerify = async () => {
    try {
      if (isVerified) {
        setSuccessMessage("Restaurant document has already been checked.");
        setIsSuccessModalOpen(true);
        return;
      }
  
      // Using the refactored checkRestaurantByEmail
      const response = await checkRestaurantByEmail(restaurant.email);
      console.log("Verification response:", response);
  
      if (response?.success) {
        setSuccessMessage(response.message || "Verification successful!");
        setIsSuccessModalOpen(true);
        setIsVerified(true);
      } else if (response?.message?.includes('already approved')) {
        // Handle the case where the restaurant is already verified
        setErrorMessage("This restaurant is already verified.");
        setIsErrorModalOpen(true);
      } else {
        throw new Error(response?.message || "Verification failed.");
      }
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : "An unknown error occurred.";
      console.error("Detailed error:", error); // Log detailed error
      setErrorMessage(errorMsg);
      setIsErrorModalOpen(true);
    }
  };
  

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Restaurant Image */}
      <div className="relative">
        <img
          src={restaurant.image_url || 'placeholder-image-url'}
          alt={restaurant.name}
          className="w-full h-64 object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-40"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white text-2xl font-bold bg-black bg-opacity-50 p-4">
          {restaurant.name}
        </div>
      </div>

      <div className="p-6">
        <div className="flex items-center mb-6">
          <img
            src={restaurant.image_url || 'placeholder-image-url'}
            alt={restaurant.name}
            className="w-24 h-24 object-cover rounded-full border-4 border-gray-300"
          />
          <div className="ml-4">
            <h2 className="text-3xl font-bold text-gray-800">{restaurant.name}</h2>
            <p className="text-gray-600 text-lg">{restaurant.email}</p>
            <p className="text-gray-600 text-lg">{restaurant.phone}</p>
          </div>
        </div>

        {/* Address */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700">Address</h3>
          <p className="text-gray-600 text-lg">{restaurant.address}</p>
          <p className="text-gray-600 text-lg">
            {/* Ensure Coordinates exist before accessing */}
            Lat: {restaurant.location?.Coordinates?.[1] ?? 'N/A'}, 
            Lng: {restaurant.location?.Coordinates?.[0] ?? 'N/A'}
          </p>
        </div>

        {/* Google Maps Integration */}
        {restaurant.location?.Coordinates && restaurant.location.Coordinates.length >= 2 ? (
          <div className="mb-6">
            <h3 className="text-xl font-semibold text-gray-700">Location</h3>
            <LoadScript googleMapsApiKey="AIzaSyD845dpQ62RHqNW83JcyA5YKaRQ05UVl8I">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={{
                  lat: restaurant.location.Coordinates[1],
                  lng: restaurant.location.Coordinates[0],
                }}
                zoom={15}
              >
                <Marker
                  position={{
                    lat: restaurant.location.Coordinates[1],
                    lng: restaurant.location.Coordinates[0],
                  }}
                />
              </GoogleMap>
            </LoadScript>
          </div>
        ) : (
          <p className="text-red-600">Location coordinates not available.</p>
        )}

        {/* Days Open */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700">Days Open</h3>
          <ul className="list-disc list-inside text-gray-600">
            {restaurant.days_open?.map((day, index) => (
              <li key={index} className="mb-2">
                {day.day}: {day.open} - {day.close}
              </li>
            ))}
          </ul>
        </div>

        {/* Verification Button */}
        <div className="mb-6">
          <button
            className="bg-amber-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={handleVerify}
            disabled={isVerified} // Disable button if already verified
          >
            {isVerified ? 'Verified' : 'Verify Document'}
          </button>
        </div>
      </div>

     {/* Success Modal */}
      <Modal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        title="Verification Successful"
        message={successMessage}
        confirmText="OK"
        onConfirm={() => setIsSuccessModalOpen(false)}
      />

      {/* Error Modal */}
      <Modal
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        title="Verification Failed"
        message={errorMessage || "Something went wrong."} // Fallback if errorMessage is undefined
        confirmText="OK"
        onConfirm={() => setIsErrorModalOpen(false)}
      />

      {/* Activate Modal */}
      <Modal
        isOpen={isActivateModalOpen}
        onClose={() => setIsActivateModalOpen(false)}
        title="Activate Restaurant"
        message="Are you sure you want to activate this restaurant?"
        confirmText="Yes"
        cancelText="Cancel"
        onConfirm={handleActivate}
      />
    </div>
  );
};

export default RestaurantDetails;
