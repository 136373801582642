import React, { useEffect, useState } from 'react';
import { AppLayout } from 'src/components/layouts';
import UserTable from './components/UserTable';
import AdminService from 'src/services/admin.services';
import { User } from '../../services/types';
import SearchInput from "../../components/ui/SearchInput";
import Statistics from './components/Statistics';
import BackButton from 'src/components/BackButton';

 
const adminService = new AdminService();
const ITEMS_PER_PAGE = 10;

const UserManagement: React.FC = () => {
  const [data, setData] = useState<User[]>([]);
  const [filteredData, setFilteredData] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const users = await adminService.getUsers(1); // Fetch users from server
        setData(users);
        setFilteredData(users); // Initialize filtered data
      } catch (error) {
        console.error('Error fetching users:', error);
        setError('Error fetching users.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      setFilteredData(
        data.filter((user) =>
          user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredData(data);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  // Calculate statistics
  const totalUsers = data.length;
  const activeUsers = data.filter(user => !user.is_suspended).length;
  const inactiveUsers = data.filter(user => user.is_suspended).length;

  return (
    <AppLayout>
      <BackButton />
      <div className="grid lg:grid-cols-2 gap-4">
        <div className="lg:col-span-3">
          <div className="bg-white shadow-lg rounded-md p-8">
            <h2 className="text-xl font-semibold mb-4">User Management</h2>
            <div className="mb-4">
              <SearchInput search={handleSearch} placeholder="Search users..." />
            </div>
            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <p>Loading...</p>
              </div>
            ) : error ? (
              <div className="flex justify-center items-center h-full">
                <p className="text-red-500">{error}</p>
              </div>
            ) : (
              <UserTable
                data={filteredData}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                itemsPerPage={ITEMS_PER_PAGE}
              />
            )}
          </div>
        </div>

        <div className="w-full lg:col-span-1 flex flex-col space-y-8">
          <h2 className="text-xl font-semibold mb-4">Statistics</h2>
          <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4">
            <Statistics
              totalUsers={totalUsers}
              activeUsers={activeUsers}
              inactiveUsers={inactiveUsers}
            />
           
          </div>
        </div>
        

      </div>
    </AppLayout>
  );
};

export default UserManagement;
