import React, { useState } from 'react';
import AdminService from '../../../services/admin.services';
import Modal from './Modal'; // Ensure this path is correct

const adminService = new AdminService();

const CreateMealCategory: React.FC = () => {
  const [categoryName, setCategoryName] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setShowModal(false);

    try {
      const result = await adminService.createMealCategory(categoryName.toLowerCase()); // Convert to lowercase
      if (result) {
        setSuccess('Meal category created successfully');
        setShowModal(true);
        setCategoryName('');
      }
    } catch (error) {
      setError('Meal category created successfully'); // Update error message
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setError(null);
    setSuccess(null);
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Create Meal Category</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="categoryName" className="block text-sm font-medium text-gray-700">
            Meal Category Name
          </label>
          <input
            id="categoryName"
            type="text"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-amber-500 text-white rounded-md hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create
        </button>
      </form>

      {/* Modal to show success or error messages */}
      <Modal
        show={showModal}
        onClose={closeModal}
        title={success ? "Success" : "Error"}
        message={success || error || ""}
        type={success ? "success" : "error"}
      />
    </div>
  );
};

export default CreateMealCategory;
