import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Import the WhatsApp icon

const Welcome: React.FC = () => {
  // Get current date and time
  const currentDate = new Date();

  // Format date and time
  const formattedDate = currentDate.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
  const formattedTime = currentDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  const dayOfWeek = currentDate.toLocaleDateString('en-US', {
    weekday: 'long',
  });

  return (
    <div className="relative flex justify-between items-center mx-auto">
      <div className="text-black">
        <div className="font-['Outfit'] text-4xl font-medium leading-[50px] tracking-normal">Good Morning</div>
        <div className="font-['Outfit'] text-[#7A7A7A] text-lg font-lighter leading-[27px] tracking-[0.035rem]">Welcome to Peakreach admin dashboard</div>
      </div>
      <div className="text-black text-lg font-normal font-['Outfit'] leading-[27px] tracking-tight absolute bottom-0 right-0">
        {`${formattedDate}, ${dayOfWeek} ${formattedTime}`}
      </div>

     
    </div>
  );
};

export default Welcome;
