import React, { useState, useEffect } from 'react';
import ProgressBar from '../../../components/ui/ProgressBar';
import AdminService from '../../../services/admin.services';



const Rightnav: React.FC = () => {
    const [statsData, setStatsData] = useState<{
        agents: number;
        orders: number;
        parcels: number;
        restaurants: number;
        users: number;
    } | null>(null);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const adminService = new AdminService();
                const response = await adminService.getAppStats();
                setStatsData(response.data);
            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };

        fetchStats();
    }, []);

    const getProgressBarColor = (value: number) => {
        if (value >= 50) return 'bg-green-500'; // High value
        if (value >= 20) return 'bg-yellow-500'; // Medium value
        if (value >= 10) return 'bg-orange-500'; // Low value
        return 'bg-red-500'; // Very low value
    };

    return (
        <div className="px-4 pt-2 pb-6 rounded-xl border border-zinc-300 flex-col items-start gap-2.5 inline-flex absolute right-4">
            <div className="text-black text-xl font-medium font-['Outfit'] leading-[30px] tracking-tight">Stastistic Progress</div>
            <div className="flex-col justify-start items-start gap-6 flex">
                {statsData ? (
                    <>
                        <div className="flex-col justify-start items-center gap-2.5 flex">
                            <div className="w-[210px] justify-between items-center inline-flex">
                                <div className="text-black text-base font-normal leading-normal">Agents</div>
                                <div className="text-zinc-400 text-base font-normal leading-normal">{statsData.agents}</div>
                            </div>
                            <div className='w-[210px]'>
                                <ProgressBar 
                                    progress={`w-[${Math.min(statsData.agents, 100)}%]`}
                                    progressColor={getProgressBarColor(statsData.agents)}
                                />
                            </div>
                        </div>
                        <div className="flex-col justify-start items-center gap-2.5 flex">
                            <div className="w-[210px] justify-between items-center inline-flex">
                                <div className="text-black text-base font-normal leading-normal">Orders</div>
                                <div className="text-zinc-400 text-base font-normal leading-normal">{statsData.orders}</div>
                            </div>
                            <div className='w-[210px]'>
                                <ProgressBar 
                                    progress={`w-[${Math.min(statsData.orders, 100)}%]`}
                                    progressColor={getProgressBarColor(statsData.orders)}
                                />
                            </div>
                        </div>
                        <div className="flex-col justify-start items-center gap-2.5 flex">
                            <div className="w-[210px] justify-between items-center inline-flex">
                                <div className="text-black text-base font-normal leading-normal">Parcels</div>
                                <div className="text-zinc-400 text-base font-normal leading-normal">{statsData.parcels}</div>
                            </div>
                            <div className='w-[210px]'>
                                <ProgressBar 
                                    progress={`w-[${Math.min(statsData.parcels, 100)}%]`}
                                    progressColor={getProgressBarColor(statsData.parcels)}
                                />
                            </div>
                        </div>
                        <div className="flex-col justify-start items-center gap-2.5 flex">
                            <div className="w-[210px] justify-between items-center inline-flex">
                                <div className="text-black text-base font-normal leading-normal">Restaurants</div>
                                <div className="text-zinc-400 text-base font-normal leading-normal">{statsData.restaurants}</div>
                            </div>
                            <div className='w-[210px]'>
                                <ProgressBar 
                                    progress={`w-[${Math.min(statsData.restaurants, 100)}%]`}
                                    progressColor={getProgressBarColor(statsData.restaurants)}
                                />
                            </div>
                        </div>
                        <div className="flex-col justify-start items-center gap-2.5 flex">
                            <div className="w-[210px] justify-between items-center inline-flex">
                                <div className="text-black text-base font-normal leading-normal">Users</div>
                                <div className="text-zinc-400 text-base font-normal leading-normal">{statsData.users}</div>
                            </div>
                            <div className='w-[210px]'>
                                <ProgressBar 
                                    progress={`w-[${Math.min(statsData.users, 100)}%]`}
                                    progressColor={getProgressBarColor(statsData.users)}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
};

export default Rightnav;
