import React from 'react';
import { Logistic } from '../../../services/types';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, ArcElement, PointElement, LineElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, ArcElement, PointElement, LineElement);

interface LogisticsAnalysisProps {
  logistics: Logistic[];
}

const LogisticsAnalysis: React.FC<LogisticsAnalysisProps> = ({ logistics }) => {
  const totalOrders = logistics.length;
  const totalCost = logistics.reduce((sum, logistic) => sum + logistic.total, 0);

  // Order Status Distribution
  const orderStatusCount = logistics.reduce((statusCount, logistic) => {
    statusCount[logistic.order_status] = (statusCount[logistic.order_status] || 0) + 1;
    return statusCount;
  }, {} as Record<string, number>);

  const pieChartData = {
    labels: Object.keys(orderStatusCount),
    datasets: [{
      label: 'Order Status Distribution',
      data: Object.values(orderStatusCount),
      backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF'],
      borderColor: '#fff',
      borderWidth: 1,
    }]
  };

  // Cost Distribution Against Date
  const costDistributionData = logistics.reduce((data, logistic) => {
    const date = new Date(logistic.created_at).toLocaleDateString();
    if (!data[date]) {
      data[date] = 0;
    }
    data[date] += logistic.total;
    return data;
  }, {} as Record<string, number>);

  const lineChartData = {
    labels: Object.keys(costDistributionData),
    datasets: [{
      label: 'Cost Distribution',
      data: Object.values(costDistributionData),
      borderColor: '#36A2EB',
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderWidth: 2,
    }]
  };

  // Number of Orders per Status
  const ordersPerStatus = logistics.reduce((statusCount, logistic) => {
    statusCount[logistic.order_status] = (statusCount[logistic.order_status] || 0) + 1;
    return statusCount;
  }, {} as Record<string, number>);

  const barChartData = {
    labels: Object.keys(ordersPerStatus),
    datasets: [{
      label: 'Number of Orders per Status',
      data: Object.values(ordersPerStatus),
      backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF'],
      borderColor: '#fff',
      borderWidth: 1,
    }]
  };

  return (
    <div className="p-4 mt-4 bg-gray-100 rounded-md shadow-md">
      <h2 className="text-lg font-semibold mb-4">Logistics Analysis</h2>
      <div className="flex flex-col md:flex-row md:space-x-4">
        <div className="flex-1 bg-amber-500 p-4 rounded-md border border-amber-600 mb-4 md:mb-0">
          <h3 className="text-md font-medium mb-2">Total Orders</h3>
          <p className="text-lg font-bold">{totalOrders}</p>
        </div>
        <div className="flex-1 bg-amber-500 p-4 rounded-md border border-amber-600 mb-4 md:mb-0">
          <h3 className="text-md font-medium mb-2">Total Cost</h3>
          <p className="text-lg font-bold">₦{totalCost.toFixed(2)}</p>
        </div>
      </div>
      <div className="mt-4 flex flex-col md:flex-row md:space-x-4">
        <div className="flex-1 bg-amber-500 p-4 rounded-md border border-amber-600 mb-4 md:mb-0">
          <h3 className="text-md font-medium mb-2">Order Status Distribution</h3>
          <Pie data={pieChartData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
        </div>
        <div className="flex-1 bg-amber-500 p-4 rounded-md border border-amber-600 mb-4 md:mb-0">
          <h3 className="text-md font-medium mb-2">Cost Distribution</h3>
          <Line data={lineChartData} options={{ responsive: true, plugins: { legend: { display: false } } }} />
        </div>
        <div className="flex-1 bg-amber-500 p-4 rounded-md border border-amber-600">
          <h3 className="text-md font-medium mb-2">Number of Orders per Status</h3>
          <Bar data={barChartData} options={{ responsive: true, plugins: { legend: { display: false } } }} />
        </div>
      </div>
    </div>
  ); 
};

export default LogisticsAnalysis;
