import {
  FaSignOutAlt,
  FaTachometerAlt,
  FaUser,
  FaUsers,
  FaUtensils,
  FaMoneyBill,
  FaCog,
  FaBell,
  FaTruck,
  FaMapMarkerAlt,
  FaShoppingBasket,
} from 'react-icons/fa'; 

export const navlinks = [
  {
    to: 'dashboard',
    icon: <FaTachometerAlt />,
    name: 'Dashboard'
  },
  {
    to: 'users',
    icon: <FaUser />,
    name: 'User Management'
  },
  {
    to: 'agents',
    icon: <FaUsers />,
    name: 'Agent Management'
  },
  {
    to: 'restaurants',
    icon: <FaUtensils />,
    name: 'Restaurant Management'
  },
  {
    to: 'logistics',
    icon: <FaTruck />,
    name: 'Logistics'
  },
  
  {
    to: 'notification',
    icon: <FaShoppingBasket />,
    name: 'Order',
    badge: true
  },
  /*
  {
    to: 'delivery-location',
    icon: <FaMapMarkerAlt />,
    name: 'Delivery Location'
  },
  */
  {
    to: 'assign-rider',
    icon: <FaMapMarkerAlt />, 
    name: 'Assign Rider'
  },
  {
    to: 'finances',
    icon: <FaMoneyBill />,
    name: 'Finances'
  },
  {
    to: 'settings',
    icon: <FaCog />,
    name: 'Settings'
  },
 
  {
    to: 'logout',
    icon: <FaSignOutAlt />,
    name: 'Logout'
  }
];
