import React from 'react';
import AdminService from 'src/services/admin.services';
import { Suspension, Admin } from 'src/services/types';

interface SuspensionHistoryProps {
  response: Suspension[];
  page: number;
  totalPages: number;
}

const SuspensionHistory: React.FC<SuspensionHistoryProps> = ({ response, page, totalPages }) => {
  const [suspensions, setSuspensions] = React.useState<Suspension[]>(response);
  const [currentPage, setCurrentPage] = React.useState<number>(page);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [selectedSuspension, setSelectedSuspension] = React.useState<Suspension | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [adminDetails, setAdminDetails] = React.useState<Admin | null>(null); // Admin details state

  React.useEffect(() => {
    const fetchSuspensionHistory = async () => {
      setLoading(true);
      try {
        const adminService = new AdminService();
        const fetchedResponse = await adminService.getSuspensionHistory(currentPage);
        setSuspensions(fetchedResponse);
      } catch (err) {
        setError('Failed to fetch suspension history.');
      } finally {
        setLoading(false);
      }
    };

    fetchSuspensionHistory();
  }, [currentPage]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const openModal = async (suspension: Suspension) => {
    setSelectedSuspension(suspension);
    try {
      const adminService = new AdminService();
      const admin = await adminService.getAdminDetails(suspension.suspended_by); // Fetch admin details
      setAdminDetails(admin);
    } catch (error) {
      console.error('Error fetching admin details:', error);
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedSuspension(null);
    setAdminDetails(null); // Reset admin details on close
    setIsModalOpen(false);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Suspension History</h2>
      <table className="w-full bg-gray-100 border border-gray-300 rounded-lg">
        <thead>
          <tr>
            <th className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">ID</th>
            <th className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">Status</th>
            <th className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">Suspendee ID</th>
            <th className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">Date Suspended</th>
            <th className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">View Detail</th>
          </tr>
        </thead>
        <tbody>
          {suspensions.map((suspension) => (
            <tr key={suspension._id}>
              <td className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">{suspension._id}</td>
              <td className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">{suspension.status}</td>
              <td className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">{suspension.suspendee_id}</td>
              <td className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">{new Date(suspension.date_suspended).toLocaleDateString()}</td>
              <td className="px-5 py-3 bg-amber-200 text-left text-xs font-semibold text-black uppercase tracking-wider">
                <button
                  onClick={() => openModal(suspension)}
                  className="bg-amber-500 text-white px-4 py-2 rounded"
                >
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 flex justify-between items-center">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="h-11 px-5 rounded-xl border border-amber-500 text-black text-base font-normal"
        >
          Previous
        </button>
        <span className="text-black text-base font-normal">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="h-11 px-5 rounded-xl border border-amber-500 text-black text-base font-normal"
        >
          Next
        </button>
      </div>

      {/* Modal Box */}
      {isModalOpen && selectedSuspension && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full relative max-h-screen overflow-y-auto">
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-yellow-500 text-white px-5 py-3 border-zinc-400 text-left text-xs font-semibold uppercase tracking-wider rounded"
            >
              Close
            </button>
            <h2 className="text-2xl font-bold mb-4">Suspension Details</h2>
            <div className="space-y-2">
              <div className="flex justify-between border-b pb-2">
                <p><strong>Suspension ID:</strong></p>
                <p>{selectedSuspension._id}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Suspended By:</strong></p>
                {adminDetails ? (
                  <p>{`${adminDetails.first_name} ${adminDetails.last_name}`}</p>
                ) : (
                  <p>Loading admin details...</p>
                )}
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Reason:</strong></p>
                <p>{selectedSuspension.reason}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Duration:</strong></p>
                <p>{selectedSuspension.duration}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Status:</strong></p>
                <p>{selectedSuspension.status}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Suspendee ID:</strong></p>
                <p>{selectedSuspension.suspendee_id}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Type:</strong></p>
                <p>{selectedSuspension.type}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Date Suspended:</strong></p>
                <p>{new Date(selectedSuspension.date_suspended).toLocaleDateString()}</p>
              </div>
              {selectedSuspension.date_lifted && (
                <div className="flex justify-between border-b pb-2">
                  <p><strong>Date Lifted:</strong></p>
                  <p>{new Date(selectedSuspension.date_lifted).toLocaleDateString()}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuspensionHistory;
