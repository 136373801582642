import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { request } from '../../../hooks/api';
import LogisticDetails from './LogisticDetails';
import { Logistic, Location } from 'src/services/types';
import AdminService from 'src/services/admin.services';


const adminService = new AdminService();
const ITEMS_PER_PAGE = 50;

const LogisticsWrapper: React.FC = () => {
  
  const [logistics, setLogistics] = useState<Logistic[]>([]);
  const [logisticsLoading, setLogisticsLoading] = useState<boolean>(false);
  const [logisticsError, setLogisticsError] = useState<string | null>(null);
  const [selectedLogistic, setSelectedLogistic] = useState<Logistic | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sortField, setSortField] = useState<string>('trackingId');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [notifications, setNotifications] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [locationData, setLocationData] = useState<Location | null>(null);

// Reusable function to fetch logistics data
const fetchLogisticsData = async (
  selectedStatus: 'pending' | 'cancelled' | 'delivered' | 'confirmed',
  page: number,
  searchTerm?: string,
  sortField?: string,
  sortOrder?: string,
  locationData?: { lat: number; lng: number }
) => {
  try {
    const logisticsData = await adminService.getLogisticsManagement(page);
    
    if (searchTerm || sortField || sortOrder || locationData) {
      // Optionally, filter or sort data based on searchTerm, sortField, sortOrder, and locationData
      let filteredLogistics = logisticsData;

      // Example: Filter by coordinates if locationData is available
      if (locationData?.lat && locationData?.lng) {
        filteredLogistics = filterByCoordinates(
          filteredLogistics,
          locationData.lng,
          locationData.lat
        );
      }

      return filteredLogistics;
    }

    return logisticsData;
  } catch (error) {
    console.error("Error fetching logistics data:", error);
    throw error;
  }
};


  
  function filterByCoordinates(
    orders: Logistic[], // Update to use Logistic type
    longitude?: number, 
    latitude?: number, 
    maxDistance: number = 1000000000000 // Default max distance (in meters)
  ): Logistic[] {
    if (!longitude || !latitude) {
      return orders; // Return all orders if no coordinates are provided
    }
  
    return orders.filter((order) => {
      const orderLongitude = order.pickup_location.longitude ?? order.receiver.address.longitude;
      const orderLatitude = order.pickup_location.latitude ?? order.receiver.address.latitude;
  
      if (orderLongitude && orderLatitude) {
        // Calculate distance between coordinates using Haversine formula
        const distance = getDistanceFromLatLonInMeters(latitude, longitude, orderLatitude, orderLongitude);
        return distance <= maxDistance;
      }
  
      return false; // Exclude orders without valid coordinates
    });
  }
  
  // Helper function to calculate distance between two sets of coordinates (Haversine formula)
  function getDistanceFromLatLonInMeters(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371e3; // Radius of Earth in meters
    const φ1 = (lat1 * Math.PI) / 180; // Convert latitude from degrees to radians
    const φ2 = (lat2 * Math.PI) / 180; // Convert latitude from degrees to radians
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;
  
    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    return R * c; // Distance in meters
  }
  

  useEffect(() => {
    const notificationInterval = setInterval(() => {
      const randomUpdate = logistics.length
        ? `Update for Logistic ID: ${logistics[Math.floor(Math.random() * logistics.length)].trackingId}`
        : 'New logistic added!';
      setNotifications((prev) => [...prev, randomUpdate]);
    }, 15000);

    return () => clearInterval(notificationInterval);
  }, [logistics]);

  const openModal = (logistic: Logistic) => setSelectedLogistic(logistic);
  const closeModal = () => setSelectedLogistic(null);
  const handlePageChange = (page: number) => page > 0 && page <= totalPages && setCurrentPage(page);

  const handleSort = (field: string) => {
    setSortField(field);
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const handleLogisticUpdate = async (logisticId: string, updateData: Partial<Logistic>) => {
    setIsUpdating(true); // Set isUpdating to true when starting the update process
    setSuccessMessage(null);
    try {
      await adminService.updateLogistics(logisticId, updateData);
      setSuccessMessage("Logistic updated successfully!");
    } catch (error) {
      setError('Error updating logistic');
    } finally {
      setIsUpdating(false); // Set isUpdating to false when the update is complete
    }
  };
  
  
  const handleStatusChange = (logistic: Logistic, newStatus: string) => {
    const updateData = { order_status: newStatus };
    handleLogisticUpdate(logistic._id, updateData);
  };

  

  const handleCancelLogistic = (logistic: Logistic) => {
    console.log('Cancelling logistic:', logistic._id); // Log to ensure it's called
    handleLogisticUpdate(logistic._id, { order_status: 'cancelled' });
  };
  


  if (logisticsLoading) return <div className="text-center">Loading...</div>;
  if (logisticsError) return <div className="text-center text-red-500">{logisticsError}</div>;

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mt-6">
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            placeholder="Search by Tracking ID or Cost"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border border-gray-300 rounded-lg px-4 py-2"
          />
        </div>
        <h2 className="text-2xl font-bold mb-4">Logistics History</h2>
        {logistics.length > 0 ? (
          <>
            <table className="min-w-full bg-white rounded-lg">
              <thead>
                <tr>
                  {['Tracking ID', 'Date Delivered', 'Cost', 'Status'].map((header) => (
                    <th
                      key={header}
                      onClick={() => handleSort(header.toLowerCase().replace(' ', '_'))}
                      className="px-5 py-3 text-left cursor-pointer"
                    >
                      {header} {sortField === header.toLowerCase().replace(' ', '_') && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                  ))}
                  <th className="px-5 py-3">Details</th>
                </tr>
              </thead>
              <tbody>
  {logistics.map((logistic) => (
    <tr
      key={logistic._id}
      className="hover:bg-gray-100 cursor-pointer"
      onClick={() => openModal(logistic)}
    >


      <td className="px-5 py-3">{logistic.trackingId}</td>
      <td className="px-5 py-3">{new Date(logistic.delivered_time).toLocaleDateString() === '1/1/1' ? '' : new Date(logistic.delivered_time).toLocaleDateString()}</td>
      <td className="px-5 py-3">₦{logistic.cost.toFixed(2)}</td>
      <td className="px-5 py-3">
        {logistic.order_status === 'pending' ||  logistic.order_status === 'cancelled' || logistic.order_status === 'delivered' || logistic.order_status === 'confirmed' ? (
          <button
            onClick={() => handleCancelLogistic(logistic)}
            className="text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded"
            disabled={isUpdating}
          >
            {isUpdating ? 'Updating...' : 'Mark as Cancelled'}
          </button>
        ) : null}
      </td>
      <td className="px-5 py-3">
        <button
          onClick={(e) => {
            e.stopPropagation();
            openModal(logistic);
          }}
          className="text-blue-500"
        >
          View Details
        </button>
      </td>
    </tr>
  ))}
</tbody>

            </table>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage <= 1}
                className="px-4 py-2 border rounded"
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= totalPages}
                className="px-4 py-2 border rounded"
              >
                Next
              </button>
            </div>
          </>
        ) : (
          <div className="text-center text-gray-500 mt-4">No logistics found</div>
        )}
      </div>

      {selectedLogistic && (
        <LogisticDetails
          logistic={selectedLogistic}
          onClose={closeModal}
          handleStatusChange={handleStatusChange}
          successMessage={successMessage}
          error={error}
        />
      )}

      {notifications.length > 0 && (
        <div className="absolute top-0 left-0 p-4 bg-gray-800 text-white">
          {notifications.map((notif, idx) => (
            <div key={idx} className="mb-2">{notif}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LogisticsWrapper;
