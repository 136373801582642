import React, { useState, useEffect } from 'react';
import TransactionAnalysis from './components/TransactionAnalysis';
import UserTransactions from './components/UserTransactions';
import AgentTransactions from './components/AgentTransactions';
//import CashPayments from './components/CashPayments';
import { Transaction, CashPayment } from '../../services/types';
import AdminService from '../../services/admin.services';
import BackButton from 'src/components/BackButton';

const Finance: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'userTransactions' | 'agentTransactions' | 'analysis' | 'cashPayments'>('userTransactions');
  const [userTransactions, setUserTransactions] = useState<Transaction[]>([]);
  const [agentTransactions, setAgentTransactions] = useState<Transaction[]>([]);
  const [cashPayments, setCashPayments] = useState<CashPayment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  
  const adminService = new AdminService();

  useEffect(() => {
    const fetchUserTransactions = async () => {
      setLoading(true);
      try {
        const response = await adminService.getUserTransactions(1, 100);
        setUserTransactions(response);
      } catch (error) {
        console.error('Error fetching user transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAgentTransactions = async () => {
      setLoading(true);
      try {
        const response = await adminService.getAgentTransactions(1, 100);
        setAgentTransactions(response);
      } catch (error) {
        console.error('Error fetching agent transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCashPayments = async () => {
      setLoading(true);
      try {
        const response = await adminService.getCashPayments(1, 100);
        setCashPayments(response); // Assuming response is in the correct format
      } catch (error) {
        console.error('Error fetching cash payments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserTransactions();
    fetchAgentTransactions();
    fetchCashPayments();
  }, []);

  return (
    <div className="p-4">
      {/* Back Button */}
      <BackButton />
      
      {/* Tabs Navigation */}
      <div className="mb-4 flex space-x-2">
        {['userTransactions', 'agentTransactions', 'analysis', 'cashPayments'].map(tab => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab as typeof activeTab)}
            className={`py-2 px-4 rounded-md transition duration-300 
                        ${activeTab === tab ? 'bg-amber-500 text-white' : 'bg-gray-200 text-gray-700'}
                        hover:bg-amber-400`}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1).replace(/([A-Z])/g, ' $1')}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      {activeTab === 'userTransactions' && (
        <UserTransactions transactions={userTransactions} loading={loading} error={error} />
      )}
      {activeTab === 'agentTransactions' && (
        <AgentTransactions transactions={agentTransactions} loading={loading} error={error} />
      )}
      {activeTab === 'analysis' && (
        <TransactionAnalysis transactions={[...userTransactions, ...agentTransactions]} />
      )}
     
    </div>
  );
};

export default Finance;
