import React from 'react';
import StarIcons from './StarIcons';

interface StatisticsProps {
  totalAgents: number;
  activeAgents: number;
  inactiveAgents: number;
  totalDeliveries: number;
  totalDeliveryDistance: number;
  averageRating: number; // Change to number type
  averageDeliveriesPerMonth: string;
  averageDeliveryTime: string;
  totalSuspensions: number;
}

const Statistics: React.FC<StatisticsProps> = ({
  totalAgents,
  activeAgents,
  inactiveAgents,
  totalDeliveries,
  totalDeliveryDistance,
  averageRating,
  averageDeliveriesPerMonth,
  averageDeliveryTime,
  totalSuspensions,
}) => {
  return (
    <div className="grid s:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
      <div className="sg:col-span-3 md:col-span-2 col-span-1">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-7">
          <div className="flex flex-col text-center justify-center items-center rounded-[12px] border border-zinc-400 px-[16px] py-[11px]">
            <p className="text-neutral-500 text-base font-normal font-['Nunito'] leading-[27px]">Total Agents</p>
            <h3 className="text-black text-12px] font-bold font-['Nunito'] leading-[42px]">{totalAgents}</h3>
          </div>
          <div className="flex flex-col text-center justify-center items-center rounded-[12px] border border-zinc-400 px-[16px] py-[11px]">
            <p className="text-neutral-500 text-base font-normal font-['Nunito'] leading-[27px]">Active Agents</p>
            <h3 className="text-black text-12px] font-bold font-['Nunito'] leading-[42px]">{activeAgents}</h3>
          </div>
          <div className="flex flex-col text-center justify-center items-center rounded-[12px] border border-zinc-400 px-[16px] py-[11px]">
            <p className="text-neutral-500 text-base font-normal font-['Nunito'] leading-[27px]">Inactive Agents</p>
            <h3 className="text-black text-12px] font-bold font-['Nunito'] leading-[42px]">{inactiveAgents}</h3>
          </div>
          </div>
         
        </div>
        </div>
  );
};

export default Statistics;