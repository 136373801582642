import React, { useState, useEffect, useCallback } from "react";
import searchIcon from "../../assets/icons/search.svg";

interface SearchProps {
  search: (searchTerm: string) => void;
  placeholder?: string;
}

function SearchInput({ search, placeholder }: SearchProps) {
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      search(value);
    }, 300), [search]
  );

  useEffect(() => {
    debouncedSearch(searchValue);
  }, [searchValue, debouncedSearch]);

  const handleSearch = (e: React.SyntheticEvent) => {
    e.preventDefault();
    search(searchValue);
  }

  const handleClear = () => {
    setSearchValue('');
    search('');
  };

  return (
    <form onSubmit={handleSearch} className="w-full border border-zinc-400 rounded-xl flex items-center">
      <button type="submit" className="px-2 py-[0.5rem] flex items-center justify-center">
        <img src={searchIcon} alt="search" />
      </button>
      <input
        autoComplete="off"
        placeholder={placeholder || "Search"}
        type="text"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch(e);
          }
        }}
        className="w-full py-[.38rem] justify-start items-center gap-3 text-[15px] outline-none"
      />
      {searchValue && (
        <button type="button" onClick={handleClear} className="px-2.5 pt-[0.38rem] pb-[0.30rem] flex justify-center">
          x
        </button>
      )}
    </form>
  );
}

// Utility function for debouncing with type annotations
function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
  let timeout: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<T>) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export default SearchInput;
