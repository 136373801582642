import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Bar, Line, Pie } from 'react-chartjs-2';
import AdminService from '../../../services/admin.services';

// Add the necessary imports for Chart.js animations
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const Statistics: React.FC = () => {
  const [statsData, setStatsData] = useState<any>(null);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const adminService = new AdminService();
        const response = await adminService.getAppStats();
        setStatsData(response.data);
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, []);

  const agentsData = {
    labels: ['Agents'],
    datasets: [
      {
        label: 'Number of Agents',
        data: [statsData?.agents || 0],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const ordersData = {
    labels: ['Orders'],
    datasets: [
      {
        label: 'Number of Orders',
        data: [statsData?.orders || 0],
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  const parcelsData = {
    labels: ['Parcels'],
    datasets: [
      {
        label: 'Number of Parcels',
        data: [statsData?.parcels || 0],
        backgroundColor: 'rgba(255, 159, 64, 0.6)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
    ],
  };

  const restaurantsData = {
    labels: ['Restaurants'],
    datasets: [
      {
        label: 'Number of Restaurants',
        data: [statsData?.restaurants || 0],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const usersData = {
    labels: ['Users'],
    datasets: [
      {
        label: 'Number of Users',
        data: [statsData?.users || 0],
        backgroundColor: 'rgba(255, 206, 86, 0.6)',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1,
      },
    ],
  };

  const comparisonData = {
    labels: ['Agents', 'Orders', 'Parcels', 'Restaurants', 'Users'],
    datasets: [
      {
        label: 'Comparison of Numbers',
        data: [
          statsData?.agents || 0,
          statsData?.orders || 0,
          statsData?.parcels || 0,
          statsData?.restaurants || 0,
          statsData?.users || 0,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6 animate__animated animate__fadeIn">App Statistics</h2>
      {statsData ? (
        <>
          <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4 mb-8 animate__animated animate__fadeIn animate__delay-1s">
            <div className="w-full h-[120px] px-4 py-3 rounded-xl border border-zinc-300 flex-col justify-start items-center gap-1.5 inline-flex transition-transform transform hover:scale-105">
              <div className="text-black text-base font-normal leading-normal">Agents</div>
              <div className="text-black text-[24px] font-bold leading-[42px]">{statsData.agents}</div>
            </div>

            <div className="w-full h-[120px] px-4 py-3 rounded-xl border border-zinc-300 flex-col justify-start items-center gap-1.5 inline-flex transition-transform transform hover:scale-105">
              <div className="text-black text-base font-normal leading-normal">Food Orders</div>
              <div className="text-black text-[24px] font-bold leading-[42px]">{statsData.orders}</div>
            </div>

            <div className="w-full h-[120px] px-4 py-3 rounded-xl border border-zinc-300 flex-col justify-start items-center gap-1.5 inline-flex transition-transform transform hover:scale-105">
              <div className="text-black text-base font-normal leading-normal">Parcel Orders</div>
              <div className="text-black text-[24px] font-bold leading-[42px]">{statsData.parcels}</div>
            </div>

            <div className="w-full h-[120px] px-4 py-3 rounded-xl border border-zinc-300 flex-col justify-start items-center gap-1.5 inline-flex transition-transform transform hover:scale-105">
              <div className="text-black text-base font-normal leading-normal">Restaurants</div>
              <div className="text-black text-[24px] font-bold leading-[42px]">{statsData.restaurants}</div>
            </div>

            <div className="w-full h-[120px] px-4 py-3 rounded-xl border border-zinc-300 flex-col justify-start items-center gap-1.5 inline-flex transition-transform transform hover:scale-105">
              <div className="text-black text-base font-normal leading-normal">Users</div>
              <div className="text-black text-[24px] font-bold leading-[42px]">{statsData.users}</div>
            </div>
          </div>

          <div className="mb-4 flex items-center animate__animated animate__fadeIn animate__delay-2s">
            <h1 className="text-black text-xl font-bold mr-4">Filter by Date</h1>
            <div className="relative mr-4 flex items-center">
              <DatePicker
                selected={startDate}
                onChange={(date: Date | null) => setStartDate(date ?? undefined)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                className="pl-10 pr-2 py-1 border border-gray-300 rounded-md text-black"
                placeholderText="Start Date"
              />
              <i className="absolute left-3 text-black fas fa-calendar-alt"></i>
            </div>
            <div className="relative flex items-center">
              <DatePicker
                selected={endDate}
                onChange={(date: Date | null) => setEndDate(date ?? undefined)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate || undefined}
                className="pl-10 pr-2 py-1 border border-gray-300 rounded-md text-black"
                placeholderText="End Date"
              />
              <i className="absolute left-3 text-black fas fa-calendar-alt"></i>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 animate__animated animate__fadeIn animate__delay-3s">
            <div>
              <h3 className="text-lg font-semibold mb-2">Agents</h3>
              <Bar data={agentsData} options={{ responsive: true, animation: { duration: 1000 } }} />
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">Orders</h3>
              <Line data={ordersData} options={{ responsive: true, animation: { duration: 1000 } }} />
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">Parcels</h3>
              <Pie data={parcelsData} options={{ responsive: true, animation: { duration: 1000 } }} />
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">Restaurants</h3>
              <Bar data={restaurantsData} options={{ responsive: true, animation: { duration: 1000 } }} />
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">Users</h3>
              <Line data={usersData} options={{ responsive: true, animation: { duration: 1000 } }} />
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">Comparison of Numbers</h3>
              <Bar data={comparisonData} options={{ responsive: true, animation: { duration: 1000 } }} />
            </div>
          </div>
        </>
      ) : (
        <p className="text-lg font-semibold animate__animated animate__fadeIn">Loading...</p>
      )}
    </div>
  );
};

export default Statistics;
