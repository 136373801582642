// src/components/BackButton.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const BackButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <button 
      onClick={() => navigate(-1)} // Navigate back one page
      className="flex items-center bg-gray-300 text-black px-4 py-2 rounded"
    >
      <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
      Back
    </button>
  );
};

export default BackButton;
