import React, { useEffect, useState } from 'react';
import removeRedEye from '../../../assets/icons/removeRedEye.svg';
import { Logistic } from 'src/services/types';
import LogisticDetails from './LogisticDetails'; // Adjust import path as needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter, faDownload } from '@fortawesome/free-solid-svg-icons';

interface LogisticTableProps {
  data: Logistic[];
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  itemsPerPage: number;
}

const LogisticTable: React.FC<LogisticTableProps> = ({
  data,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
}) => {
  const [visibleData, setVisibleData] = useState<Logistic[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Logistic | null>(null);
  const [filterStatus, setFilterStatus] = useState<string>('all');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredData, setFilteredData] = useState<Logistic[]>([]);
  const [totalCost, setTotalCost] = useState<number>(0);

  useEffect(() => {
    let filtered = data;

    // Filter by order status
    if (filterStatus !== 'all') {
      filtered = data.filter(logistic => logistic.order_status === filterStatus);
    }

    // Search filter by Tracking ID, Receiver's name, Address, Date, Agent, or Order Status
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      filtered = filtered.filter(logistic => {
        const isDateMatch = new Date(logistic.created_at).toLocaleDateString().includes(searchTerm);
        const isTrackingIdMatch = logistic.trackingId.toLowerCase().includes(searchTermLower);
        const isReceiverNameMatch = logistic.receiver.name.toLowerCase().includes(searchTermLower);
        const isAddressMatch = logistic.pickup_location.address.toLowerCase().includes(searchTermLower);
        const isAgentMatch = logistic.agentId.toLowerCase().includes(searchTermLower);
        const isOrderStatusMatch = logistic.order_status.toLowerCase().includes(searchTermLower);

        return isTrackingIdMatch || isReceiverNameMatch || isAddressMatch || isDateMatch || isAgentMatch || isOrderStatusMatch;
      });
    }

    // Paginate data
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filtered.length);
    setVisibleData(filtered.slice(startIndex, endIndex));
    setFilteredData(filtered);

    // Calculate total cost
    setTotalCost(filtered.reduce((sum, logistic) => sum + logistic.total, 0)); 
  }, [data, currentPage, itemsPerPage, filterStatus, searchTerm]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleRowClick = (logistic: Logistic) => {
    setSelectedOrder(logistic);
  };

  const closeModal = () => {
    setSelectedOrder(null);
  };

  const handleDownload = () => {
    const csvRows = [
      [
        'Tracking ID', 'Pickup Location', 'Receiver Name', 'Cost', 'Order Status', 'Picked Up Time',
        'Created At', 'Delivered Time', 'Enroute Time', 'Arrived Time', 'Tax', 'Total', 'Agent ID',
        'User ID', 'Description', 'Payment Method'
      ],
      ...filteredData.map(logistic => [
        logistic.trackingId,
        `${logistic.pickup_location.address}`,  // Now only showing address
        logistic.receiver.name,
        logistic.cost.toFixed(2),
        logistic.order_status,
        logistic.picked_up_time,
        logistic.created_at,
        logistic.delivered_time,
        logistic.enroute_time,
        logistic.arrived_time,
        logistic.tax.toFixed(2),
        logistic.total.toFixed(2),
        logistic.agentId,
        logistic.userId,
        logistic.description || '',
        logistic.payment_method
      ])
    ];

    const csvContent = 'data:text/csv;charset=utf-8,' + csvRows.map(e => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'logistics.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="min-w-full leading-normal bg-amber-200 bg-opacity-25 rounded-tl-xl rounded-tr-xl border rounded-xl">
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center space-x-2">
          <input
            type="text"
            placeholder="Search by Tracking ID, Receiver, Address, Date, Agent or Status"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border border-gray-300 rounded-lg px-4 py-2"
          />
          <button
            onClick={handleDownload}
            className="bg-yellow-500 text-white px-4 py-2 rounded-lg flex items-center"
          >
            <FontAwesomeIcon icon={faDownload} className="mr-2" />
            Download
          </button>
        </div>
        <div className="flex items-center">
          <span className="mr-2">Filter by:</span>
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="border border-gray-300 rounded-lg px-4 py-2"
          >
            <option value="all">All</option>
            <option value="delivered">Delivered</option>
            <option value="pending">Pending</option>
            <option value="cancelled">Cancelled</option>
            <option value="location">Location</option>
          </select>
          <FontAwesomeIcon icon={faFilter} className="ml-2 text-gray-600" />
        </div>
        <div className="p-4">
          <h3 className="text-lg font-semibold">Total Income: ₦{totalCost.toFixed(2)}</h3>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-amber-200 bg-opacity-25 rounded-tl-xl rounded-tr-xl border border-b-0 text-left text-xs font-semibold text-black uppercase tracking-wider">
          <thead>
            <tr>
              <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                S/N
              </th>
              <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Tracking ID
              </th>
              <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Date
              </th>
              <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Pickup Location
              </th>
              <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Receiver Name
              </th>
              <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                status
              </th>
              <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                View More
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-600">
            {visibleData.map((logistic, index) => (
              <tr key={logistic.trackingId} className="border-b border-zinc-400 hover:bg-gray-200 cursor-pointer" onClick={() => handleRowClick(logistic)}>
                <td className="px-5 py-4 text-left text-xs">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                <td className="px-5 py-4 text-left text-xs">{logistic.trackingId}</td>
                <td className="px-5 py-4 text-left text-xs">{new Date(logistic.created_at).toLocaleDateString()}</td>
                <td className="px-5 py-4 text-left text-xs">{logistic.pickup_location.address}</td>
                <td className="px-5 py-4 text-left text-xs">{logistic.receiver.name}</td>
                <td className="px-5 py-4 text-left text-xs">{logistic.order_status}</td>
                <td className="px-5 py-4 text-left text-xs">
                  <img src={removeRedEye} alt="View" className="w-6 h-6" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center p-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg disabled:bg-gray-300"
        >
          Previous
        </button>
        <span className="self-center">{currentPage} of {totalPages}</span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg disabled:bg-gray-300"
        >
          Next
        </button>
      </div>

      {selectedOrder && (
        <LogisticDetails logistic={selectedOrder} onClose={closeModal} handleStatusChange={function (logistic: Logistic, newStatus: string): void {
          throw new Error('Function not implemented.');
        } } successMessage={null} error={null} />
      )}
    </div>
  );
};

export default LogisticTable;
