import React, { useState, useEffect } from 'react';
import AdminService from '../../../services/admin.services';
import AdminTable from '../components/AdminTable';
import SettingsTabs from '../components/SettingsTabs';
import CreateAdmin from '../components/CreateAdmin';
import DeleteAdmin from '../components/DeleteAdmin';
import CreateSuspension from './CreateSuspension';
import SuspensionHistory from '../components/SuspensionHistory';
import MealCategory from '../components/MealCategory';
import CreateMealCategory from '../components/CreateMealCategory';

import { Admin, Suspension, MealCategory as MealCategoryType } from 'src/services/types';


const adminService = new AdminService();

const SettingsWrapper: React.FC = () => {
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [suspensions, setSuspensions] = useState<Suspension[]>([]);
  const [mealCategories, setMealCategories] = useState<MealCategoryType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tab, setTab] = useState<number>(0);
  const itemsPerPage = 50;

  useEffect(() => {
    if (tab === 0) {
      fetchAdmins();
    } else if (tab === 4) {
      fetchSuspensions();
    } else if (tab === 5) {
      fetchMealCategories();
    }
  }, [currentPage, tab]);

  const fetchAdmins = async () => {
    try {
      const response = await adminService.getAdmins();
      if (response && Array.isArray(response)) {
        setAdmins(response);
      } else {
        console.error('Failed to fetch admins');
      }
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const fetchMealCategories = async () => {
    try {
      const response = await adminService.getMealCategories();
      if (response && Array.isArray(response)) {
        setMealCategories(response);
      } else {
        console.error('Failed to fetch meal categories');
      }
    } catch (error) {
      console.error('Error fetching meal categories:', error);
    }
  };

  const deleteMeal = async (id: string) => {
    try {
      await adminService.deleteMeal(id);
      setMealCategories((prevCategories) => prevCategories.filter((category) => category._id !== id)); // Use _id
    } catch (error) {
      console.error('Error deleting meal category:', error);
    }
  };

  const fetchSuspensions = async () => {
    try {
      const response = await adminService.getSuspensionHistory(currentPage);
      if (response && Array.isArray(response)) {
        setSuspensions(response);
      } else {
        console.error('Failed to fetch suspensions');
      }
    } catch (error) {
      console.error('Error fetching suspensions:', error);
    }
  };

  return (
    <div className="flex">
      <div className="flex-1 p-6 ml-64">
        <h2 className="text-2xl font-bold mb-6">Admin Settings</h2>
        <SettingsTabs setTab={setTab} tab={tab} />
        <div className="mt-6">
          {tab === 0 && (
            <div>
              <h3 className="text-xl font-semibold mb-4">Admin Table</h3>
              <AdminTable
                data={admins}
                currentPage={currentPage}
                totalPages={Math.ceil(admins.length / itemsPerPage)}
                onPageChange={setCurrentPage}
                itemsPerPage={itemsPerPage}
              />
            </div>
          )}
          {tab === 1 && <CreateAdmin />}
          {tab === 2 && <DeleteAdmin />}
          {tab === 3 && <CreateSuspension />}
          {tab === 4 && (
            <SuspensionHistory
              response={suspensions}
              page={currentPage}
              totalPages={Math.ceil(suspensions.length / itemsPerPage)}
            />
          )}
          {tab === 5 && (
            <div>
              <MealCategory
                data={mealCategories} // Pass correct meal category data
                currentPage={currentPage}
                totalPages={Math.ceil(mealCategories.length / itemsPerPage)}
                itemsPerPage={itemsPerPage}
                onPageChange={setCurrentPage}
                onDeleteMeal={deleteMeal} // Pass delete function
              />
            </div>
          )}
          {tab === 6 && (
            <div>
              <CreateMealCategory />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsWrapper;
