import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';

interface StatisticsProps {
  totalUsers: number;
  activeUsers: number;
  inactiveUsers: number;
}

const Statistics: React.FC<StatisticsProps> = ({ totalUsers, activeUsers, inactiveUsers }) => {
  const pieData = {
    labels: ['Active Users', 'Inactive Users'],
    datasets: [
      {
        label: 'User Distribution',
        data: [activeUsers, inactiveUsers],
        backgroundColor: ['#00C49F', '#FF8042'],
        hoverBackgroundColor: ['#00C49F', '#FF8042'],
      },
    ],
  };

  const barData = {
    labels: ['Active Users', 'Inactive Users'],
    datasets: [
      {
        label: 'Users Count',
        data: [activeUsers, inactiveUsers],
        backgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  return (
    <div className="flex flex-col lg:flex-row gap-7">
      <div className="flex flex-col items-center justify-center text-center rounded-lg border border-zinc-400 p-6 bg-white shadow-lg lg:w-1/3">
        <div className="space-y-4 w-100">
          <div>
            <p className="text-neutral-500 text-base font-normal leading-7">
              <i className="fa fa-users text-gray-500 mr-2"></i> Total Users
            </p>
            <h3 className="text-black text-3xl font-bold">{totalUsers}</h3>
          </div>
          <div>
            <p className="text-neutral-500 text-base font-normal leading-7">
              <i className="fa fa-user-check text-green-500 mr-2"></i> Active Users
            </p>
            <h3 className="text-black text-3xl font-bold">{activeUsers}</h3>
          </div>
          <div>
            <p className="text-neutral-500 text-base font-normal leading-7">
              <i className="fa fa-user-slash text-red-500 mr-2"></i> Inactive Users
            </p>
            <h3 className="text-black text-3xl font-bold">{inactiveUsers}</h3>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row flex-grow gap-5">
        <div className="h-64 flex-1">
          <Pie data={pieData} options={{ maintainAspectRatio: false }} />
        </div>
        <div className="h-64 flex-1">
          <Bar data={barData} options={{ maintainAspectRatio: false }} />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
