import React from 'react';
import { Transaction } from '../../../services/types'; // Adjust the path if needed
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import removeRedEye from '../../../assets/icons/removeRedEye.svg'; // Ensure this path is correct

interface UserTransactionsProps {
  transactions: Transaction[];
  loading: boolean;
  error: string | null;
}

const UserTransactions: React.FC<UserTransactionsProps> = ({ transactions, loading, error }) => {
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [filter, setFilter] = React.useState<string>('all');
  const [page, setPage] = React.useState<number>(1);
  const [size] = React.useState<number>(10);

  const downloadCSV = () => {
    const csvData = transactions.map(transaction => ({
      ID: transaction._id,
      Amount: transaction.amount,
      Status: transaction.status,
      Description: transaction.description,
      Type: transaction.type,
      'Transaction Code': transaction.transaction_code,
      'Created At': new Date(transaction.created_at).toLocaleString(),
      'Updated At': new Date(transaction.updated_at).toLocaleString(),
    }));

    const csvContent = [
      Object.keys(csvData[0]).join(','),
      ...csvData.map(row => Object.values(row).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'user_transactions.csv');
  };

  
  const filterByDateRange = (range: string, transactions: Transaction[]) => {
    const now = new Date();
    let filteredTransactions = transactions;
  
    if (range === 'today') {
      const startOfDay = new Date(now.setHours(0, 0, 0, 0));
      filteredTransactions = transactions.filter(
        transaction => new Date(transaction.created_at) >= startOfDay
      );
    } else if (range === 'thisWeek') {
      const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
      filteredTransactions = transactions.filter(
        transaction => new Date(transaction.created_at) >= startOfWeek
      );
    } else if (range === 'thisMonth') {
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      filteredTransactions = transactions.filter(
        transaction => new Date(transaction.created_at) >= startOfMonth
      );
    } else if (range === 'thisYear') {
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      filteredTransactions = transactions.filter(
        transaction => new Date(transaction.created_at) >= startOfYear
      );
    } else if (range === 'debit') {
      filteredTransactions = transactions.filter(transaction => transaction.type === 'debit');
    } else if (range === 'credit') {
      filteredTransactions = transactions.filter(transaction => transaction.type === 'credit');
    } else if (range === 'successful') {
      filteredTransactions = transactions.filter(transaction => transaction.status === 'successful');
    } else if (range === 'unsuccessful') {
      filteredTransactions = transactions.filter(transaction => transaction.status === 'unsuccessful');
    }
  
    return filteredTransactions;
  };
  
  
  const filteredTransactionsByDate = filterByDateRange(filter, transactions);

  const handleSearch = (transactions: Transaction[]) => {
    return transactions.filter(transaction => {
      const query = searchQuery.toLowerCase();
      return (
        transaction._id.toLowerCase().includes(query) ||
        transaction.description.toLowerCase().includes(query) ||
        transaction.status.toLowerCase().includes(query) ||
        transaction.type.toLowerCase().includes(query)
      );
    });
  };
  

  const filteredTransactions = handleSearch(filteredTransactionsByDate);
  const paginatedTransactions = filteredTransactions.slice((page - 1) * size, page * size);
  const totalPages = Math.ceil(filteredTransactions.length / size);

  return (
    <div className="p-4 mt-4 bg-gray-100 rounded-md shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">User Transactions</h2>
        <button onClick={downloadCSV} className="text-black text-base font-normal">
          <FontAwesomeIcon icon={faDownload} /> Download CSV
        </button>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}

      <div className="mb-4 flex justify-between">
        <input
          type="text"
          placeholder="Search transactions..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border border-gray-300 p-2 rounded-md"
        />
      

      <select 
  value={filter}
  onChange={(e) => setFilter(e.target.value)}
  className="border border-gray-300 p-2 rounded-md ml-2"
>
  <option value="all">All</option>
  <option value="today">Today</option>
  <option value="thisWeek">This Week</option>
  <option value="thisMonth">This Month</option>
  <option value="thisYear">This Year</option>
  <option value="debit">Debit</option>
  <option value="credit">Credit</option>
  <option value="successful">Successful</option>
  <option value="unsuccessful">Unsuccessful</option>
</select>

      </div>

      <table className="min-w-full bg-white border border-gray-200 rounded-md">
        <thead>
          <tr>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">ID</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Description</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Type</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Transaction Code</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Created At</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Updated At</th>
            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">View User</th>
           
          </tr>
        </thead>
        <tbody>
          {paginatedTransactions.map(transaction => (
            <tr key={transaction._id}>
              <td className="px-5 py-3 border border-zinc-400">{transaction._id}</td>
              <td className="px-5 py-3 border border-zinc-400">₦{transaction.amount.toFixed(2)}</td>
              <td className="px-5 py-3 border border-zinc-400">{transaction.status}</td>
              <td className="px-5 py-3 border border-zinc-400">{transaction.description}</td>
              <td className="px-5 py-3 border border-zinc-400">{transaction.type}</td>
              <td className="px-5 py-3 border border-zinc-400">{transaction.transaction_code}</td>
              <td className="px-5 py-3 border border-zinc-400">{new Date(transaction.created_at).toLocaleString()}</td>
              <td className="px-5 py-3 border border-zinc-400">{new Date(transaction.updated_at).toLocaleString()}</td>
              <td className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
               
              <Link to={`/pr-admin/users/user-details/${transaction.user_id}`}>
        <img src={removeRedEye} alt="View" />
      </Link>
             </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination controls */}
      <div className="mt-4 flex justify-between items-center">
        <button onClick={() => setPage(prevPage => Math.max(prevPage - 1, 1))} disabled={page === 1} className="h-11 p-2.5 rounded-xl border border-amber-500 flex items-center">
          <FontAwesomeIcon icon={faArrowLeft} /> Previous
        </button>
        <span className="text-sm">
          Page {page} of {totalPages}
        </span>
        <button onClick={() => setPage(prevPage => Math.min(prevPage + 1, totalPages))} disabled={page === totalPages} className="h-11 p-2.5 rounded-xl border border-amber-500 flex items-center">
          Next <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

export default UserTransactions;
