import React, { useEffect, useState } from 'react';
import { request } from 'src/hooks/api';
import AdminService from 'src/services/admin.services';
import wallet from '../../../assets/images/wallet.png';

// Modal component for displaying error/success messages
const Modal: React.FC<{ message: string; onClose: () => void }> = ({ message, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-96 text-center">
      <p className="text-lg mb-4">{message}</p>
      <button className="bg-red-500 text-white py-2 px-4 rounded" onClick={onClose}>
        Close
      </button>
    </div>
  </div>
);

interface WalletProps {
  agentId: string;
}

interface WalletData {
  _id: string;
  agentId: string;
  balance: { amount: number; currency_code: string };
  rewards: { bonus: { registration: number } };
  created_at: string;
  updated_at: string;
}

const WalletTab: React.FC<WalletProps> = ({ agentId }) => {
  const [walletData, setWalletData] = useState<WalletData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [debitAmount, setDebitAmount] = useState<string>(''); // State for debit amount
  const [modalMessage, setModalMessage] = useState<string | null>(null); // For modal messages
  const [debitHistory, setDebitHistory] = useState<any[]>([]); // Initialize debit history state
  const adminService = new AdminService();

  useEffect(() => {
    const fetchAgentsWallet = async () => {
      setLoading(true);
      try {
        const response = await request(
          `/agents/wallet/${agentId}`,
          "GET",
          {},
          true,
          false,
          false
        );

        if (response && response.balance) {
          setWalletData(response);
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (error) {
        console.error('Error fetching wallet details:', error);
        setError('Error fetching wallet information');
      } finally {
        setLoading(false);
      }
    };

    if (agentId) {
      fetchAgentsWallet();
    } else {
      setError('Invalid agentId');
      setLoading(false);
    }
  }, [agentId]);

/*
  const handleDebit = async () => {
    const amountToDebit = Number(debitAmount.trim());
  
    if (isNaN(amountToDebit) || amountToDebit <= 0) {
      setModalMessage('Please enter a valid debit amount');
      return;
    }
  
    try {
      const response = await request(
        '/admins/cash-payment-wallet/wallet-debit',
        'PATCH',
        { amount: amountToDebit, agentId },
        true,
        false,
        false
      );
      
      console.log('Debit Response:', response);
  
      if (response && response.message === 'updated successfully') {
        setWalletData((prev) => {
          if (prev) {
            return {
              ...prev,
              balance: {
                ...prev.balance,
                amount: prev.balance.amount - amountToDebit,
              },
            };
          }
          return prev;
        });
  
        const newHistoryItem = { agentId, amount: amountToDebit, date: new Date().toLocaleString() };
        const updatedHistory = [...debitHistory, newHistoryItem];
        setDebitHistory(updatedHistory);
        localStorage.setItem(`debitHistory_${agentId}`, JSON.stringify(updatedHistory));
  
        setModalMessage('Debit successfully processed!');
        setDebitAmount('');
      } else {
        throw new Error('Debit operation failed');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Error processing debit';
      setModalMessage(errorMessage);
    }
  };

  */
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!walletData) {
    return <div>No wallet data available</div>;
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
        <div className="flex items-center mb-4">
          <img src={wallet} alt="Wallet Icon" className="w-20 h-20 mr-2" />
          <h3 className="text-2xl font-semibold">Wallet Information</h3>
        </div>
        
        <p className="text-lg">
          <strong>Balance:</strong> ₦{walletData.balance.amount} 
        </p>
        <p className="text-lg">
          <strong>Registration Bonus:</strong> ₦{walletData.rewards.bonus.registration} 
        </p>
        <p className="text-lg">
          <strong>Created At:</strong> {new Date(walletData.created_at).toLocaleString()}
        </p>
        <p className="text-lg">
          <strong>Updated At:</strong> {new Date(walletData.updated_at).toLocaleString()}
        </p>

        </div>
        </div>
      )}


export default WalletTab;
